import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';
import { Alert, Button, message, Popconfirm, Space, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import Search from 'antd/lib/input/Search';
import { useAdminDeviceApi } from 'api/devices/useAdminDeviceApi';
import Breadcrumb from 'components/common/Breadcrumb';
import IdTag from 'components/common/IdTag';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import ResourceIcons from 'components/common/resourceIcons';
import DeviceConfigurationCollapsible from 'components/device-configuration/DeviceConfigurationCollapsible';
import useJsonSearch from 'hooks/useJsonSearch';
import useOrganizationDeviceConfigurations from 'hooks/useOrganizationDeviceConfigurations';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import type { OrganizationDeviceConfiguration } from 'interfaces/adminOrganizationDeviceConfig';
import _ from 'lodash';
import { useMemo } from 'react';
import getErrorMessage from 'utlis/getErrorMessage';

const AdminOrganizationDeviceConfigurationView: React.FC = () => {
  const { deleteOrganizationDeviceConfiguration } = useAdminDeviceApi();

  const {
    data: configurations,
    refetch: refreshConfigurations,
    isLoading: isDeviceConfigsLoading,
    isError: isDeviceConfigError,
  } = useOrganizationDeviceConfigurations();

  const { filteredItems, setSearchText } = useJsonSearch(configurations || []);

  const { mutateAsync: removeOrganizationDeviceConfiguration } = useMutation({
    mutationFn: deleteOrganizationDeviceConfiguration,
    onSuccess: () => {
      refreshConfigurations();
    },
    onError: (error: any) => {
      message.error(getErrorMessage(error));
    },
  });

  const getColumnSearchProps =
    useTableSearchFilter<OrganizationDeviceConfiguration>();

  const columns = useMemo<ColumnsType<OrganizationDeviceConfiguration>>(
    () => [
      {
        title: 'ID',
        width: '80px',
        sorter: (a, b) => a.id.localeCompare(b.id),
        ...getColumnSearchProps(
          'id',
          'id',
          (record) => record.id,
          (record, _, searchText) => (
            <IdTag id={record.id} highlight={searchText} />
          ),
        ),
      },
      {
        title: 'Anzahl der Geräte',
        width: '40px',
        sorter: (a, b) => a.devicesCount - b.devicesCount,
        render: (_, config) => config.devicesCount,
      },
      {
        title: 'Organisation',
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: 'ascend',
        ...getColumnSearchProps(
          'organization',
          'organization',
          (record) => record.organization,
        ),
      },
      {
        title: 'Gerätekonfiguration',
        render: (_, config) => (
          <DeviceConfigurationCollapsible config={config} />
        ),
      },

      {
        title: 'Priorität',
        sorter: (a, b) => a.priority - b.priority,
        render: (_, config) => config.priority,
      },
      {
        title: 'Actions',
        render: (_, config) => (
          <Space>
            <Link
              to="/admin/organization-device-configurations/$organizationDeviceConfigurationId/edit"
              params={{
                organizationDeviceConfigurationId: config.id,
              }}
            >
              <Button type="text" icon={<EditOutlined />} />
            </Link>
            <Popconfirm
              title="Are you sure delete this Config?"
              onConfirm={() => removeOrganizationDeviceConfiguration(config.id)}
              okText="Yes"
            >
              <Button type="text" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        ),
      },
    ],
    [getColumnSearchProps, removeOrganizationDeviceConfiguration],
  );

  return (
    <>
      <Breadcrumb
        items={[
          {
            name: 'Organisations-Gerätekonfigurationen',
            href: '/admin/organization-device-configurations',
          },
        ]}
      />
      <PageTitleBar
        title="Organisations-Gerätekonfigurationen"
        Icon={ResourceIcons.OrganizationDeviceConfiguration}
        renderToolbar={() => (
          <Link to="/admin/organization-device-configurations/create">
            <Button icon={<PlusOutlined />}>Konfiguration erstellen</Button>
          </Link>
        )}
      />
      <PageContentContainer>
        {isDeviceConfigError && (
          <Alert type="error" message="Something went wrong" />
        )}
        <Search
          placeholder="Schnellsuche"
          onChange={_.debounce((e) => setSearchText(e.target.value), 500)}
          style={{
            maxWidth: '800px',
            marginBottom: '16px',
          }}
        />
        <Table
          loading={isDeviceConfigsLoading}
          dataSource={filteredItems}
          columns={columns}
          pagination={false}
          rowKey={(dc) => dc.id}
        />
      </PageContentContainer>
    </>
  );
};

export default AdminOrganizationDeviceConfigurationView;
