import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import {
  Alert,
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Table,
  message,
} from 'antd';
import { FlexBox } from 'components/Helpers';
import Breadcrumb from 'components/common/Breadcrumb';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import ResourceIcons from 'components/common/resourceIcons';
import SeniorCareIntegrationConfigForm from 'components/device-configuration/features/senior-care-integration-config/SeniorCareIntegrationConfigForm';
import SeniorCareIntegrationConfigSummary from 'components/device-configuration/features/senior-care-integration-config/SeniorCareIntegrationConfigSummary';
import { useManagementApiFromContext } from 'components/scaffold/OrganizationView';
import { seniorCareAppDeviceRole } from 'defaults/deviceRoles';
import { seniorCareDeviceManagementIntegrationConfigurationKey } from 'deviceMetadataKeys';
import { useManagementDeviceConfigurationsInvalidation } from 'hooks/useManagementDeviceConfigurations';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import type { PutManagementDeviceConfiguration } from 'interfaces/managementDeviceConfiguration';
import { Operator } from 'interfaces/matchers';
import { useState } from 'react';
import { useNullablePathOrganization } from 'router';
import { v4 as uuidv4 } from 'uuid';
import { useSeniorCareIntegrationConfigs } from './SeniorCareDeviceConfigurationAssignment';
import {
  integrationTypeName,
  type SeniorCareIntegrationConfig,
} from './SeniorCareIntegrationConfig';

const SeniorCareIntegrationConfigsView: React.FC = () => {
  const organization = useNullablePathOrganization();
  const [editItem, setEditItem] = useState<SeniorCareIntegrationConfig | null>(
    null,
  );
  const [viewItem, setViewItem] = useState<SeniorCareIntegrationConfig | null>(
    null,
  );
  const [isCreateViewOpen, setIsCreateViewOpen] = useState(false);

  const integrationConfigs = useSeniorCareIntegrationConfigs();

  const {
    putManagementDeviceConfiguration,
    postManagementDeviceConfiguration,
    deleteManagementDeviceConfiguration,
  } = useManagementApiFromContext();
  const invalidateManagementDeviceConfigurations =
    useManagementDeviceConfigurationsInvalidation();
  const [createForm] = Form.useForm();
  const [editForm] = Form.useForm();

  const { mutate: createConfig, error: createError } = useMutation({
    mutationFn: postManagementDeviceConfiguration,
    onSuccess: () => {
      invalidateManagementDeviceConfigurations();
      message.success('Schnittstelle wurde erstellt.');
      setIsCreateViewOpen(false);
    },
  });

  const { mutate: updateConfig, error: updateError } = useMutation({
    mutationFn: async (data: PutManagementDeviceConfiguration) => {
      if (editItem === null) throw new Error('Edit item is null');
      await putManagementDeviceConfiguration(editItem.id, data);
    },
    onSuccess: () => {
      invalidateManagementDeviceConfigurations();
      message.success('Schnittstelle wurde bearbeitet.');
      setEditItem(null);
    },
  });

  const { mutate: deleteConfig } = useMutation({
    mutationFn: deleteManagementDeviceConfiguration,
    onSuccess: () => {
      invalidateManagementDeviceConfigurations();
      message.success('Schnittstelle wurde gelöscht.');
    },
  });

  const getColumnSearchProps =
    useTableSearchFilter<SeniorCareIntegrationConfig>();

  return (
    <>
      <Breadcrumb
        items={[
          {
            name: 'Schnittstellenverwaltung',
            href: `/management/${organization}/devices`,
          },
        ]}
      />
      <PageTitleBar
        title="Schnittstellen"
        Icon={ResourceIcons.Integration}
        renderToolbar={() => (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsCreateViewOpen(true)}
          >
            Schnittstelle anlegen
          </Button>
        )}
      />
      {viewItem !== null && (
        <Modal
          open
          title={viewItem.name}
          onCancel={() => setViewItem(null)}
          styles={{
            body: {
              padding: '20px 0',
            },
          }}
          width={800}
          footer={null}
        >
          <SeniorCareIntegrationConfigSummary
            properties={viewItem.properties}
          />
        </Modal>
      )}
      {editItem !== null && (
        <Modal
          open
          title={`${editItem.name} bearbeiten`}
          onCancel={() => setEditItem(null)}
          styles={{
            body: {
              padding: '20px 0',
            },
          }}
          width={800}
          footer={null}
        >
          {updateError && (
            <Form.Item>
              <Alert type="error" message={updateError.message} />
            </Form.Item>
          )}
          <Form form={editForm}>
            <Form.Item
              label="Name"
              labelAlign="left"
              labelCol={{ span: 6 }}
              name="name"
              rules={[
                { required: true, message: 'Bitte geben Sie einen Namen ein.' },
              ]}
              initialValue={editItem.name}
            >
              <Input />
            </Form.Item>
            <SeniorCareIntegrationConfigForm
              initialProperties={editItem.properties}
              onSubmit={async (properties) => {
                // TODO: The merging of the integration config form and the name input is not optimal.
                //       Consider turning the form into a controlled component and adding a wrapper form.
                await editForm.validateFields();
                updateConfig({
                  name: editForm.getFieldValue('name'),
                  priority: editItem.priority,
                  deviceSelector: editItem.deviceSelector,
                  properties,
                });
              }}
            />
          </Form>
        </Modal>
      )}
      <Modal
        open={isCreateViewOpen}
        title="Schnittstelle erstellen"
        onCancel={() => setIsCreateViewOpen(false)}
        styles={{
          body: {
            padding: '20px 0',
          },
        }}
        width={800}
        footer={null}
      >
        {createError && (
          <Form.Item>
            <Alert type="error" message={createError.message} />
          </Form.Item>
        )}
        <Form form={createForm}>
          <Form.Item
            label="Name"
            labelAlign="left"
            labelCol={{ span: 6 }}
            name="name"
            rules={[
              { required: true, message: 'Bitte geben Sie einen Namen ein.' },
            ]}
          >
            <Input />
          </Form.Item>
          <SeniorCareIntegrationConfigForm
            onSubmit={async (properties) => {
              // TODO: The merging of the integration config form and the name input is not optimal.
              //       Consider turning the form into a controlled component and adding a wrapper form.
              await createForm.validateFields();
              createConfig({
                name: createForm.getFieldValue('name'),
                priority: 100,
                deviceSelector: {
                  matchLabels: [
                    {
                      key: seniorCareDeviceManagementIntegrationConfigurationKey,
                      operator: Operator.IN,
                      values: [uuidv4()],
                    },
                  ],
                  matchRole: {
                    in: [seniorCareAppDeviceRole],
                  },
                },
                properties,
              });
            }}
          />
        </Form>
      </Modal>
      <PageContentContainer>
        <Table<SeniorCareIntegrationConfig>
          dataSource={integrationConfigs}
          onRow={(config) => ({
            style: { cursor: 'pointer' },
            onClick: () => setViewItem(config),
          })}
          columns={[
            {
              title: 'Name',
              ...getColumnSearchProps('name', 'name', (record) => record.name),
            },
            {
              title: 'Typ',
              render: (_, config) =>
                integrationTypeName[config.integrationType],
              filters: Object.entries(integrationTypeName).map(
                ([key, value]) => ({
                  text: value,
                  value: key,
                }),
              ),
              filterSearch: true,
              onFilter: (value, record) => record.integrationType === value,
            },
            {
              title: 'URL',
              render: (_, config) => config.baseUrl,
            },
            {
              title: 'Sync-Nutzer',
              render: (_, config) => config.username,
            },
            {
              title: 'Geräte',
              render: (_, config) => config.devicesCount,
            },
            {
              title: 'Aktionen',
              render: (_, config) => (
                <FlexBox withgap>
                  <Button
                    icon={<EditOutlined />}
                    type="text"
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditItem(config);
                    }}
                  />
                  <Popconfirm
                    title="Sind Sie sicher, dass Sie diese Schnittstelle löschen möchten?"
                    onConfirm={(e) => {
                      e?.stopPropagation();
                      deleteConfig(config.id);
                    }}
                  >
                    <Button
                      icon={<DeleteOutlined />}
                      type="text"
                      danger
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Popconfirm>
                </FlexBox>
              ),
            },
          ]}
        />
      </PageContentContainer>
    </>
  );
};

export default SeniorCareIntegrationConfigsView;
