import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input } from 'antd';
import { FlexBox } from 'components/Helpers';
import Joi from 'joi';
import { useCallback, useEffect, useMemo } from 'react';
import { validateJsonProperty } from 'utlis/validationUtil';
import type { FeatureRenderProps } from '../../../defaults/featuresToDeviceProperties';

const RoomPrefixesSchema = Joi.array<string[]>().items(Joi.string());

const SeniorCareResidentsRoomPrefixFeature: React.FC<FeatureRenderProps> = ({
  values,
  onUpdate,
  isEditing,
  onEditDone,
}) => {
  const [form] = Form.useForm();

  const [roomPrefixes, error] = useMemo(
    () =>
      validateJsonProperty(
        values['ROOM_PREFIXES_TO_GROUP_RESIDENTS'],
        RoomPrefixesSchema,
      ),
    [values],
  );

  const roomPrefixesToGroupResidents = Form.useWatch(
    'roomPrefixesToGroupResidents',
    form,
  );

  const onChange = useCallback(
    (roomPrefixes: string[]) => {
      onUpdate({
        ...values,
        ROOM_PREFIXES_TO_GROUP_RESIDENTS: JSON.stringify(roomPrefixes),
      });
    },
    [onUpdate, values],
  );

  useEffect(() => {
    if (onEditDone !== undefined) return;
    onChange(roomPrefixesToGroupResidents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomPrefixesToGroupResidents]);

  if (!isEditing) {
    return (
      <FlexBox>
        {error !== null && (
          <Alert
            message="Fehler"
            description={error}
            type="error"
            showIcon
            closable
          />
        )}
        {roomPrefixes !== null &&
          roomPrefixes.map((roomPrefix) => (
            <div key={roomPrefix}>{roomPrefix}</div>
          ))}
      </FlexBox>
    );
  }

  return (
    <Form<{
      roomPrefixesToGroupResidents: string[];
    }>
      form={form}
      onFinish={(formData) => {
        onChange(formData.roomPrefixesToGroupResidents);
        onEditDone?.();
      }}
      initialValues={
        roomPrefixes !== null
          ? {
              roomPrefixesToGroupResidents: roomPrefixes,
            }
          : undefined
      }
      onChange={onEditDone === undefined ? form.submit : undefined}
    >
      <Form.List name="roomPrefixesToGroupResidents">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field) => (
              <Form.Item
                label="Zimmername Gruppen REGEX String"
                required={false}
                key={field.key}
              >
                <Form.Item
                  {...field}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      whitespace: false,
                      message: 'Geben Sie einen Bereichsgruppen String ein.',
                    },
                  ]}
                  noStyle
                >
                  <Input
                    placeholder="Zimmer Gruppierungs-REGEX"
                    style={{ width: '60%' }}
                  />
                </Form.Item>
                <MinusCircleOutlined
                  className="dynamic-delete-button"
                  onClick={() => remove(field.name)}
                />
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: '60%' }}
                icon={<PlusOutlined />}
              >
                Gruppierungs String hinzufügen
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      {onEditDone !== undefined && (
        <Button type="primary" htmlType="submit" onClick={() => form.submit()}>
          Speichern
        </Button>
      )}
    </Form>
  );
};

export default SeniorCareResidentsRoomPrefixFeature;
