import { ReloadOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, Skeleton } from 'antd';
import Breadcrumb from 'components/common/Breadcrumb';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import ResourceIcons from 'components/common/resourceIcons';
import { DeviceMetadataStatistics } from 'components/views/statistics/metadata/DeviceMetadataStatistics';
import { DevicePropertiesStatistics } from 'components/views/statistics/properties/DevicePropertiesStatistics';
import useAdminDevices from 'hooks/devices/useAdminDevices';
import { useFilteredDevices } from './filtering/useFilteredDevices';

const DeviceMetadataWhitelist = [
  'voize/platform',
  'voize/source',
  'voize/language',
  'voize/industry',
  'android/app-version',
  'android/system-version',
  'android/device/manufacturer',
  'android/device/model',
  'ios/app-version',
  'ios/system-version',
  'ios/device/model',
  'voize-app-senior-care/version',
  'voize-app-senior-care/release-track',
  'voize-app-tic/version',
  'voize-sdk/version',
];

export const AdminDeviceStatisticsView: React.FC = () => {
  const { devices, isLoading, isFetching, isError, invalidateDevices } =
    useAdminDevices(undefined);

  const { filteredDevices, ui: filterUi } = useFilteredDevices(devices, {
    showSourceFilter: true,
    showOrganizationFilter: true,
    showActiveFilter: true,
    showIndustryFilter: true,
  });

  return (
    <>
      <Breadcrumb
        items={[{ name: 'Statistiken', href: '/admin/statistics' }]}
      />
      <PageTitleBar
        title="Statistiken"
        Icon={ResourceIcons.Statistics}
        renderToolbar={() => (
          <Button
            icon={<ReloadOutlined />}
            onClick={invalidateDevices}
            loading={isLoading || isFetching}
          >
            Aktualisieren
          </Button>
        )}
      />
      <PageContentContainer>
        {isError && <Alert type="error" message="Something went wrong" />}
        {isLoading ? (
          <Skeleton active />
        ) : (
          <>
            {filterUi}
            <Divider />
            <DeviceMetadataStatistics
              devices={filteredDevices}
              metadataWhitelist={DeviceMetadataWhitelist}
            />
            <Divider />
            <DevicePropertiesStatistics devices={filteredDevices} />
          </>
        )}
      </PageContentContainer>
    </>
  );
};
