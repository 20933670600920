import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';
import { getRequiredEnv } from 'config';
import type { PostUserRequest, PutUserRequest, User } from 'interfaces/user';
import { useCallback } from 'react';
import {
  deleteUsersScope,
  readUsersScope,
  writeUsersScope,
} from './deviceManagementApiScopes';

const audience = getRequiredEnv('VITE_AUTH0_AUDIENCE');

export const voizeDeviceManagementApi = Axios.create({
  baseURL: getRequiredEnv('VITE_DEVICE_MANAGEMENT_API_URL'),
});

export const useAdminUserApi = () => {
  const { getAccessTokenSilently } = useAuth0();
  const getAccessToken = useCallback(
    async (...scopes: string[]) =>
      await getAccessTokenSilently({
        authorizationParams: {
          audience,
          scope: scopes.join(' '),
        },
      }),
    [getAccessTokenSilently],
  );

  const getUsers = useCallback(
    async (organization?: string): Promise<User[]> => {
      const accessToken = await getAccessToken(readUsersScope);

      const { data } = await voizeDeviceManagementApi.get('/v2/admin/users', {
        params: {
          organization,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return data;
    },
    [getAccessToken],
  );

  const getUser = useCallback(
    async (userId: string): Promise<User> => {
      const accessToken = await getAccessToken(readUsersScope);

      const { data } = await voizeDeviceManagementApi.get(
        `/v2/admin/users/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      return data;
    },
    [getAccessToken],
  );

  const postUser = useCallback(
    async (user: PostUserRequest): Promise<User> => {
      const accessToken = await getAccessToken(writeUsersScope);

      const { data } = await voizeDeviceManagementApi.post(
        '/v2/admin/users',
        user,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      return data;
    },
    [getAccessToken],
  );

  const putUser = useCallback(
    async (userId: string, user: PutUserRequest): Promise<void> => {
      const accessToken = await getAccessToken(writeUsersScope);

      await voizeDeviceManagementApi.put(`/v2/admin/users/${userId}`, user, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
    [getAccessToken],
  );

  const deleteUser = useCallback(
    async (userId: string): Promise<void> => {
      const accessToken = await getAccessToken(deleteUsersScope);

      await voizeDeviceManagementApi.delete(`/v2/admin/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
    [getAccessToken],
  );

  return {
    getUsers,
    getUser,
    postUser,
    putUser,
    deleteUser,
  };
};
