import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, type InputRef, Space } from 'antd';
import type { ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/lib/table/interface';
import React, { useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';

export type SearchRender<T> = (
  record: T,
  index: number,
  searchText: string,
) => React.ReactNode;

const useTableSearchFilter = <T,>() => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState<string | null>(null);
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    columnIdentifier: string,
  ) => {
    confirm();
    setSearchText(selectedKeys[0] ?? '');
    setSearchedColumn(columnIdentifier);
  };

  const handleReset = (clearFilters: () => void, confirm: () => void) => {
    clearFilters();
    setSearchText('');
    confirm();
  };

  const getColumnSearchProps = (
    name: string,
    columnIdentifier: string,
    getSearchValue: (record: T) => string | null,
    render?: SearchRender<T>,
  ): ColumnType<T> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${name}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, columnIdentifier)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, columnIdentifier)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && confirm && handleReset(clearFilters, confirm)
            }
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => {
      return (
        <SearchOutlined
          style={{
            color:
              filtered && searchedColumn === columnIdentifier
                ? '#1890ff'
                : 'rgba(0, 0, 0, 0.29)',
          }}
        />
      );
    },
    onFilter: (value, record) => {
      const recordValue = getSearchValue(record);

      if (recordValue === null) return false;

      return recordValue
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (_, record, index) => {
      if (render) return render(record, index, searchText);

      const value = getSearchValue(record);

      return searchedColumn === columnIdentifier ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={value ? value.toString() : ''}
        />
      ) : (
        value
      );
    },
  });

  return getColumnSearchProps;
};

export default useTableSearchFilter;
