import { useQuery } from '@tanstack/react-query';
import {
  CursorDirection,
  type PaginationQuery,
} from 'interfaces/PaginationQuery';
import type { EntryWithId } from './useListActionManager';

interface Arguments<T> {
  queryKey: any[];
  queryFn: (paginationQuery: PaginationQuery) => Promise<T[]>;
}

const useLoadAllPaginatedCursorQuery = <T extends EntryWithId>(
  { queryKey, queryFn: getItems }: Arguments<T>,
  batchSize = 100,
) => {
  const query = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      if (batchSize <= 0) {
        throw new Error('batchSize must be greater than 0');
      }

      const items: T[] = [];

      let cursor = undefined;

      while (true) {
        const currentItems: T[] = await getItems({
          cursor,
          limit: batchSize,
          cursorDirection: CursorDirection.forward,
        });
        items.push(...currentItems);

        if (currentItems.length < batchSize) {
          break;
        }

        cursor = currentItems?.[currentItems.length - 1]?.id;
      }

      return items;
    },
  });

  return query;
};

export default useLoadAllPaginatedCursorQuery;
