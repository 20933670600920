import { Col, Row, Typography } from 'antd';
import { FlexBox } from 'components/Helpers';
import type { ManagementDevice } from 'interfaces/device';
import { useMemo } from 'react';
import { MdInsertChartOutlined } from 'react-icons/md';
import { StatisticsCard } from './StatisticsCard';

interface MetadataStatisticsProps {
  devices: ManagementDevice[];
  metadataWhitelist?: string[];
}

type MetadataDistribution = Record<string, number>;
type MetadataStats = Record<string, MetadataDistribution>;

export const DeviceMetadataStatistics: React.FC<MetadataStatisticsProps> = ({
  devices,
  metadataWhitelist,
}) => {
  const metadataStats = useMemo(() => {
    const stats: MetadataStats = {};

    devices.forEach((device) => {
      if (!device.metadata) return;

      Object.entries(device.metadata).forEach(([key, value]) => {
        if (metadataWhitelist && !metadataWhitelist.includes(key)) return;
        if (!stats[key]) stats[key] = {};
        if (typeof value === 'string') {
          stats[key][value] = (stats[key][value] || 0) + 1;
        }
      });
    });

    return stats;
  }, [devices, metadataWhitelist]);

  return (
    <FlexBox direction="column">
      <Typography.Title level={4}>
        <FlexBox alignitems="center" withgap gap={8}>
          <MdInsertChartOutlined style={{ fontSize: '1.5rem' }} />
          <span>Geräte-Statistiken</span>
        </FlexBox>
      </Typography.Title>

      <FlexBox direction="column" withgap gap={24}>
        <Row gutter={[16, 16]}>
          {Object.entries(metadataStats).map(([property, distribution]) => (
            <Col xs={24} sm={24} md={8} key={property}>
              <StatisticsCard property={property} distribution={distribution} />
            </Col>
          ))}
        </Row>
      </FlexBox>
    </FlexBox>
  );
};
