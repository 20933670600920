import { Modal } from 'antd';
import type { DeviceProperty } from 'defaults/deviceProperties';
import DevicePropertyInfo from './DevicePropertyInfo';

interface Props {
  open: boolean;
  onClose: () => void;
  deviceProperty: DeviceProperty;
}
const DevicePropertyInfoModal: React.FC<Props> = ({
  open,
  onClose,
  deviceProperty,
}) => {
  return (
    <Modal open={open} onCancel={onClose} footer={null}>
      <DevicePropertyInfo deviceProperty={deviceProperty} />
    </Modal>
  );
};

export default DevicePropertyInfoModal;
