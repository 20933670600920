import {
  DevicePropertyType,
  useDeviceProperties,
} from 'defaults/deviceProperties';
import type { DeviceProperties } from 'interfaces/adminOrganizationDeviceConfig';
import { useMemo } from 'react';
import recursivelyMaskPasswordValues from 'utlis/maskSecrets';
import LabelList from './LabelList';

interface Props {
  deviceProperties: DeviceProperties;
  highlightTexts?: string[];
  containerStyle?: React.CSSProperties;
  labelOptions?: Record<string, string[]>;
}
const DevicePropertiesList: React.FC<Props> = ({
  deviceProperties,
  highlightTexts,
  containerStyle,
  labelOptions,
}) => {
  const defaultDeviceProperties = useDeviceProperties();

  const maskedDeviceProperties = useMemo(() => {
    return Object.fromEntries(
      Object.entries(deviceProperties).map(([key, value]) => {
        const currentDeviceProperty = defaultDeviceProperties.find(
          ({ name }) => name === key,
        );
        if (currentDeviceProperty?.type !== DevicePropertyType.JSON) {
          return [key, value];
        }

        if (value.length > 1000) {
          return [key, value];
        }

        try {
          return [
            key,
            JSON.stringify(
              recursivelyMaskPasswordValues(JSON.parse(value)),
              null,
              0,
            ),
          ];
        } catch (e) {
          return [key, value];
        }
      }),
    );
  }, [defaultDeviceProperties, deviceProperties]);

  return (
    <LabelList
      labels={maskedDeviceProperties}
      editable={false}
      highlightTexts={highlightTexts}
      containerStyle={containerStyle}
      labelOptions={labelOptions}
    />
  );
};

export default DevicePropertiesList;
