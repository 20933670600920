export const getLabelsDiff = (
  original: Record<string, string>,
  updated: Record<string, string>,
) => {
  const added: Record<string, string> = {};
  const removed: Record<string, null> = {};

  for (const key in updated) {
    if (original[key] !== updated[key]) {
      added[key] = updated[key] ?? '';
    }
  }

  for (const key in original) {
    if (!updated[key]) {
      removed[key] = null;
    }
  }

  return { ...added, ...removed };
};
