import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Form, message } from 'antd';
import { FlexBox } from 'components/Helpers';
import _ from 'lodash';
import React from 'react';

interface Props {
  value?: Record<string, string>;
  onChange?: (value: Record<string, string>) => void;
  addCaption?: string;
  labelOptions?: Record<string, string[]>;
}

// See also LabelsFormList
const KeyValueForm: React.FC<Props> = ({
  value = {},
  onChange = () => {},
  addCaption = 'Label hinzufügen',
  labelOptions = {},
}) => {
  return (
    <>
      {Object.entries(value).map(([key, itemValue], index) => (
        <LabelInput
          key={index}
          labelOptions={labelOptions}
          remove={() => {
            onChange(_.omit(value, key));
          }}
          onChange={(newValue) => {
            if (
              key !== newValue.key &&
              newValue.key !== undefined &&
              value[newValue.key] !== undefined
            ) {
              message.error('Label bereits vorhanden');
              return;
            }

            onChange(
              Object.fromEntries(
                _.map(value, (v, k) => {
                  if (k === key) {
                    return [newValue.key, newValue.value];
                  } else {
                    return [k, v];
                  }
                }),
              ),
            );
          }}
          value={{
            key,
            value: itemValue,
          }}
        />
      ))}
      <Button
        type="dashed"
        onClick={() => {
          if (value[''] !== undefined) {
            message.error('Bitte erst das leere Feld ausfüllen');
            return;
          }
          onChange({
            ...value,
            '': '',
          });
        }}
        block
        icon={<PlusOutlined />}
      >
        {addCaption}
      </Button>
    </>
  );
};

export default KeyValueForm;

const LabelInput: React.FC<{
  remove: () => void;
  onChange: (value: Record<string, string>) => void;
  value: {
    key: string;
    value: string;
  };
  labelOptions: Record<string, string[]>;
}> = ({ remove, onChange, value, labelOptions }) => {
  const valueOptions = labelOptions[value.key] ?? [];

  return (
    <FlexBox alignitems="baseline" withgap gap={20}>
      <Form.Item label="Key" style={{ flex: 1 }}>
        <AutoComplete
          popupMatchSelectWidth={false}
          options={Object.keys(labelOptions).map((labelKey) => ({
            label: labelKey,
            value: labelKey,
          }))}
          onChange={(newValue) => {
            onChange({
              ...value,
              key: newValue,
            });
          }}
          value={value.key}
          filterOption={(input: string, option) =>
            option?.label.toLowerCase().includes(input.toLowerCase()) ?? false
          }
        />
      </Form.Item>
      <Form.Item label="Value" style={{ flex: 1 }}>
        <AutoComplete
          popupMatchSelectWidth={false}
          options={valueOptions.map((option) => ({
            label: option,
            value: option,
          }))}
          onChange={(newValue) => {
            onChange({
              ...value,
              value: newValue,
            });
          }}
          value={value.value}
          filterOption={(input: string, option) =>
            option?.label.toLowerCase().includes(input.toLowerCase()) ?? false
          }
        />
      </Form.Item>
      <MinusCircleOutlined onClick={remove} />
    </FlexBox>
  );
};
