import { useMutation } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { message } from 'antd';
import Breadcrumb from 'components/common/Breadcrumb';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import ResourceIcons from 'components/common/resourceIcons';
import OrganizationDeviceConfigurationForm from 'components/device-configuration/OrganizationDeviceConfigurationForm';
import { useManagementApiFromContext } from 'components/scaffold/OrganizationView';
import useManagementDevices from 'hooks/devices/useManagementDevices';
import { useManagementDeviceConfigurationsInvalidation } from 'hooks/useManagementDeviceConfigurations';
import { useManagementPathOrganization } from 'router';

const ManagementDeviceConfigurationCreateView: React.FC = () => {
  const navigate = useNavigate();
  const invalidateManagementDeviceConfigurations =
    useManagementDeviceConfigurationsInvalidation();

  const { postManagementDeviceConfiguration } = useManagementApiFromContext();

  const organization = useManagementPathOrganization();
  const { devices, isLoading: isDevicesLoading } = useManagementDevices();
  const {
    mutate: createOrganizationDeviceConfiguration,
    isPending: isCreating,
    error: createError,
  } = useMutation({
    mutationFn: postManagementDeviceConfiguration,
    onSuccess: () => {
      invalidateManagementDeviceConfigurations();
      message.success('Device Configuration wurde erstellt.');
      navigate({
        to: '/management/$organization/device-configurations',
        params: { organization },
      });
    },
  });

  return (
    <>
      <Breadcrumb
        items={[
          {
            name: 'Gerätekonfigurationen',
            href: `/management/${organization}/device-configurations`,
          },
        ]}
      />
      <PageTitleBar
        title="Gerätekonfiguration anlegen"
        Icon={ResourceIcons.DeviceConfiguration}
      />
      <PageContentContainer>
        <OrganizationDeviceConfigurationForm
          onSubmit={createOrganizationDeviceConfiguration}
          isLoading={isCreating}
          error={createError}
          fixedOrganization={organization}
          devices={devices}
          isDevicesLoading={isDevicesLoading}
        />
      </PageContentContainer>
    </>
  );
};

export default ManagementDeviceConfigurationCreateView;
