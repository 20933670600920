import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Form } from 'antd';
import { FlexBox } from 'components/Helpers';
import React, { useMemo, useState } from 'react';

interface Props {
  name: string;
  label: string;
  labelOptions?: Record<string, string[]>;
}

export function convertToFormList(
  labels: Record<string, string>,
): { key: string; value: string }[] {
  return Object.entries(labels).map(([key, value]) => ({
    key,
    value,
  }));
}

export function convertFromFormList(
  labels: { key: string; value: string }[] | undefined,
): Record<string, string> {
  return labels !== undefined
    ? Object.fromEntries(labels.map(({ key, value }) => [key, value]))
    : {};
}

// See also KeyValueForm
const LabelsFormList: React.FC<Props> = ({
  name,
  label,
  labelOptions = {},
}) => {
  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ name }) => (
            <LabelInput
              key={name}
              name={name}
              remove={remove}
              labelOptions={labelOptions}
            />
          ))}

          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
            >
              {label} hinzufügen
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default LabelsFormList;

const LabelInput: React.FC<{
  name: number;
  remove: (name: number) => void;
  labelOptions: Record<string, string[]>;
}> = ({ name, remove, labelOptions }) => {
  const [selectedKey, setSelectedKey] = useState('');

  const valueOptions = useMemo(
    () => (labelOptions[selectedKey] ?? []).sort(),
    [labelOptions, selectedKey],
  );

  return (
    <FlexBox alignitems="baseline" withgap gap={20}>
      <Form.Item label="Key" name={[name, 'key']} style={{ flex: 1 }}>
        <AutoComplete
          popupMatchSelectWidth={false}
          placeholder="Key"
          options={Object.keys(labelOptions).map((labelKey) => ({
            label: labelKey,
            value: labelKey,
          }))}
          onChange={(value) => setSelectedKey(value)}
          filterOption={(inputValue, option) =>
            option !== undefined
              ? option.value.toLowerCase().includes(inputValue.toLowerCase())
              : false
          }
        />
      </Form.Item>
      <Form.Item label="Value" name={[name, 'value']} style={{ flex: 1 }}>
        <AutoComplete
          popupMatchSelectWidth={false}
          placeholder="Value"
          options={valueOptions.map((option) => ({
            label: option,
            value: option,
          }))}
          filterOption={(inputValue, option) =>
            option !== undefined
              ? option.value.toLowerCase().includes(inputValue.toLowerCase())
              : false
          }
        />
      </Form.Item>
      <MinusCircleOutlined onClick={() => remove(name)} />
    </FlexBox>
  );
};
