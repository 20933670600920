import { InputNumber, Tooltip } from 'antd';
import type { NumberDeviceProperty } from 'defaults/deviceProperties';

interface Props {
  deviceProperty: NumberDeviceProperty;
  onChange?: (value: string) => void;
  value: string;
  style?: React.CSSProperties;
}

const NumberDevicePropertyValue: React.FC<Props> = ({
  deviceProperty,
  value,
  onChange,
  style,
}) => {
  const isDefault = String(deviceProperty.default) === value;

  return (
    <Tooltip
      title={isDefault ? 'Standardwert' : undefined}
      mouseEnterDelay={0.3}
    >
      <InputNumber
        stringMode
        value={value}
        onChange={(value) => {
          if (value == null) return;
          onChange?.(value);
        }}
        status={isDefault ? 'warning' : undefined}
        style={style}
      />
    </Tooltip>
  );
};

export default NumberDevicePropertyValue;
