import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';
import {
  Alert,
  Button,
  Popconfirm,
  Skeleton,
  Space,
  Table,
  message,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import Search from 'antd/lib/input/Search';
import Breadcrumb from 'components/common/Breadcrumb';
import IdTag from 'components/common/IdTag';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import ResourceIcons from 'components/common/resourceIcons';
import DeviceConfigurationCollapsible from 'components/device-configuration/DeviceConfigurationCollapsible';
import DuplicateManagementDeviceConfigurationButton from 'components/device-configuration/DuplicateManagementDeviceConfigurationButton';
import { useManagementApiFromContext } from 'components/scaffold/OrganizationView';
import useUserOrg from 'hooks/auth/useUserOrg';
import useJsonSearch from 'hooks/useJsonSearch';
import useManagementDeviceConfigurations from 'hooks/useManagementDeviceConfigurations';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import type { ManagementDeviceConfiguration } from 'interfaces/managementDeviceConfiguration';
import _ from 'lodash';
import { useMemo } from 'react';
import { useManagementPathOrganization } from 'router';
import getErrorMessage from 'utlis/getErrorMessage';

const ManagementDeviceConfigurationsView: React.FC = () => {
  const organization = useManagementPathOrganization();

  const { deleteManagementDeviceConfiguration } = useManagementApiFromContext();

  const userOrg = useUserOrg();

  const {
    data: configurations,
    refetch: refreshConfigurations,
    isLoading: isDeviceConfigsLoading,
    isError: isDeviceConfigError,
  } = useManagementDeviceConfigurations();

  const { filteredItems, setSearchText } = useJsonSearch(configurations || []);
  const { mutateAsync: removeOrganizationDeviceConfiguration } = useMutation({
    mutationFn: deleteManagementDeviceConfiguration,
    onSuccess: () => {
      refreshConfigurations();
    },
    onError: (error: any) => {
      message.error(getErrorMessage(error));
    },
  });

  const getColumnSearchProps =
    useTableSearchFilter<ManagementDeviceConfiguration>();

  const columns = useMemo<ColumnsType<ManagementDeviceConfiguration>>(
    () => [
      {
        title: 'ID',
        width: '80px',
        sorter: (a, b) => a.id.localeCompare(b.id),
        ...getColumnSearchProps(
          'id',
          'id',
          (record) => record.id,
          (record, _, searchText) => (
            <IdTag id={record.id} highlight={searchText} />
          ),
        ),
      },
      {
        title: 'Anzahl der Geräte',
        width: '40px',
        sorter: (a, b) => a.devicesCount - b.devicesCount,
        render: (_, config) => config.devicesCount,
      },
      {
        title: 'Gerätekonfiguration',
        render: (_, config) => (
          <DeviceConfigurationCollapsible config={config} />
        ),
      },
      {
        title: 'Priorität',
        sorter: (a, b) => a.priority - b.priority,
        render: (_, config) => config.priority,
      },
      {
        title: 'Actions',
        render: (_, config) => (
          <Space>
            <Link
              to="/management/$organization/device-configurations/$deviceConfigurationId/edit"
              params={{
                organization,
                deviceConfigurationId: config.id,
              }}
            >
              <Button type="text" icon={<EditOutlined />} />
            </Link>
            <DuplicateManagementDeviceConfigurationButton
              managementDeviceConfiguration={config}
            />
            <Popconfirm
              title="Sind sie sicher, dass sie diese Konfiguration löschen möchten?"
              onConfirm={() => removeOrganizationDeviceConfiguration(config.id)}
              okText="Ja"
              cancelText="Abbrechen"
            >
              <Button type="text" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        ),
      },
    ],
    [getColumnSearchProps, organization, removeOrganizationDeviceConfiguration],
  );

  return (
    <>
      <Breadcrumb
        items={[
          {
            name: 'Gerätekonfigurationen',
            href: `/management/${organization}/device-configurations`,
          },
        ]}
      />
      <PageTitleBar
        title="Gerätekonfigurationen"
        Icon={ResourceIcons.DeviceConfiguration}
        renderToolbar={() => (
          <Link
            to="/management/$organization/device-configurations/create"
            params={{ organization }}
          >
            <Button icon={<PlusOutlined />}>Konfiguration Erstellen</Button>
          </Link>
        )}
      />
      <PageContentContainer>
        {isDeviceConfigError ? (
          <Alert type="error" message="Something went wrong" />
        ) : !userOrg ? (
          <Alert
            type="error"
            message="Sie müssen Teil einer Organisation sein um diese Seite zu sehen."
          />
        ) : isDeviceConfigsLoading ? (
          <Skeleton active />
        ) : (
          <>
            <Search
              placeholder="Schnellsuche"
              onChange={_.debounce((e) => setSearchText(e.target.value), 500)}
              style={{
                maxWidth: '800px',
                marginBottom: '16px',
              }}
            />
            <Table dataSource={filteredItems} columns={columns} />
          </>
        )}
      </PageContentContainer>
    </>
  );
};

export default ManagementDeviceConfigurationsView;
