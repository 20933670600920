import { Descriptions, type DescriptionsProps, Typography } from 'antd';
import { FlexBox } from 'components/Helpers';
import type { Feature } from 'defaults/featuresToDeviceProperties';

interface Props {
  feature: Feature;
}
const FeatureInfo: React.FC<Props> = ({ feature }) => {
  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      children: <Typography.Title level={5}>{feature.name}</Typography.Title>,
      span: 4,
    },
    {
      key: '3',
      children: feature.description,
      span: 4,
    },
    {
      key: '4',
      label: 'Device Properties',
      children: (
        <FlexBox direction="column">
          {Object.entries(feature.properties).map(([k, v]) => (
            <div key={k}>
              {k}: {v}
            </div>
          ))}
        </FlexBox>
      ),
      span: 4,
    },
  ];

  return <Descriptions items={items} layout="vertical" />;
};

export default FeatureInfo;
