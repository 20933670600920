import type { FeatureRenderProps } from '../../../../defaults/featuresToDeviceProperties';
import UserManagerOIDCConfigForm from './UserManagerOIDCConfigForm';
import UserManagerOIDCConfigSummary from './UserManagerOIDCConfigSummary';

const UserManagerOIDCConfigFeature: React.FC<FeatureRenderProps> = ({
  values,
  onUpdate,
  isEditing,
  onEditDone,
}) => {
  if (isEditing) {
    return (
      <UserManagerOIDCConfigForm
        initialProperties={values}
        onSubmit={onUpdate}
        onEditDone={onEditDone}
      />
    );
  }

  return <UserManagerOIDCConfigSummary properties={values} />;
};

export default UserManagerOIDCConfigFeature;
