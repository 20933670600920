import { Radio } from 'antd';
import type { FeatureRenderProps } from 'defaults/featuresToDeviceProperties';

const SingleSelectFeature = ({
  onUpdate,
  options,
  valueKey,
  values,
}: FeatureRenderProps & {
  options: {
    label: string;
    value: string;
  }[];
  valueKey: string;
}) => (
  <Radio.Group
    onChange={(event) => {
      onUpdate({
        [valueKey]: event.target.value,
      });
    }}
    options={options}
    optionType="button"
    value={values[valueKey]}
  />
);

export const createSingleSelectFeature =
  (
    valueKey: string,
    options: {
      label: string;
      value: string;
    }[],
  ) =>
  // eslint-disable-next-line react/display-name
  (props: FeatureRenderProps) => (
    <SingleSelectFeature {...props} options={options} valueKey={valueKey} />
  );
