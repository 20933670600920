import { Button, Checkbox, Input } from 'antd';
import { FlexBox } from 'components/Helpers';
import { useState } from 'react';
import type { ShiftsOverwriteConfig } from './ShiftsOverwriteFeature';

interface Props {
  initialValue: ShiftsOverwriteConfig | null;
  onSubmit: (config: FormData) => void;
}

interface ShiftsOverwriteConfigTimeFormData {
  days: number;
  hours: number | null;
  minutes: number | null;
}

interface FormData {
  early: {
    start: ShiftsOverwriteConfigTimeFormData;
    end: ShiftsOverwriteConfigTimeFormData;
  };
  late: {
    start: ShiftsOverwriteConfigTimeFormData;
    end: ShiftsOverwriteConfigTimeFormData;
  };
  night: {
    start: ShiftsOverwriteConfigTimeFormData;
    end: ShiftsOverwriteConfigTimeFormData;
  };
}

const ShiftsOverwriteFeatureForm: React.FC<Props> = ({
  initialValue,
  onSubmit,
}) => {
  const [formValue, setFormValue] = useState<FormData>(
    initialValue ?? {
      early: {
        start: { days: 0, hours: null, minutes: null },
        end: { days: 0, hours: null, minutes: null },
      },
      late: {
        start: { days: 0, hours: null, minutes: null },
        end: { days: 0, hours: null, minutes: null },
      },
      night: {
        start: { days: 0, hours: null, minutes: null },
        end: { days: 0, hours: null, minutes: null },
      },
    },
  );

  const renderTime = (
    time: ShiftsOverwriteConfigTimeFormData,
    onUpdate: (newTime: ShiftsOverwriteConfigTimeFormData) => void,
  ) => {
    return (
      <FlexBox withgap gap={20} alignitems="center">
        <FlexBox withgap gap={6} alignitems="center">
          <Input
            style={{ width: 50, textAlign: 'center' }}
            placeholder="0"
            value={time.hours?.toString() ?? ''}
            onChange={(event) => {
              const newHours = parseInt(event.target.value);
              onUpdate({
                ...time,
                hours: Number.isNaN(newHours) ? null : newHours,
              });
            }}
          />
          <span>:</span>
          <Input
            style={{ width: 50, textAlign: 'center' }}
            placeholder="00"
            value={time.minutes?.toString()?.padStart(2, '0') ?? ''}
            onChange={(event) => {
              const newMinutes = parseInt(event.target.value);
              onUpdate({
                ...time,
                minutes: Number.isNaN(newMinutes) ? null : newMinutes,
              });
            }}
          />
          <span>Uhr</span>
        </FlexBox>
        <Checkbox
          checked={time.days === 1}
          onChange={(event) => {
            onUpdate({ ...time, days: event.target.checked ? 1 : 0 });
          }}
        >
          Nächster Tag
        </Checkbox>
      </FlexBox>
    );
  };

  const renderShift = (
    name: string,
    shift: {
      start: ShiftsOverwriteConfigTimeFormData;
      end: ShiftsOverwriteConfigTimeFormData;
    },
    onUpdate: (newShift: {
      start: ShiftsOverwriteConfigTimeFormData;
      end: ShiftsOverwriteConfigTimeFormData;
    }) => void,
  ) => {
    return (
      <tr>
        <td>{name}</td>
        <td>
          {renderTime(shift.start, (newTime) =>
            onUpdate({
              ...shift,
              start: newTime,
            }),
          )}
        </td>
        <td>
          {renderTime(shift.end, (newTime) =>
            onUpdate({
              ...shift,
              end: newTime,
            }),
          )}
        </td>
      </tr>
    );
  };

  return (
    <FlexBox direction="column" withgap>
      <table style={{ textAlign: 'left' }} cellPadding={4}>
        <thead>
          <tr>
            <th>Schicht</th>
            <th>Start</th>
            <th>Ende</th>
          </tr>
        </thead>
        {renderShift('Frühschicht', formValue.early, (newShift) =>
          setFormValue({
            ...formValue,
            early: newShift,
          }),
        )}
        {renderShift('Spätschicht', formValue.late, (newShift) =>
          setFormValue({
            ...formValue,
            late: newShift,
          }),
        )}
        {renderShift('Nachtschicht', formValue.night, (newShift) =>
          setFormValue({
            ...formValue,
            night: newShift,
          }),
        )}
      </table>
      <Button
        type="primary"
        style={{ alignSelf: 'flex-start' }}
        onClick={() => {
          onSubmit(formValue);
        }}
      >
        Speichern
      </Button>
    </FlexBox>
  );
};

export default ShiftsOverwriteFeatureForm;
