import { Form, Select } from 'antd';
import {
  deviceConfigurationKey,
  seniorCareDeviceManagementIntegrationConfigurationKey,
} from 'deviceMetadataKeys';
import useManagementDeviceConfigurations from 'hooks/useManagementDeviceConfigurations';
import type { ManagementDeviceConfiguration } from 'interfaces/managementDeviceConfiguration';
import { useMemo } from 'react';
import {
  getConfigSelectorLabelValue,
  getConfigSelectorLabelValueOrThrow,
} from '../device-configuration/DeviceConfigurationHelper';
import {
  type SeniorCareDeviceConfig,
  toSeniorCareDeviceConfig,
} from './SeniorCareDeviceConfig';
import {
  type SeniorCareIntegrationConfig,
  toSeniorCareIntegrationConfig,
} from './SeniorCareIntegrationConfig';

interface Props {
  deviceConfigurations: ManagementDeviceConfiguration[];
  initialIntegrationConfig?: string;
  initialDeviceConfig?: string;
}

export function getSeniorCareIntegrationConfigs(
  deviceConfigurations: ManagementDeviceConfiguration[],
) {
  return deviceConfigurations.filter(
    (config) =>
      getConfigSelectorLabelValue(
        config,
        seniorCareDeviceManagementIntegrationConfigurationKey,
      ) !== null,
  );
}

export function useSeniorCareIntegrationConfigs(): SeniorCareIntegrationConfig[] {
  const { data: deviceConfigurations } = useManagementDeviceConfigurations();

  return useMemo(
    () =>
      getSeniorCareIntegrationConfigs(deviceConfigurations ?? []).map(
        toSeniorCareIntegrationConfig,
      ),
    [deviceConfigurations],
  );
}

export function useSeniorCareDeviceConfigs(): SeniorCareDeviceConfig[] {
  const { data: deviceConfigurations } = useManagementDeviceConfigurations();

  return useMemo(
    () =>
      getDeviceConfigs(deviceConfigurations ?? []).map(
        toSeniorCareDeviceConfig,
      ),
    [deviceConfigurations],
  );
}

export function getDeviceConfigs(
  deviceConfigurations: ManagementDeviceConfiguration[],
): ManagementDeviceConfiguration[] {
  return deviceConfigurations.filter(
    (config) =>
      getConfigSelectorLabelValue(config, deviceConfigurationKey) !== null,
  );
}

const SeniorCareDeviceConfigurationForm: React.FC<Props> = ({
  deviceConfigurations,
  initialDeviceConfig,
  initialIntegrationConfig,
}) => {
  const integrationConfigs = useMemo(
    () =>
      getSeniorCareIntegrationConfigs(deviceConfigurations).map((config) => ({
        id: config.id,
        selector: getConfigSelectorLabelValueOrThrow(
          config,
          seniorCareDeviceManagementIntegrationConfigurationKey,
        ),
        name: config.name,
      })),
    [deviceConfigurations],
  );

  const deviceConfigs = useMemo(
    () =>
      getDeviceConfigs(deviceConfigurations).map((config) => ({
        id: config.id,
        selector: getConfigSelectorLabelValueOrThrow(
          config,
          deviceConfigurationKey,
        ),
        name: config.name,
      })),
    [deviceConfigurations],
  );

  return (
    <>
      <Form.Item
        name="integration"
        label="Schnittstelle"
        labelAlign="left"
        labelCol={{ span: 6 }}
        rules={[
          { required: true, message: 'Bitte wähle eine Schnittstelle aus.' },
        ]}
        initialValue={
          integrationConfigs.length === 1
            ? integrationConfigs[0]?.selector
            : initialIntegrationConfig
        }
      >
        <Select
          showSearch={true}
          optionFilterProp="label"
          options={integrationConfigs.map((config) => ({
            label: config.name,
            value: config.selector,
          }))}
        />
      </Form.Item>
      <Form.Item
        name="deviceconfig"
        label="Gerätekonfiguration"
        labelAlign="left"
        labelCol={{ span: 6 }}
        rules={[
          {
            required: true,
            message: 'Bitte wähle eine Gerätekonfiguration aus.',
          },
        ]}
        initialValue={
          deviceConfigs.length === 1
            ? deviceConfigs[0]?.selector
            : initialDeviceConfig
        }
      >
        <Select
          showSearch={true}
          optionFilterProp="label"
          options={deviceConfigs.map((config) => ({
            label: config.name,
            value: config.selector,
          }))}
        />
      </Form.Item>
    </>
  );
};

export default SeniorCareDeviceConfigurationForm;
