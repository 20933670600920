import { useQuery } from '@tanstack/react-query';
import {
  Alert,
  Button,
  Form,
  Radio,
  Select,
  Tag,
  TreeSelect,
  Typography,
} from 'antd';
import {
  type GetIntegrationAreasRequest,
  type SeniorCareIntegrationArea,
  StandardizedClientAuthMethod,
  useSeniorCareApi,
} from 'api/seniorcare/useSeniorCareApi';
import { FlexBox } from 'components/Helpers';
import { getSeniorCareIntegrationConfigs } from 'components/seniorcare/SeniorCareDeviceConfigurationAssignment';
import {
  IntegrationType,
  SeniorCareIntegrationConfigSchema,
} from 'components/seniorcare/SeniorCareIntegrationConfig';
import useManagementDeviceConfigurations from 'hooks/useManagementDeviceConfigurations';
import Joi from 'joi';
import { useCallback, useMemo, useState } from 'react';
import { UnreachableCaseError } from 'ts-essentials';
import { validateJsonProperty, validateOrThrow } from 'utlis/validationUtil';
import type { FeatureRenderProps } from '../../../../defaults/featuresToDeviceProperties';

export const integrationAreasDevicePropertyKey = 'INTEGRATION_AREAS';

export const SeniorIntegrationAreasSchema = Joi.array<string[]>().items(
  Joi.string(),
);

export const StandardizedApiAuthenticationConfigSchema = Joi.object<{
  tokenUrl: string;
  clientAuthMethod: StandardizedClientAuthMethod;
}>({
  tokenUrl: Joi.string().required(),
  clientAuthMethod: Joi.string()
    .valid(...Object.values(StandardizedClientAuthMethod))
    .required(),
});

export const StandardizedUserAuthenticationOIDCConfigSchema = Joi.object<{
  clientId: string;
  clientSecret?: string;
  clientAuthMethod?: StandardizedClientAuthMethod;
  discoveryUri: string;
  scopes: string[];
  isRefreshTokenRotationEnabled: boolean;
  /**
   * Not used in senior care.
   */
  refreshTokenExpirationDuration?: string | null;
  endSessionOnLogout: boolean;
  skipEmailVerifiedCheck?: boolean;
  useRefreshTokens?: boolean;
  forceReAuthenticate?: boolean;
  responseMode?: string | null;
  additionalAuthorizationParameters?: Record<string, string> | null;
}>({
  clientId: Joi.string().required(),
  clientSecret: Joi.string().optional(),
  clientAuthMethod: Joi.string()
    .valid(...Object.values(StandardizedClientAuthMethod))
    .when('clientSecret', {
      is: Joi.exist(),
      then: Joi.required(),
      otherwise: Joi.optional(),
    }),
  discoveryUri: Joi.string().uri().required(),
  scopes: Joi.array().items(Joi.string()).required(),
  isRefreshTokenRotationEnabled: Joi.boolean().required(),
  refreshTokenExpirationDuration: Joi.string().allow(null),
  endSessionOnLogout: Joi.boolean().required(),
  skipEmailVerifiedCheck: Joi.boolean(),
  useRefreshTokens: Joi.boolean(),
  forceReAuthenticate: Joi.boolean(),
  responseMode: Joi.string().allow(null),
  additionalAuthorizationParameters: Joi.object()
    .pattern(Joi.string(), Joi.string())
    .allow(null),
});

const SeniorCareIntegrationAreasFeature: React.FC<FeatureRenderProps> = ({
  values,
  onUpdate,
  isEditing,
  onEditDone,
}) => {
  const { getIntegrationAreas } = useSeniorCareApi();

  const { data: deviceConfigurations } = useManagementDeviceConfigurations();

  const integrationDeviceConfigs = useMemo(
    () => getSeniorCareIntegrationConfigs(deviceConfigurations ?? []),
    [deviceConfigurations],
  );

  const [integrationAreas, validationError] = useMemo(
    () =>
      validateJsonProperty(
        values[integrationAreasDevicePropertyKey],
        SeniorIntegrationAreasSchema,
      ),
    [values],
  );

  const [form] = Form.useForm<{ integrationAreas: string[] }>();
  const [
    selectedIntegrationDeviceConfigId,
    setSelectedIntegrationDeviceConfigId,
  ] = useState<string | null>(null);

  const selectedIntegrationDeviceConfig = useMemo(
    () =>
      selectedIntegrationDeviceConfigId !== null
        ? (deviceConfigurations?.find(
            (config) => config.id === selectedIntegrationDeviceConfigId,
          ) ?? null)
        : null,
    [selectedIntegrationDeviceConfigId, deviceConfigurations],
  );

  const loadIntegrationAreas = async (): Promise<
    SeniorCareIntegrationArea[]
  > => {
    if (selectedIntegrationDeviceConfig === null) {
      return [];
    }

    const integrationConfig = validateOrThrow(
      JSON.parse(
        selectedIntegrationDeviceConfig.properties['INTEGRATION_CONFIG_V2'] ??
          '{}',
      ),
      SeniorCareIntegrationConfigSchema,
    );

    const request: GetIntegrationAreasRequest = (() => {
      switch (integrationConfig.type) {
        case IntegrationType.VIVENDI:
          return {
            type: IntegrationType.VIVENDI,
            baseUrl: integrationConfig.baseUrl,
            credentialsId: integrationConfig.credentialsId,
            credentialsSecret: integrationConfig.credentialsSecret,
            useWindowsLogin:
              selectedIntegrationDeviceConfig.properties[
                'USE_VIVENDI_WINDOWS_LOGIN'
              ] === 'true' &&
              selectedIntegrationDeviceConfig.properties[
                'DISABLE_SYNC_USER_VIVENDI_WINDOWS_LOGIN'
              ] !== 'true',
          };
        case IntegrationType.DAN:
          return {
            type: IntegrationType.DAN,
            baseUrl: integrationConfig.baseUrl,
            credentialsId: integrationConfig.credentialsId,
            credentialsSecret: integrationConfig.credentialsSecret,
          };
        case IntegrationType.STANDARDIZED: {
          const [config, error] = validateJsonProperty(
            selectedIntegrationDeviceConfig.properties[
              'STANDARDIZED_API_AUTHENTICATION_CONFIG'
            ],
            StandardizedApiAuthenticationConfigSchema,
          );

          if (config === null) {
            throw new Error(
              `Standardized API authentication config invalid: ${error}`,
            );
          }

          return {
            type: IntegrationType.STANDARDIZED,
            baseUrl: integrationConfig.baseUrl,
            credentialsId: integrationConfig.credentialsId,
            credentialsSecret: integrationConfig.credentialsSecret,
            tokenUrl: config.tokenUrl,
            clientAuthMethod: config.clientAuthMethod,
          };
        }
        default:
          throw new UnreachableCaseError(integrationConfig.type);
      }
    })();

    return await getIntegrationAreas(request);
  };

  const {
    data: loadedIntegrationAreas,
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: [
      'senior-care-integration-areas',
      selectedIntegrationDeviceConfig,
    ],
    enabled: false,
    queryFn: loadIntegrationAreas,
  });

  const [areaSelectionMode, setAreaSelectionMode] = useState<
    'loaded' | 'manual'
  >(
    values[integrationAreasDevicePropertyKey] !== undefined
      ? 'manual'
      : 'loaded',
  );

  const onChange = useCallback(
    (integrationAreas: string[]) => {
      onUpdate({
        [integrationAreasDevicePropertyKey]: JSON.stringify(integrationAreas),
      });
    },
    [onUpdate],
  );

  if (isEditing) {
    return (
      <Form<{ integrationAreas: string[] }>
        id="senior-care-integration-areas-feature"
        initialValues={{
          integrationAreas: integrationAreas ?? [],
        }}
        form={form}
        onFinish={(values) => {
          onUpdate({
            [integrationAreasDevicePropertyKey]: JSON.stringify(
              values.integrationAreas,
            ),
          });
          onEditDone?.();
        }}
      >
        <Form.Item>
          <Radio.Group
            onChange={(e) => {
              setAreaSelectionMode(e.target.value);
            }}
            value={areaSelectionMode}
          >
            <Radio.Button value="loaded">Aus Schnittstelle laden</Radio.Button>
            <Radio.Button value="manual">Manuell</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {areaSelectionMode === 'loaded' ? (
          <>
            <FlexBox direction="column">
              <Form.Item label="Schnittstelle">
                <FlexBox withgap>
                  <Select
                    options={integrationDeviceConfigs.map((config) => ({
                      label: config.name,
                      value: config.id,
                    }))}
                    onChange={(value) => {
                      setSelectedIntegrationDeviceConfigId(value);
                      onChange([]);
                    }}
                    value={selectedIntegrationDeviceConfigId}
                    placeholder="Bitte wählen Sie eine Schnittstelle."
                    showSearch={true}
                    filterOption={true}
                    optionFilterProp="label"
                  />
                  <Button
                    disabled={selectedIntegrationDeviceConfigId === null}
                    onClick={() => {
                      refetch();
                    }}
                    loading={isLoading}
                  >
                    Bereiche laden
                  </Button>
                </FlexBox>
              </Form.Item>
              {loadedIntegrationAreas !== undefined && (
                <Form.Item name="integrationAreas">
                  <TreeSelect
                    treeData={toTreeNodes(loadedIntegrationAreas)}
                    treeNodeFilterProp="title"
                    treeNodeLabelProp="label"
                    showSearch={true}
                    multiple={true}
                    treeCheckable={true}
                    treeCheckStrictly={true} // important so that children are not automatically selected
                    placeholder="Bitte wählen Sie einen Bereich."
                    style={{ width: '100%' }}
                    onChange={(selected) => {
                      const integrationAreas = selected.map(
                        (s: any) => s.value,
                      );
                      form.setFieldsValue({
                        integrationAreas,
                      });
                      if (onEditDone === undefined) {
                        onChange(integrationAreas);
                      }
                    }}
                  />
                </Form.Item>
              )}
              {error !== null && (
                <Alert
                  message="Bereiche konnten nicht geladen werden."
                  description={error.message}
                  type="error"
                  showIcon
                />
              )}
            </FlexBox>
          </>
        ) : (
          <>
            <Typography.Text
              type="secondary"
              style={{ display: 'block', marginBottom: 10 }}
            >
              Trage die IDs der Bereiche ein, die für das Gerät konfiguriert
              werden soll.
            </Typography.Text>
            <Form.Item
              name="integrationAreas"
              rules={[{ required: true, type: 'array' }]}
              initialValue={JSON.parse(
                values[integrationAreasDevicePropertyKey] ?? '[]',
              )}
            >
              <Select
                mode="tags"
                placeholder="Bereiche angeben"
                onChange={(value) => {
                  form.setFieldsValue({ integrationAreas: value });

                  if (onEditDone === undefined) {
                    onChange(value);
                  }
                }}
              />
            </Form.Item>
          </>
        )}
        {onEditDone !== undefined && (
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => form.submit()}
          >
            Speichern
          </Button>
        )}
      </Form>
    );
  } else {
    return (
      <FlexBox>
        {validationError !== null && (
          <Alert
            message="Fehler"
            description={validationError}
            type="error"
            showIcon
          />
        )}
        <FlexBox wrap withgap>
          {integrationAreas !== null &&
            integrationAreas.map((area, index) => (
              <Tag key={index} style={{ margin: 0 }}>
                {area}
              </Tag>
            ))}
        </FlexBox>
      </FlexBox>
    );
  }
};

export interface TreeNode {
  key: string;
  value: string;
  title: string;
  label: string;
  children?: TreeNode[];
}

export const toTreeNodes = (areas: SeniorCareIntegrationArea[]): TreeNode[] => {
  const rootAreas = areas.filter(
    (area) => !areas.find((a) => a.id === area.parentId),
  );

  return rootAreas.map((root) => buildTree(areas, root));
};

export const buildTree = (
  areas: SeniorCareIntegrationArea[],
  currentNode: SeniorCareIntegrationArea,
) => {
  const children = areas.filter((area) => area.parentId === currentNode.id);

  const node: TreeNode = {
    key: currentNode.id,
    title: `${currentNode.name} (${currentNode.id})`,
    value: currentNode.id,
    label: `${currentNode.name} (${currentNode.id})`,
    children: children.map((child) => buildTree(areas, child)),
  };

  return node;
};

export default SeniorCareIntegrationAreasFeature;
