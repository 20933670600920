import { ArrowRightOutlined } from '@ant-design/icons';
import { useNavigate } from '@tanstack/react-router';
import { Modal, Select } from 'antd';
import useIsAdmin from 'hooks/auth/useIsAdmin';
import useOrganizations from 'hooks/organizations/useOrganizations';
import useRegisterShortcutListener from 'hooks/useRegisterShortcutListener';
import { useCallback, useState } from 'react';
import { ShortcutEvent } from 'utlis/shortcuts';

const OrganizationSwitcherShortcut: React.FC = () => {
  const isAdmin = useIsAdmin();
  const [isOrganizationSwitchModalOpen, setIsOrganizationSwitchModalOpen] =
    useState(false);

  useRegisterShortcutListener(
    ShortcutEvent.SWITCH_ORGANIZATION,
    useCallback(() => {
      setIsOrganizationSwitchModalOpen(true);
    }, []),
    isAdmin,
  );

  if (!isOrganizationSwitchModalOpen) return null;

  return (
    <OrganizationSwitcherModal
      onClose={() => setIsOrganizationSwitchModalOpen(false)}
    />
  );
};

const OrganizationSwitcherModal: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const navigate = useNavigate();
  const organizations = useOrganizations();

  const input = useCallback<any>((element: any) => {
    if (element) {
      element.focus();
    }
  }, []);

  return (
    <Modal
      open={true}
      closable={false}
      onCancel={onClose}
      footer={null}
      width={400}
      styles={{
        content: {
          background: 'transparent',
          boxShadow: 'none',
          padding: '0',
        },
      }}
    >
      <Select
        ref={input}
        suffixIcon={<ArrowRightOutlined />}
        onChange={(value) => {
          navigate({
            to: '/management/$organization',
            params: { organization: value },
          });
          onClose();
        }}
        showSearch={true}
        options={organizations.sortedOrganizations.map((organization) => ({
          label: organization.name,
          value: organization.id,
        }))}
        size="large"
        style={{ width: '100%' }}
        placeholder="Organisation wählen"
        autoFocus={true}
        filterOption={(input, option) => {
          if (!input || !option) return true;
          const searchTerm = input.toLowerCase();
          return (
            option.value?.toLowerCase().includes(searchTerm) ||
            option.label?.toLowerCase().includes(searchTerm)
          );
        }}
      />
    </Modal>
  );
};

export default OrganizationSwitcherShortcut;
