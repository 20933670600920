import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import {
  Alert,
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Table,
  Tooltip,
  Typography,
  message,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useAtom } from 'jotai';
import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { FlexBox } from 'components/Helpers';
import Breadcrumb from 'components/common/Breadcrumb';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import TagList from 'components/common/TagList';
import DevicePropertiesForm from 'components/common/device-properties/DevicePropertiesForm';
import ResourceIcons from 'components/common/resourceIcons';
import DuplicateManagementDeviceConfigurationButton from 'components/device-configuration/DuplicateManagementDeviceConfigurationButton';
import { integrationAreasDevicePropertyKey } from 'components/device-configuration/features/senior-care-integration-config/SeniorCareIntegrationAreasFeature';
import { useManagementApiFromContext } from 'components/scaffold/OrganizationView';
import { useSeniorCareDeviceConfigs } from 'components/seniorcare/SeniorCareDeviceConfigurationAssignment';
import DevicePropertiesList from 'components/views/DevicePropertiesList';
import { seniorCareAppDeviceRole } from 'defaults/deviceRoles';
import { features } from 'defaults/featuresToDeviceProperties';
import { deviceConfigurationKey } from 'deviceMetadataKeys';
import useManagementDeviceConfigurations, {
  useManagementDeviceConfigurationsInvalidation,
} from 'hooks/useManagementDeviceConfigurations';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import {
  type ManagementDeviceConfiguration,
  type PostManagementDeviceConfiguration,
  type PutManagementDeviceConfiguration,
} from 'interfaces/managementDeviceConfiguration';
import { Operator } from 'interfaces/matchers';
import { useNullablePathOrganization } from 'router';
import { isUserAdvancedAtom } from 'state/state';
import type { SeniorCareDeviceConfig } from './SeniorCareDeviceConfig';

// Types for the form data
interface DeviceConfigFormData {
  name: string;
  properties: Record<string, string>;
}

// Configuration Form component
const ConfigForm: React.FC<{
  error: Error | null;
  isSubmitting: boolean;
  onSubmit: (data: DeviceConfigFormData) => void;
  initialValues?: DeviceConfigFormData;
}> = ({
  onSubmit,
  isSubmitting,
  initialValues = { name: '', properties: {} },
  error,
}) => {
  const [isUserAdvanced] = useAtom(isUserAdvancedAtom);

  return (
    <Form<DeviceConfigFormData>
      initialValues={initialValues}
      onFinish={onSubmit}
    >
      {error !== null && (
        <Form.Item>
          <Alert
            message="Ein Fehler ist aufgetreten."
            description={error.message}
            type="error"
            showIcon
          />
        </Form.Item>
      )}
      <Form.Item
        label="Name"
        labelAlign="left"
        labelCol={{ span: 6 }}
        name="name"
        rules={[
          { required: true, message: 'Bitte geben Sie einen Namen ein.' },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="properties" noStyle />
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.properties !== currentValues.properties
        }
      >
        {({ getFieldValue, setFieldValue }) => (
          <DevicePropertiesForm
            value={getFieldValue('properties')}
            onChange={(properties) => setFieldValue('properties', properties)}
            canAdd={isUserAdvanced}
            canDelete={isUserAdvanced}
          />
        )}
      </Form.Item>
      <Button
        key="submit"
        htmlType="submit"
        type="primary"
        loading={isSubmitting}
      >
        Speichern
      </Button>
    </Form>
  );
};

// Section header component with title, description and tooltip
const SectionHeader: React.FC<{
  title: string;
  description: string;
  tooltipTitle: string;
}> = ({ title, description, tooltipTitle }) => (
  <div>
    <Tooltip title={tooltipTitle} placement="right">
      <Typography.Title level={4} style={{ margin: 0, cursor: 'help' }}>
        {title}
      </Typography.Title>
    </Tooltip>
    <Typography.Paragraph type="secondary">
      {description}
      <Tooltip title={tooltipTitle}>
        <span className="ml-2 cursor-help">
          <InfoCircleOutlined className="text-base" />
        </span>
      </Tooltip>
    </Typography.Paragraph>
  </div>
);

// Check if a device configuration is organization-wide
const isOrganizationWideConfig = (
  config:
    | ManagementDeviceConfiguration
    | PutManagementDeviceConfiguration
    | PostManagementDeviceConfiguration,
): boolean => {
  return (
    config.deviceSelector.matchRole?.in?.length === 1 &&
    config.deviceSelector.matchRole.in[0] === seniorCareAppDeviceRole &&
    (!config.deviceSelector.matchLabels ||
      config.deviceSelector.matchLabels.length === 0)
  );
};

const SeniorCareDeviceConfigsView = () => {
  const [isUserAdvanced] = useAtom(isUserAdvancedAtom);
  const organization = useNullablePathOrganization();
  const [editItem, setEditItem] =
    useState<ManagementDeviceConfiguration | null>(null);
  const [isCreateViewOpen, setIsCreateViewOpen] = useState(false);
  const [isCreateOrgWideViewOpen, setIsCreateOrgWideViewOpen] = useState(false);

  const { data: allDeviceConfigs, isError: isAllDeviceConfigsError } =
    useManagementDeviceConfigurations();
  const deviceConfigs = useSeniorCareDeviceConfigs();

  const organizationWideConfigs = useMemo(() => {
    if (!allDeviceConfigs) return [];
    return allDeviceConfigs.filter(isOrganizationWideConfig);
  }, [allDeviceConfigs]);

  const {
    putManagementDeviceConfiguration,
    postManagementDeviceConfiguration,
    deleteManagementDeviceConfiguration,
  } = useManagementApiFromContext();
  const invalidateManagementDeviceConfigurations =
    useManagementDeviceConfigurationsInvalidation();

  const {
    mutate: createConfig,
    error: createError,
    isPending: isSubmittingCreation,
  } = useMutation({
    mutationFn: postManagementDeviceConfiguration,
    onSuccess: (_, variables) => {
      invalidateManagementDeviceConfigurations();
      const isOrgWide = isOrganizationWideConfig(variables);
      message.success(
        isOrgWide
          ? 'Organisationsweite Konfiguration wurde erstellt und wird auf alle Geräte angewendet.'
          : 'Konfiguration wurde erstellt.',
      );
      setIsCreateViewOpen(false);
      setIsCreateOrgWideViewOpen(false);
    },
    onError: (error) => {
      message.error(
        `Fehler beim Erstellen der Konfiguration: ${error.message}`,
      );
    },
  });

  const {
    mutate: updateConfig,
    error: updateError,
    isPending: isSubmittingEdit,
  } = useMutation({
    mutationFn: async (data: PutManagementDeviceConfiguration) => {
      if (editItem === null) throw new Error('Edit item is null');
      await putManagementDeviceConfiguration(editItem.id, data);
    },
    onSuccess: () => {
      invalidateManagementDeviceConfigurations();
      message.success('Konfiguration wurde bearbeitet.');
      setEditItem(null);
    },
    onError: (error) => {
      message.error(
        `Fehler beim Bearbeiten der Konfiguration: ${error.message}`,
      );
    },
  });

  const { mutate: deleteConfig, isPending: isDeleting } = useMutation({
    mutationFn: deleteManagementDeviceConfiguration,
    onSuccess: () => {
      invalidateManagementDeviceConfigurations();
      message.success('Konfiguration wurde gelöscht.');
    },
    onError: (error) => {
      message.error(`Fehler beim Löschen der Konfiguration: ${error.message}`);
    },
  });

  const getColumnSearchProps = useTableSearchFilter<SeniorCareDeviceConfig>();
  const getOrgWideColumnSearchProps =
    useTableSearchFilter<ManagementDeviceConfiguration>();

  const configColumns = [
    {
      title: 'Name',
      ...getColumnSearchProps('name', 'name', (record) => record.name),
    },
    {
      title: 'Bereiche',
      render: (_: unknown, config: SeniorCareDeviceConfig) => (
        <TagList tags={config.areaIds ?? []} />
      ),
    },
    {
      title: 'Geräte',
      render: (_: unknown, config: SeniorCareDeviceConfig) =>
        config.devicesCount,
    },
    {
      title: 'Eigenschaften',
      render: (_: unknown, config: SeniorCareDeviceConfig) => (
        <DevicePropertiesList deviceProperties={config.properties} />
      ),
    },
    {
      title: 'Aktionen',
      render: (_: unknown, config: SeniorCareDeviceConfig) => (
        <FlexBox withgap>
          <Button
            icon={<EditOutlined />}
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              setEditItem(config);
            }}
          />
          <DuplicateManagementDeviceConfigurationButton
            managementDeviceConfiguration={config}
          />
          <Popconfirm
            title="Sind Sie sicher, dass Sie diese Konfiguration löschen möchten?"
            onConfirm={(e) => {
              e?.stopPropagation();
              deleteConfig(config.id);
            }}
          >
            <Button
              icon={<DeleteOutlined />}
              type="text"
              danger
              onClick={(e) => e.stopPropagation()}
            />
          </Popconfirm>
        </FlexBox>
      ),
    },
  ];

  const orgWideConfigColumns: ColumnsType<ManagementDeviceConfiguration> = [
    {
      title: 'Name',
      ...getOrgWideColumnSearchProps('name', 'name', (record) => record.name),
    },
    {
      title: 'Geräte',
      render: (_: unknown, config: ManagementDeviceConfiguration) =>
        config.devicesCount,
    },
    {
      title: 'Eigenschaften',
      render: (_: unknown, config: ManagementDeviceConfiguration) => (
        <DevicePropertiesList deviceProperties={config.properties} />
      ),
    },
    {
      title: 'Aktionen',
      render: (_: unknown, config: ManagementDeviceConfiguration) => (
        <FlexBox withgap>
          <Button
            icon={<EditOutlined />}
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              setEditItem(config);
            }}
          />
          <DuplicateManagementDeviceConfigurationButton
            managementDeviceConfiguration={config}
          />
          <Popconfirm
            title="Sind Sie sicher, dass Sie diese Konfiguration löschen möchten?"
            onConfirm={(e) => {
              e?.stopPropagation();
              deleteConfig(config.id);
            }}
          >
            <Button
              icon={<DeleteOutlined />}
              type="text"
              danger
              onClick={(e) => e.stopPropagation()}
            />
          </Popconfirm>
        </FlexBox>
      ),
    },
  ];

  const getDefaultProperties = () => {
    return (
      features.find(
        (feature) => feature.id === integrationAreasDevicePropertyKey,
      )?.initialValues ?? {}
    );
  };

  if (isAllDeviceConfigsError) {
    return (
      <PageContentContainer>
        <Alert
          message="Fehler beim Laden der Konfigurationen"
          description="Die Gerätekonfigurationen konnten nicht geladen werden. Bitte versuchen Sie es später erneut."
          type="error"
          showIcon
        />
      </PageContentContainer>
    );
  }

  return (
    <>
      <Breadcrumb
        items={[
          {
            name: 'Pflege App Gerätekonfigurationen',
            href: `/management/${organization}/senior-care-device-configurations`,
          },
        ]}
      />
      <PageTitleBar
        title="Pflege App Gerätekonfigurationen"
        Icon={ResourceIcons.DeviceConfiguration}
      />
      {editItem !== null && (
        <Modal
          open
          title={`${editItem.name} bearbeiten`}
          onCancel={() => setEditItem(null)}
          styles={{ body: { padding: '20px 0' } }}
          width={1100}
          footer={null}
        >
          <ConfigForm
            error={updateError}
            isSubmitting={isSubmittingEdit}
            onSubmit={(values) => {
              updateConfig({
                name: values.name,
                priority: editItem.priority,
                deviceSelector: editItem.deviceSelector,
                properties: values.properties,
              });
            }}
            initialValues={{
              name: editItem.name,
              properties: editItem.properties,
            }}
          />
        </Modal>
      )}
      <Modal
        open={isCreateViewOpen}
        title="Gerätekonfiguration erstellen"
        onCancel={() => setIsCreateViewOpen(false)}
        styles={{ body: { padding: '20px 0' } }}
        width={800}
        footer={null}
      >
        <ConfigForm
          error={createError}
          isSubmitting={isSubmittingCreation}
          onSubmit={(values: DeviceConfigFormData) => {
            createConfig({
              name: values.name,
              priority: 100,
              deviceSelector: {
                matchLabels: [
                  {
                    key: deviceConfigurationKey,
                    operator: Operator.IN,
                    values: [uuidv4()],
                  },
                ],
                matchRole: {
                  in: [seniorCareAppDeviceRole],
                },
              },
              properties: values.properties,
            });
          }}
          initialValues={{
            name: '',
            properties: getDefaultProperties(),
          }}
        />
      </Modal>
      <Modal
        open={isCreateOrgWideViewOpen}
        title="Organisationsweite Konfiguration erstellen"
        onCancel={() => setIsCreateOrgWideViewOpen(false)}
        styles={{ body: { padding: '20px 0' } }}
        width={800}
        footer={null}
      >
        <ConfigForm
          error={createError}
          isSubmitting={isSubmittingCreation}
          onSubmit={(values) => {
            createConfig({
              name: values.name,
              priority: 50,
              deviceSelector: {
                matchLabels: [],
                matchRole: {
                  in: [seniorCareAppDeviceRole],
                },
              },
              properties: {},
            });
          }}
          initialValues={{
            name: '',
            properties: {},
          }}
        />
      </Modal>
      <PageContentContainer>
        {isUserAdvanced && (
          <div className="mb-14">
            <div className="flex justify-between">
              <SectionHeader
                title="Organisationsweite Konfigurationen"
                description="Diese Konfigurationen werden auf alle Pflege App Geräte in der Organisation angewendet."
                tooltipTitle="Konfigurationen in diesem Bereich werden auf alle Pflege App Geräte in der Organisation angewendet und haben Vorrang vor gerätespezifischen Konfigurationen."
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setIsCreateOrgWideViewOpen(true)}
              >
                Organisationsweite Konfiguration anlegen
              </Button>
            </div>
            <Table<ManagementDeviceConfiguration>
              dataSource={organizationWideConfigs}
              columns={orgWideConfigColumns}
              loading={isDeleting}
              rowKey="id"
            />
          </div>
        )}
        <div className="flex justify-between">
          <SectionHeader
            title="Gerätespezifische Konfigurationen"
            description="Diese Konfigurationen werden nur auf spezifische Pflege App Geräte angewendet."
            tooltipTitle="Diese Konfigurationen werden nur auf spezifische Geräte angewendet und haben eine niedrigere Priorität als organisationsweite Konfigurationen."
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsCreateViewOpen(true)}
          >
            Gerätekonfiguration anlegen
          </Button>
        </div>
        <Table<SeniorCareDeviceConfig>
          dataSource={deviceConfigs}
          columns={configColumns}
          loading={isDeleting}
          rowKey="id"
        />
      </PageContentContainer>
    </>
  );
};

export default SeniorCareDeviceConfigsView;
