import {
  CheckCircleOutlined,
  CheckSquareOutlined,
  DeleteOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, Popconfirm } from 'antd';
import ActivationRequestEmail from 'components/common/ActivationRequestEmail';
import IdTag from 'components/common/IdTag';
import InfoTagVertical from 'components/common/InfoTagVertical';
import ListItemInfoTag from 'components/common/ListItemInfoTag';
import Metadata from 'components/common/Metadata';
import RelativeDateTimeWithTooltip from 'components/common/RelativeDateTimeWithTooltip';
import StandardHighlighter from 'components/common/StandardHighlighter';
import LabelList from 'components/views/LabelList';
import type { DeviceActivationRequest } from 'interfaces/deviceActivationRequest';
import type { ManagementDeviceActivationRequest } from 'interfaces/managementActivationRequest';
import { deviceRoleLabel } from 'labels';

interface Props {
  activationRequest:
    | DeviceActivationRequest
    | ManagementDeviceActivationRequest;
  isIdCollapsed?: boolean;
  searchText?: string;
  toggleSelect?: (id: string) => void;
  isChecked?: boolean;
  setIsActivationModalOpen: (value: boolean) => void;
  remove: () => Promise<void>;
  organizationInfo?: React.ReactNode;
}
const DevicesActivationRequestInfo: React.FC<Props> = ({
  activationRequest,
  isIdCollapsed = true,
  searchText,
  toggleSelect,
  isChecked,
  setIsActivationModalOpen,
  remove,
  organizationInfo,
}) => {
  return (
    <div>
      {toggleSelect !== undefined && (
        <div
          className="flex justify-center items-center"
          style={{ gridArea: 'checkbox' }}
        >
          <Checkbox
            onClick={() => toggleSelect(activationRequest.id)}
            checked={isChecked}
          />
        </div>
      )}
      <div className="flex flex-col justify-between gap-3">
        <div className="flex gap-3 items-end">
          {activationRequest.approvedAt && (
            <Popconfirm
              title="Anfrage löschen"
              description="Wenn sie diese Anfrage ablehnen, wird sie direkt gelöscht und kann nicht wiederhergestellt werden. Fortfahren?"
              okText="Ja"
              cancelText="Nein"
              onConfirm={async () => await remove()}
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
          <InfoTagVertical label="ID">
            <IdTag
              id={activationRequest.id}
              isCollapsed={isIdCollapsed}
              highlight={searchText}
            />
          </InfoTagVertical>
          {activationRequest.approvedAt ? (
            <>
              {activationRequest.deviceId && (
                <InfoTagVertical label="Geräte-ID">
                  <IdTag id={activationRequest.deviceId} />
                </InfoTagVertical>
              )}
              <CheckSquareOutlined
                style={{
                  fontSize: '20px',
                  color: 'hsl(120, 50%, 50%)',
                }}
              />
              Angenommen
            </>
          ) : (
            <>
              <Button
                type="primary"
                icon={<CheckCircleOutlined />}
                onClick={() => setIsActivationModalOpen(true)}
              >
                Annehmen
              </Button>
              <Popconfirm
                title="Anfrage löschen"
                description="Wenn sie diese Anfrage ablehnen, wird sie direkt gelöscht und kann nicht wiederhergestellt werden. Fortfahren?"
                okText="Ja"
                cancelText="Nein"
                onConfirm={async () => await remove()}
              >
                <Button type="primary" danger icon={<StopOutlined />}>
                  Löschen
                </Button>
              </Popconfirm>
            </>
          )}
          <ListItemInfoTag
            label="E-Mail Adresse bestätigt"
            valueStyle={{
              backgroundColor: activationRequest.userEmailVerifiedAt
                ? 'hsla(90, 100%, 72%, 1)'
                : 'hsla(0, 100%, 72%, 1)',
            }}
          >
            <RelativeDateTimeWithTooltip
              timestamp={activationRequest.userEmailVerifiedAt}
              noneText="Noch nicht bestätigt"
            />
          </ListItemInfoTag>
        </div>
        <div className="flex gap-x-3 items-center flex-wrap">
          <ListItemInfoTag label="Username">
            {activationRequest.userName}
          </ListItemInfoTag>
          <ListItemInfoTag label="E-Mail Adresse">
            <ActivationRequestEmail activationRequest={activationRequest} />
          </ListItemInfoTag>
          {organizationInfo}
          <ListItemInfoTag label="Rolle">
            <StandardHighlighter
              searchWords={[searchText ?? '']}
              text={activationRequest.metadata[deviceRoleLabel] ?? ''}
            />
          </ListItemInfoTag>
          <ListItemInfoTag label="Erstellt">
            <RelativeDateTimeWithTooltip
              timestamp={activationRequest.createdAt}
            />
          </ListItemInfoTag>
        </div>
        <div className="flex gap-3 items-center">
          <Metadata metadata={activationRequest.metadata} />
        </div>
        <LabelList
          labels={activationRequest.labels}
          highlightTexts={searchText ? [searchText] : []}
        />
      </div>
    </div>
  );
};

export default DevicesActivationRequestInfo;
