import { ReloadOutlined } from '@ant-design/icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Table, Tag, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useAdminUserApi } from 'api/devices/useAdminUserApi';
import ErrorAlert from 'components/common/ErrorAlert';
import IdTag from 'components/common/IdTag';
import { FlexBox, FlexOne } from 'components/Helpers';
import deviceRoles from 'defaults/deviceRoles';
import userGroups from 'defaults/userGroups';
import userRoles from 'defaults/userRoles';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import type { User } from 'interfaces/user';
import { DateTime } from 'luxon';
import { useState } from 'react';
import Highlighter from 'react-highlight-words';
import getErrorMessage from 'utlis/getErrorMessage';
import AdminUserModal from './AdminUserModal';
import UserControls from './UserControls';

interface Props {
  organizationId: string;
}

const OrganizationDetailUsers: React.FC<Props> = ({ organizationId }) => {
  const queryClient = useQueryClient();
  const { getUsers } = useAdminUserApi();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const queryKey = ['users', organizationId];

  const {
    data: organizationUsers,
    refetch,
    error,
    isRefetching,
  } = useQuery({
    queryKey,
    queryFn: () => getUsers(organizationId),
  });

  const getColumnSearchProps = useTableSearchFilter<User>();

  const columns: ColumnsType<User> = [
    {
      title: 'Actions',
      key: 'actions',
      render: (user) => (
        <FlexBox
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
            e.stopPropagation()
          }
          withgap
        >
          <UserControls
            user={user}
            onUpdated={() => {
              queryClient.invalidateQueries({
                queryKey,
              });
            }}
            onDeleted={() => {
              queryClient.invalidateQueries({
                queryKey,
              });
            }}
          />
        </FlexBox>
      ),
    },
    {
      title: 'ID',
      sorter: (a, b) => a.id.localeCompare(b.id),
      onFilter: (value, record) => record.id === value,
      ...getColumnSearchProps(
        'id',
        'id',
        (user) => user.id,
        (user, _, searchText) => (
          <IdTag id={user.id} isCollapsed={false} highlight={searchText} />
        ),
      ),
    },
    {
      title: 'Name',
      ...getColumnSearchProps(
        'name',
        'name',
        (user) => user.name,
        (user, _, searchText) => (
          <Highlighter
            searchWords={[searchText]}
            autoEscape={true}
            textToHighlight={user.name}
          />
        ),
      ),
    },
    {
      title: 'E-Mail Adresse',
      ...getColumnSearchProps(
        'email',
        'email',
        (user) => user.email,
        (user, _, searchText) => (
          <Highlighter
            searchWords={[searchText]}
            autoEscape={true}
            textToHighlight={user.email}
          />
        ),
      ),
    },
    {
      title: 'Rollen',
      filters: userRoles.map((role) => ({ text: role, value: role })),
      filterSearch: true,
      onFilter: (value, record) => record.roles.includes(value as string),
      render: (_, user) => (
        <>
          {user.roles.map((role) => (
            <Tag key={role}>{role}</Tag>
          ))}
        </>
      ),
    },
    {
      title: 'Erlaubte Geräterollen',
      filters: deviceRoles.map((deviceRole) => ({
        text: deviceRole,
        value: deviceRole,
      })),
      filterSearch: true,
      onFilter: (value, record) =>
        record.allowedDeviceRoles.includes(value as string),
      render: (_, user) => (
        <>
          {user.allowedDeviceRoles.map((role) => (
            <Tag key={role}>{role}</Tag>
          ))}
        </>
      ),
    },
    {
      title: 'Gruppen',
      filters: userGroups.map((group) => ({ text: group, value: group })),
      filterSearch: true,
      onFilter: (value, record) => record.groups.includes(value as string),
      render: (_, user) => (
        <>{user.groups?.map((role) => <Tag key={role}>{role}</Tag>)}</>
      ),
    },
    {
      title: 'Zuletzt gesehen',
      render: (_, user) =>
        user.lastLogin
          ? DateTime.fromISO(user.lastLogin).toRelative()
          : 'Noch nie',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.lastLogin?.localeCompare(b.lastLogin ?? '') ?? 0,
      responsive: ['xxl'],
    },
    {
      title: 'Erstellt am',
      key: 'createdAt',
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt) ?? 0,
      render: (_, user) =>
        DateTime.fromISO(user.createdAt).toLocaleString(
          DateTime.DATETIME_SHORT,
        ),
      responsive: ['xxl'],
    },
  ];

  if (error) {
    return (
      <ErrorAlert
        subtitle={getErrorMessage(error)}
        redirect={() => refetch()}
        redirectText="Erneut versuchen"
      />
    );
  }
  return (
    <div>
      <AdminUserModal
        open={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        user={undefined}
        organizationId={organizationId}
        onCreated={() => {
          queryClient.invalidateQueries({
            queryKey,
          });
        }}
        onUpdated={undefined}
      />
      <FlexBox withgap alignitems="center">
        <FlexOne>
          <Typography.Title level={5}>Users</Typography.Title>
        </FlexOne>
        <Button onClick={() => setIsCreateModalOpen(true)}>
          Nutzer erstellen
        </Button>

        <Button
          icon={<ReloadOutlined />}
          onClick={() => refetch()}
          loading={isRefetching}
        />
      </FlexBox>

      <Table columns={columns} dataSource={organizationUsers} />
    </div>
  );
};

export default OrganizationDetailUsers;
