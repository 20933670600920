import { InfoCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import {
  Descriptions,
  type DescriptionsProps,
  message,
  Skeleton,
  Tooltip,
  Typography,
} from 'antd';
import Popconfirm from 'antd/lib/popconfirm';
import {
  writeDevicesScope,
  writeOrganizationDevicesScope,
} from 'api/devices/deviceManagementApiScopes';
import { useAdminDeviceApiV4 } from 'api/devices/useAdminDeviceApiV4';
import { FlexBox, FlexOne } from 'components/Helpers';
import DeviceNameEditable from 'components/admin/list/DeviceNameEditable';
import Breadcrumb from 'components/common/Breadcrumb';
import ErrorAlert from 'components/common/ErrorAlert';
import IdTag from 'components/common/IdTag';
import ListItemInfoTag from 'components/common/ListItemInfoTag';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import ResourceIcons from 'components/common/resourceIcons';
import DeviceConfigurationCollapsible from 'components/device-configuration/DeviceConfigurationCollapsible';
import DevicePropertiesList from 'components/views/DevicePropertiesList';
import LabelList from 'components/views/LabelList';
import { seniorCareAppDeviceRole } from 'defaults/deviceRoles';
import useAuthContext from 'hooks/auth/useAuthContext';
import useIsAdmin from 'hooks/auth/useIsAdmin';
import useManagementDevice from 'hooks/devices/useManagementDevice';
import useDeleteSeniorCareDeviceData from 'hooks/senior-care/useDeleteSeniorCareDeviceData';
import useGlobalDeviceConfigurations from 'hooks/useGlobalDeviceConfigurations';
import useManagementDeviceConfigurations from 'hooks/useManagementDeviceConfigurations';
import useOrganizationDeviceConfigurations from 'hooks/useOrganizationDeviceConfigurations';
import { deviceCreatedAtLabel } from 'labels';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { TiDocumentDelete } from 'react-icons/ti';
import { deviceRoute, useManagementPathOrganization } from 'router';
import AdminDeviceActions from './AdminDeviceActions';
import DeviceControls from './DeviceControls';
import ManagementActivationRequestInfoWrapper from './ManagementActivationRequestInfoWrapper';
import ManagementDeviceActivationCodeModal from './ManagementDeviceActivationCodeModal';
import ManagementDeviceEditModal from './ManagementDeviceEditModal';
import SeniorCareDeviceDetails from './SeniorCareDeviceDetails';

export interface DeviceViewSearchParams {
  isNew?: boolean;
}

const DeviceView: React.FC = () => {
  const navigate = useNavigate();
  const { getDevice } = useAdminDeviceApiV4();
  const { deviceId } = deviceRoute.useParams();
  const { isNew } = deviceRoute.useSearch<DeviceViewSearchParams>();
  const pathOrganization = useManagementPathOrganization();

  const isAdmin = useIsAdmin();
  const { device, isLoading, refetch } = useManagementDevice(deviceId);

  const { data: adminDevice, isLoading: adminDeviceIsLoading } = useQuery({
    queryKey: ['admin-devices', deviceId],
    queryFn: () => getDevice(deviceId),
    enabled: isAdmin,
  });

  const { data: globalConfigurations } = useGlobalDeviceConfigurations();
  const { data: organizationConfigurations } =
    useOrganizationDeviceConfigurations();
  const { data: managementOrganizationConfigurations } =
    useManagementDeviceConfigurations();

  const [showQrCode, setShowQrCode] = useState(!!isNew);
  const [isEditing, setIsEditing] = useState(false);

  const { scope } = useAuthContext();
  const deleteSeniorCareDeviceData = useDeleteSeniorCareDeviceData();

  const concatedDeviceConfigurations = useMemo(() => {
    if (!device) return [];
    const organizationConfigs = isAdmin
      ? device.organizationConfigurations
          .map((configId) =>
            organizationConfigurations?.find(
              (config) => config.id === configId,
            ),
          )
          .filter((config) => config !== undefined)
      : device.organizationConfigurations
          .map((configId) =>
            managementOrganizationConfigurations?.find(
              (config) => config.id === configId,
            ),
          )
          .filter((config) => config !== undefined);
    if (!globalConfigurations || !isAdmin || !adminDevice)
      return organizationConfigs;
    const globalConfigs = adminDevice.globalConfigurations
      .map((configId) =>
        globalConfigurations?.find((config) => config.id === configId),
      )
      .filter((config) => config !== undefined);
    return [...organizationConfigs, ...globalConfigs];
  }, [
    device,
    globalConfigurations,
    isAdmin,
    organizationConfigurations,
    managementOrganizationConfigurations,
    adminDevice,
  ]);

  const items: DescriptionsProps['items'] = device
    ? [
        {
          key: 'name',
          label: 'Device Name',
          children: <DeviceNameEditable device={device} />,
          span: 2,
        },
        {
          key: 'deviceId',
          label: 'Device ID',
          children: <IdTag id={deviceId} isCollapsed={false} />,
          span: 2,
        },
        {
          key: 'cretedAt',
          label: 'Erstellt am',
          children: device.labels[deviceCreatedAtLabel]
            ? DateTime.fromISO(
                device.labels[deviceCreatedAtLabel],
              ).toLocaleString(DateTime.DATETIME_SHORT)
            : 'Nicht angegeben',
          span: 2,
        },
        {
          key: 'role',
          label: 'Rolle',
          children: (
            <ListItemInfoTag label="Rolle">{device.role}</ListItemInfoTag>
          ),
          span: 2,
        },
        {
          key: 'lastSeen',
          label: 'Zuletzt aktiv',
          children: device.lastSeenAt
            ? DateTime.fromISO(device.lastSeenAt).toFormat('dd.MM.yyyy HH:mm')
            : 'Noch nie',
          span: 2,
        },
        {
          key: 'activationRequest',
          label: 'Aktivierungsanfrage',
          children: (
            <ManagementActivationRequestInfoWrapper deviceId={device.id} />
          ),
          span: 4,
        },
        {
          key: 'metadata',
          label: 'Metadaten',
          children:
            Object.keys(device.metadata).length > 0 ? (
              <LabelList labels={device.metadata} />
            ) : (
              'Keine'
            ),
          span: 4,
        },
        {
          key: 'labels',
          label: 'Labels',
          children: <LabelList labels={device.labels} />,
          span: 4,
        },
        {
          key: 'properties',
          label: 'Geräteeigenschaften',
          children:
            Object.keys(device.properties).length > 0 ? (
              <DevicePropertiesList deviceProperties={device.properties} />
            ) : (
              'Keine'
            ),
          span: 4,
        },
        {
          key: 'organizationConfig',
          label: (
            <div className="flex justify-between items-center">
              <div className="flex items-start gap-x-1">
                <Typography.Text className="text-black/70">
                  Gerätekonfigurationen
                </Typography.Text>
                <Tooltip title="Es kann sein, dass kürzlich hinzugefügte Gerätekonfigurationen nicht sofort angezeigt werden. Sie sollten jedoch innerhalb von maximal 10 Minuten hier erscheinen.">
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
              {isAdmin && adminDevice && (
                <AdminDeviceActions device={adminDevice} />
              )}
            </div>
          ),
          children: (
            <div className="flex flex-col gap-y-4">
              {concatedDeviceConfigurations.length > 0
                ? concatedDeviceConfigurations.map((config) => (
                    <DeviceConfigurationCollapsible
                      key={config.id}
                      config={config}
                      isLoading={isLoading || adminDeviceIsLoading}
                      isGlobal={isAdmin ? !('organization' in config) : false}
                    />
                  ))
                : 'Keine'}
            </div>
          ),
          span: 4,
        },
      ]
    : [];

  return (
    <>
      {showQrCode && (
        <ManagementDeviceActivationCodeModal
          deviceId={deviceId}
          onClose={() => setShowQrCode(false)}
        />
      )}
      {isEditing && device && (
        <ManagementDeviceEditModal
          device={device}
          organizationConfigurations={
            isAdmin
              ? organizationConfigurations
              : managementOrganizationConfigurations
          }
          globalConfigurations={globalConfigurations}
          onEdited={() => setIsEditing(false)}
          onClose={() => setIsEditing(false)}
        />
      )}
      <Breadcrumb
        items={[
          {
            name: 'Geräte',
            href: `/management/${pathOrganization}/devices`,
          },
          ...(device
            ? [
                {
                  name: device.id,
                  href: `/management/${pathOrganization}/devices/${device.id}`,
                },
              ]
            : []),
        ]}
      />
      <PageTitleBar
        title="Gerät"
        Icon={ResourceIcons.Device}
        renderToolbar={() =>
          device ? (
            <FlexBox withgap>
              <DeviceControls
                device={device}
                onEdit={() => setIsEditing(true)}
                onShowQRCode={() => setShowQrCode(true)}
                onDeleted={() => {
                  refetch();
                  navigate({
                    to: '/management/$organization/devices',
                    params: { organization: device.organization },
                  });
                }}
                additionalControls={
                  device.role === seniorCareAppDeviceRole &&
                  (scope.includes(writeOrganizationDevicesScope) ||
                    scope.includes(writeDevicesScope))
                    ? [
                        {
                          key: 'delete-data',
                          label: (
                            <Popconfirm
                              title="Sind Sie sicher, dass Sie die Daten dieses Geräts löschen wollen?"
                              onConfirm={async () => {
                                await deleteSeniorCareDeviceData(device.id);
                                message.success(
                                  'Löschen der Daten wurde angestoßen. Es kann bis zu einer Stunde dauern, bis die Daten gelöscht sind, vorausgesetzt das Gerät ist aktiv.',
                                );
                              }}
                              okText="Ja"
                              cancelText="Nein"
                            >
                              <FlexBox
                                withgap
                                alignitems="center"
                                style={{ color: 'red' }}
                              >
                                <TiDocumentDelete />
                                <span>Daten löschen</span>
                              </FlexBox>
                            </Popconfirm>
                          ),
                        },
                      ]
                    : []
                }
              />
            </FlexBox>
          ) : null
        }
      />
      <PageContentContainer>
        {isLoading ? (
          <Skeleton active />
        ) : !device ? (
          <ErrorAlert
            redirect={() =>
              navigate({
                to: '/management/$organization/devices',
                params: { organization: pathOrganization },
              })
            }
            errorCode="404"
            subtitle="Gerät nicht gefunden"
          />
        ) : (
          <FlexBox direction="column" withgap style={{ height: '100%' }}>
            <FlexOne>
              <Descriptions bordered items={items} layout="vertical" />
            </FlexOne>

            {device.role === seniorCareAppDeviceRole && (
              <SeniorCareDeviceDetails device={device} />
            )}
          </FlexBox>
        )}
      </PageContentContainer>
    </>
  );
};

export default DeviceView;
