import { Typography } from 'antd';
import { FlexBox } from 'components/Helpers';
import type { IconBaseProps } from 'react-icons';
import styled from 'styled-components';

interface Props {
  Icon: React.FC<IconBaseProps>;
  title?: string;
  renderTitle?: () => React.ReactNode;
  renderToolbar?: () => React.ReactNode;
  style?: React.CSSProperties;
}

const PageTitleBar: React.FC<Props> = ({
  Icon,
  title,
  renderTitle,
  renderToolbar = () => null,
  style,
}) => {
  return (
    <FlexBox
      style={{ padding: '0px 30px 10px', ...style }}
      justifycontent="space-between"
    >
      <FlexBox direction="row">
        <Icon size={30} style={{ marginRight: 10 }} />
        {renderTitle ? (
          renderTitle()
        ) : (
          <Typography.Title level={3} style={{ margin: 0, padding: 0 }}>
            {title}
          </Typography.Title>
        )}
      </FlexBox>
      {renderToolbar()}
    </FlexBox>
  );
};

export const StyledTitleId = styled.span`
  font-family: monospace;
  margin-left: 12px;
  padding: 6px 12px;
  font-size: 20px;
  border-radius: 5px;
  color: hsl(200, 10%, 40%);
  background-color: hsl(200, 10%, 92%);
`;

export default PageTitleBar;
