import { useQuery } from '@tanstack/react-query';
import { useRawManagementApi } from 'api/devices/useRawManagementApi';
import { useAdminOrganizationApi } from 'api/useAdminOrganizationApi';
import type { ManagementOrganization } from 'interfaces/organization';
import { useCallback } from 'react';
import { useNullablePathOrganization } from 'router';
import useIsAdmin from './useIsAdmin';

/**
 * Get the selected organization (given by the route path)
 */
export function useOrganization(): ManagementOrganization | null {
  const isAdmin = useIsAdmin();

  const organization = useNullablePathOrganization();
  const { getOrganization: getSelfOrganization } = useRawManagementApi();
  const { getOrganization: getOrganizationById } = useAdminOrganizationApi();

  const getOrganization = useCallback(async () => {
    if (organization === null) {
      return null;
    }
    if (isAdmin) {
      const organizationData = await getOrganizationById(organization);

      return {
        name: organizationData.name,
        metadata: organizationData.metadata,
      };
    } else {
      return await getSelfOrganization();
    }
  }, [getSelfOrganization, getOrganizationById, isAdmin, organization]);

  const { data } = useQuery({
    queryKey: ['useOrganization', organization, isAdmin],
    queryFn: getOrganization,
    staleTime: 0,
    throwOnError: true,
  });
  return data ?? null;
}
