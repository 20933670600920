import { AutoComplete, Form, Modal, Select } from 'antd';
import { useAdminDeviceApiV4 } from 'api/devices/useAdminDeviceApiV4';
import wellKnownAdminDeviceLabels from 'defaults/deviceLabels';
import device from 'defaults/deviceRoles';
import useOrganizations from 'hooks/organizations/useOrganizations';
import type {
  AdminDevicePostV4Dto,
  AdminDeviceV4Dto,
} from 'interfaces/adminDeviceV4';
import _ from 'lodash';
import React from 'react';
import LabelsFormList, { convertFromFormList } from '../common/LabelsFormList';

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  onCreated?: (device: AdminDeviceV4Dto) => void;
}

const { Item } = Form;

const AdminDeviceCreateModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onCreated,
}) => {
  const { organizations } = useOrganizations();
  const { postDevice } = useAdminDeviceApiV4();

  const [form] = Form.useForm();

  const onSubmit = async (data: any) => {
    const devicePost: AdminDevicePostV4Dto = {
      organization: data.organization,
      role: data.role,
      labels: convertFromFormList(data.labels),
    };
    const device = await postDevice(devicePost);
    onCreated?.(device);
  };

  return (
    <Modal
      title="Create Device"
      open={isOpen}
      onCancel={onClose}
      onOk={form.submit}
      okText="Create Device"
    >
      <Form form={form} onFinish={onSubmit}>
        <Item name="organization" label="Organization">
          <AutoComplete
            popupMatchSelectWidth={false}
            options={(organizations ?? []).map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            filterOption={(input: string, option) =>
              option?.label.toLowerCase().includes(input.toLowerCase()) ?? false
            }
          />
        </Item>
        <Item name="role" label="Role">
          <Select
            defaultActiveFirstOption={false}
            showSearch={true}
            filterOption={true}
            optionFilterProp="label"
            options={_.sortBy(device, 'name').map((roleOption) => ({
              label: roleOption,
              value: roleOption,
            }))}
          />
        </Item>
        <LabelsFormList
          name="labels"
          label="Label"
          labelOptions={wellKnownAdminDeviceLabels}
        />
      </Form>
    </Modal>
  );
};

export default AdminDeviceCreateModal;
