import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Collapse, Form, message, Modal, Tag } from 'antd';
import KeyValueForm from 'components/common/KeyValueForm';
import DeviceConfigurationCollapsible from 'components/device-configuration/DeviceConfigurationCollapsible';
import { useAvailableDeviceSelectors } from 'components/device-configuration/DeviceConfigurationHelper';
import { useManagementApiFromContext } from 'components/scaffold/OrganizationView';
import useIsAdmin from 'hooks/auth/useIsAdmin';
import type { GlobalDeviceConfiguration } from 'interfaces/adminGlobalDeviceConfig';
import type { OrganizationDeviceConfiguration } from 'interfaces/adminOrganizationDeviceConfig';
import type { ManagementDevice } from 'interfaces/device';
import type { ManagementDeviceConfiguration } from 'interfaces/managementDeviceConfiguration';
import React, { useMemo } from 'react';
import { toBaseError } from 'utlis/BaseError';
import { getMatchingConfigurations } from 'utlis/deviceConfigurationMatcher';
import { getLabelsDiff } from 'utlis/getLabelsDiff';

interface Props {
  device: ManagementDevice;
  organizationConfigurations:
    | OrganizationDeviceConfiguration[]
    | ManagementDeviceConfiguration[]
    | undefined;
  globalConfigurations: GlobalDeviceConfiguration[] | undefined;
  onClose?: () => void;
  onEdited?: () => void;
}

interface FormData {
  labels: Record<string, string>;
}

const ManagementDeviceEditModal: React.FC<Props> = ({
  device,
  globalConfigurations,
  organizationConfigurations,
  onClose,
  onEdited,
}) => {
  const queryClient = useQueryClient();
  const { patchDeviceLabels, updateDeviceProperties } =
    useManagementApiFromContext();
  const availableDeviceSelectorLabels = useAvailableDeviceSelectors();
  const [form] = Form.useForm<FormData>();
  const labels = Form.useWatch('labels', form);
  const isAdmin = useIsAdmin();
  const matchingConfigurations = useMemo(() => {
    return getMatchingConfigurations(
      {
        ...device,
        labels: labels ?? device.labels,
      },
      globalConfigurations,
      organizationConfigurations,
    );
  }, [device, labels, globalConfigurations, organizationConfigurations]);
  const { mutate: onSubmit, isPending } = useMutation({
    mutationKey: ['management-device', device.id],
    mutationFn: async (data: FormData) => {
      await patchDeviceLabels(
        device.id,
        getLabelsDiff(device.labels, data.labels),
      );
      await updateDeviceProperties(device.id);

      return {
        ...device,
        labels: data.labels,
      };
    },
    onSuccess: () => {
      onEdited?.();
      queryClient.invalidateQueries({ queryKey: ['management-devices'] });
      message.success('Gerät wurde bearbeiten.');
    },
    onError: (error) => {
      const baseError = toBaseError(error);
      message.error(
        `Gerät konnte nicht bearbeitet werden: ${baseError.message}`,
      );
    },
  });

  return (
    <Modal
      title="Gerät bearbeiten"
      open={true}
      onCancel={onClose}
      onOk={form.submit}
      okText="Speichern"
      width={900}
      confirmLoading={isPending}
    >
      <Form<FormData>
        form={form}
        onFinish={onSubmit}
        initialValues={{
          labels: device.labels,
        }}
      >
        <Form.Item name="labels" noStyle>
          <KeyValueForm labelOptions={availableDeviceSelectorLabels} />
        </Form.Item>
      </Form>
      <Collapse className="mt-5 mb-4 ">
        <Collapse.Panel
          key="device-config"
          header={
            <div className="flex justify-between items-center w-full">
              <div className="flex items-start gap-x-1">
                <p className="font-semibold text-base">
                  Auswirkungen der Labels
                </p>
              </div>
              <Tag>
                {matchingConfigurations.organizationConfigurations.length +
                  matchingConfigurations.globalConfigurations.length}{' '}
                Gerätekonfigurationen
              </Tag>
            </div>
          }
        >
          <div className="flex flex-col gap-y-4">
            {matchingConfigurations.organizationConfigurations.map((config) => (
              <DeviceConfigurationCollapsible
                key={config.id}
                config={config}
                isGlobal={false}
              />
            ))}
            {matchingConfigurations.globalConfigurations.map((config) => (
              <DeviceConfigurationCollapsible
                key={config.id}
                config={config}
                isGlobal={true}
              />
            ))}
          </div>
        </Collapse.Panel>
      </Collapse>
    </Modal>
  );
};

export default ManagementDeviceEditModal;
