import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal } from 'antd';
import { useAdminDeviceApi } from 'api/devices/useAdminDeviceApi';
import { useAdminDeviceApiV4 } from 'api/devices/useAdminDeviceApiV4';
import type { ManagementDevice } from 'interfaces/device';
import {
  labelEmailAddress,
  labelFirstname,
  labelLastname,
  labelSalutation,
} from 'labels';
import _ from 'lodash';
import React, { useState } from 'react';
import { getLabelsDiff } from 'utlis/getLabelsDiff';
import RegistrationMailForm from './RegistrationmailForm';

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  device: ManagementDevice;
}

const SendDeviceMailModal: React.FC<Props> = ({ isOpen, onClose, device }) => {
  const { sendRegistrationMail } = useAdminDeviceApi();
  const { patchDeviceLabels } = useAdminDeviceApiV4();

  const [errors, setErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();
  const [emailForm] = Form.useForm();

  const matchEmail = (email: string) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      if (
        form.isFieldsTouched([
          'emailAddress',
          'firstname',
          'lastname',
          'salutation',
        ]) ||
        !_.has(device.labels, [
          labelEmailAddress,
          labelFirstname,
          labelLastname,
          labelSalutation,
        ])
      ) {
        const { emailAddress, firstname, lastname, salutation } =
          form.getFieldsValue();

        if (!matchEmail(emailAddress ?? ''))
          throw new Error('Email address is not valid');

        const patchLabels = getLabelsDiff(device.labels, {
          ...device.labels,
          [labelEmailAddress]: emailAddress ?? '',
          [labelFirstname]: firstname ?? '',
          [labelLastname]: lastname ?? '',
          [labelSalutation]: salutation ?? '',
        });

        await patchDeviceLabels(device.id, patchLabels);
      }

      const emailData = emailForm.getFieldsValue();
      if (!emailData.body.includes('%qr-code%'))
        throw new Error('QR Code is not in email');

      await sendRegistrationMail(device.id, {
        subject: emailData.subject,
        body: emailData.body,
      });

      onClose && onClose();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrors(true);
    }
  };

  return (
    <Modal
      title="Create Devices from CSV"
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <>
          <Button
            type="primary"
            key="create-devices-and-send"
            onClick={() => onSubmit()}
            disabled={!!isLoading}
          >
            {isLoading ? <LoadingOutlined /> : 'Send Mail'}
          </Button>
          {errors && <p style={{ color: 'red' }}>Something went wrong</p>}
        </>,
      ]}
    >
      <Form form={form}>
        <Form.Item
          name="emailAddress"
          label="Email Address"
          initialValue={device.labels[labelEmailAddress]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="firstname"
          label="First Name"
          initialValue={device.labels[labelFirstname]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lastname"
          label="Last Name"
          initialValue={device.labels[labelLastname]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="salutation"
          label="Salutation"
          initialValue={device.labels[labelSalutation]}
        >
          <Input />
        </Form.Item>
      </Form>
      <RegistrationMailForm form={emailForm} />
    </Modal>
  );
};

export default SendDeviceMailModal;
