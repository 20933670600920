import { VOIZE_METADATA_INDUSTRY } from 'components/device-configuration/devicePropertyKeys';
import type { FeatureRenderProps } from '../../../../defaults/featuresToDeviceProperties';
import TICIntegrationConfigForm from './TICIntegrationConfigForm';
import TICIntegrationConfigSummary from './TICIntegrationConfigSummary';

const TICIntegrationConfigFeature: React.FC<FeatureRenderProps> = ({
  allDeviceProperties,
  values,
  onUpdate,
  isEditing,
  onEditDone,
}) => {
  // We can not officially list the VOIZE_METADATA_INDUSTRY property for the feature
  // because then the feature would be shown everywhere where the VOIZE_METADATA_INDUSTRY property is present.
  // Therefore we have to add the property here manually so it is available in the form and summary.
  const propertiesWithIndustry = {
    ...values,
    [VOIZE_METADATA_INDUSTRY]:
      allDeviceProperties[VOIZE_METADATA_INDUSTRY] ?? '',
  };

  if (isEditing) {
    return (
      <TICIntegrationConfigForm
        initialProperties={propertiesWithIndustry}
        onSubmit={(config) => {
          onUpdate(config);
          onEditDone?.();
        }}
      />
    );
  }

  return <TICIntegrationConfigSummary properties={propertiesWithIndustry} />;
};

export default TICIntegrationConfigFeature;
