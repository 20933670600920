import { VOIZE_METADATA_INDUSTRY } from 'components/device-configuration/devicePropertyKeys';
import Joi from 'joi';
import { UnreachableCaseError } from 'ts-essentials';
import { validateOrThrow } from 'utlis/validationUtil';

export enum TICIntegrationPropertyKeys {
  ADAPTER_TYPE = 'TIC_APP_INTEGRATION_ADAPTER_TYPE',
  TUEV_NORD_MOBILITY = 'TIC_APP_INTEGRATION_ADAPTER_TUEV_NORD_CONFIGURATION',
  TUEV_NORD_SYSTEMS = 'TIC_APP_INTEGRATION_ADAPTER_TUEV_NORD_SYSTEMS_CONFIGURATION',
  TUEV_SUED_MOBILITY = 'TIC_APP_INTEGRATION_ADAPTER_TUEV_SUED_MOBILITY_CONFIGURATION',
}

export enum TICIntegrationAdapterType {
  MOCK = 'MOCK',
  TUEV_NORD = 'TUEV_NORD',
  TUEV_SUED = 'TUEV_SUED',
}

export const ticIntegrationTypeName: Record<TICIntegrationAdapterType, string> =
  {
    [TICIntegrationAdapterType.MOCK]: 'Mock',
    [TICIntegrationAdapterType.TUEV_NORD]: 'TÜV Nord',
    [TICIntegrationAdapterType.TUEV_SUED]: 'TÜV Süd',
  };

export enum TICDivisionViaIndustry {
  Mobility = 'voize-tuev-kfz',
  IndustryServices = 'voize-tic-industry-services',
}

export const ticDivisionViaIndustrySchema = Joi.string()
  .valid(...Object.values(TICDivisionViaIndustry))
  .required();

export const ticDivisionName: Record<TICDivisionViaIndustry, string> = {
  [TICDivisionViaIndustry.Mobility]: 'Mobilität',
  [TICDivisionViaIndustry.IndustryServices]: 'Systems',
};

export interface TICIntegrationFormData {
  integrationType?: TICIntegrationAdapterType;
  division?: TICDivisionViaIndustry;
  baseUrl?: string;
}

export interface TICIntegrationTuevNordMobilityConfig {
  baseUrl: string;
}

export const ticIntegrationAdapterTypeSchema = Joi.string()
  .valid(...Object.values(TICIntegrationAdapterType))
  .required();

export const ticIntegrationTuevNordMobilityConfigurationSchema =
  Joi.object<TICIntegrationTuevNordMobilityConfig>({
    baseUrl: Joi.string().uri().optional().allow(''),
  });

export interface TICIntegrationTuevNordSystemsConfig {
  baseUrl: string;
}

export const ticIntegrationTuevNordSystemsConfigurationSchema =
  Joi.object<TICIntegrationTuevNordSystemsConfig>({
    baseUrl: Joi.string().uri().optional().allow(''),
  });

export interface TICIntegrationTuevSuedMobilityConfig {
  baseUrl: string;
}

export const ticIntegrationTuevSuedMobilityConfigurationSchema =
  Joi.object<TICIntegrationTuevSuedMobilityConfig>({
    baseUrl: Joi.string().uri().optional().allow(''),
  });

export const getTicIntegrationFormData = (
  properties: Record<string, string>,
): [TICIntegrationFormData, null] | [null, Error] => {
  try {
    const integrationType = validateOrThrow(
      properties[TICIntegrationPropertyKeys.ADAPTER_TYPE],
      ticIntegrationAdapterTypeSchema,
    ) as TICIntegrationAdapterType;

    switch (integrationType) {
      case TICIntegrationAdapterType.MOCK: {
        const division = validateOrThrow(
          properties[VOIZE_METADATA_INDUSTRY] ??
            TICDivisionViaIndustry.Mobility,
          ticDivisionViaIndustrySchema,
        ) as TICDivisionViaIndustry;

        return [{ integrationType, division }, null];
      }
      case TICIntegrationAdapterType.TUEV_NORD: {
        const division = validateOrThrow(
          properties[VOIZE_METADATA_INDUSTRY] ??
            TICDivisionViaIndustry.Mobility,
          ticDivisionViaIndustrySchema,
        ) as TICDivisionViaIndustry;

        const propertyKey =
          division === TICDivisionViaIndustry.Mobility
            ? TICIntegrationPropertyKeys.TUEV_NORD_MOBILITY
            : TICIntegrationPropertyKeys.TUEV_NORD_SYSTEMS;

        const config = validateOrThrow(
          JSON.parse(properties[propertyKey] ?? '{}'),
          ticIntegrationTuevNordMobilityConfigurationSchema,
        );

        return [{ integrationType, division, baseUrl: config.baseUrl }, null];
      }
      case TICIntegrationAdapterType.TUEV_SUED: {
        const config = validateOrThrow(
          JSON.parse(
            properties[TICIntegrationPropertyKeys.TUEV_SUED_MOBILITY] ?? '{}',
          ),
          ticIntegrationTuevNordMobilityConfigurationSchema,
        );
        return [{ integrationType, baseUrl: config.baseUrl }, null];
      }
      default:
        throw new UnreachableCaseError(integrationType);
    }
  } catch (error) {
    return [null, error instanceof Error ? error : new Error(String(error))];
  }
};
