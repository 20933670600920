import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, message } from 'antd';
import { useAdminDeviceApiV4 } from 'api/devices/useAdminDeviceApiV4';
import type { AdminDeviceV4Dto } from 'interfaces/adminDeviceV4';
import getErrorMessage from 'utlis/getErrorMessage';

interface Props {
  device: AdminDeviceV4Dto;
}

const AdminDeviceActions: React.FC<Props> = ({ device }) => {
  const { updateDeviceProperties } = useAdminDeviceApiV4();
  const queryClient = useQueryClient();

  const { isPending, mutate } = useMutation({
    mutationFn: () => updateDeviceProperties(device.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin-devices'] });
      queryClient.invalidateQueries({ queryKey: ['management-devices'] });
      message.success('Device Properties wurden erfolgreich erneuert');
    },
    onError: (error) => {
      message.error(getErrorMessage(error));
    },
  });

  return (
    <Button onClick={() => mutate()} loading={isPending}>
      Device Properties erneuern
    </Button>
  );
};

export default AdminDeviceActions;
