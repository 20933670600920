import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Alert, Skeleton, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useAdminUserApi } from 'api/devices/useAdminUserApi';
import UserControls from 'components/admin/organization-details/UserControls';
import Breadcrumb from 'components/common/Breadcrumb';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import ResourceIcons from 'components/common/resourceIcons';
import { FlexBox } from 'components/Helpers';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import type { User } from 'interfaces/user';
import _ from 'lodash';
import React from 'react';

const stringSorter = (a: string, b: string) => a.localeCompare(b);

const UsersView: React.FC = () => {
  const queryClient = useQueryClient();
  const { getUsers } = useAdminUserApi();

  const queryKey = ['users'];

  const {
    data: results,
    isLoading,
    isError,
  } = useQuery({
    queryKey,
    queryFn: () => getUsers(),
  });

  const users = results ?? [];

  const getColumnSearchProps = useTableSearchFilter<User>();

  const columns: ColumnsType<User> = [
    {
      title: 'Actions',
      key: 'actions',
      render: (user: User) => (
        <FlexBox withgap>
          <UserControls
            user={user}
            onUpdated={() => {
              queryClient.invalidateQueries({
                queryKey,
              });
            }}
            onDeleted={() => {
              queryClient.invalidateQueries({
                queryKey,
              });
            }}
          />
        </FlexBox>
      ),
    },
    {
      title: 'Email',
      key: 'email',
      sorter: (a: User, b: User) => stringSorter(a.email, b.email),
      ...getColumnSearchProps('email', 'email', (record) => record.email),
    },
    {
      title: 'Organization',
      sorter: (a: User, b: User) =>
        stringSorter(a.organization ?? '', b.organization ?? ''),
      filters: _.uniq(
        users.map((user) => user.organization).filter((x) => x !== null),
      ).map((organization) => ({ text: organization, value: organization! })),
      onFilter: (value: any, record: User) => record.organization === value,
      render: (_, user) => user.organization,
    },
    {
      title: 'Created At',
      sorter: (a: User, b: User) => stringSorter(a.createdAt, b.createdAt),
      render: (_, user) => new Date(user.createdAt).toLocaleString(),
    },
    {
      title: 'Updated At',
      sorter: (a: User, b: User) => stringSorter(a.updatedAt, b.updatedAt),
      render: (_, user) => new Date(user.updatedAt).toLocaleString(),
    },
    {
      title: 'Last Login',
      sorter: (a: User, b: User) =>
        stringSorter(a.lastLogin ?? '', b.lastLogin ?? ''),
      // from now
      render: (_, user) =>
        user.lastLogin === null
          ? 'Never'
          : new Date(user.lastLogin).toLocaleString(),
    },
    {
      title: 'Roles',
      sorter: (a: User, b: User) =>
        stringSorter(
          _.sortBy(a.roles).toString(),
          _.sortBy(b.roles).toString(),
        ),
      filters: _.uniq(users.flatMap((user) => user.roles)).map((role) => ({
        text: role,
        value: role,
      })),
      onFilter: (value: any, record: User) => record.roles.includes(value),
      render: (_, user) =>
        user.roles.map((role) => (
          <Tag key={role} color="blue">
            {role}
          </Tag>
        )),
    },
    {
      title: 'Allowed Device Roles',
      sorter: (a: User, b: User) =>
        stringSorter(
          _.sortBy(a.allowedDeviceRoles).toString(),
          _.sortBy(b.allowedDeviceRoles).toString(),
        ),
      filters: _.uniq(users.flatMap((user) => user.allowedDeviceRoles)).map(
        (allowedDeviceRole) => ({
          text: allowedDeviceRole,
          value: allowedDeviceRole,
        }),
      ),
      onFilter: (value: any, record: User) =>
        record.allowedDeviceRoles.includes(value),
      render: (_, user) =>
        user.allowedDeviceRoles.map((allowedDeviceRole) => (
          <Tag key={allowedDeviceRole} color="blue">
            {allowedDeviceRole}
          </Tag>
        )),
    },
    {
      title: 'Groups',
      sorter: (a: User, b: User) =>
        stringSorter(
          _.sortBy(a.groups).toString(),
          _.sortBy(b.groups).toString(),
        ),
      filters: _.uniq(users.flatMap((user) => user.groups)).map((group) => ({
        text: group,
        value: group,
      })),
      onFilter: (value: any, record: User) => record.groups.includes(value),
      render: (_, user) =>
        user.groups.map((group) => (
          <Tag key={group} color="blue">
            {group}
          </Tag>
        )),
    },
    {
      title: 'User ID',
      key: 'id',
      sorter: (a: User, b: User) => stringSorter(a.id, b.id),
      ...getColumnSearchProps('id', 'id', (record) => record.id),
    },
  ];

  return (
    <>
      <Breadcrumb items={[{ name: 'Benutzer', href: '/admin/users' }]} />
      <PageTitleBar title="Benutzer" Icon={ResourceIcons.User} />
      <PageContentContainer>
        {isError && <Alert type="error" message="Something went wrong" />}
        {isLoading ? (
          <Skeleton active />
        ) : (
          <Table
            dataSource={users}
            columns={columns}
            pagination={{ pageSize: 200 }}
            rowKey={(record) => record.id}
          />
        )}
      </PageContentContainer>
    </>
  );
};

export default UsersView;
