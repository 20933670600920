import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useAdminDeviceApi } from 'api/devices/useAdminDeviceApi';
import useListActionManager, {
  type ListAction,
  type Selector,
} from 'hooks/useListActionManager';
import type { DeviceActivationRequest } from 'interfaces/deviceActivationRequest';
import { deviceRoleLabel } from 'labels';
import { BaseError } from 'utlis/BaseError';
import getErrorMessage from 'utlis/getErrorMessage';

const useAdminDeviceActivationRequestSelectionList = (
  deviceActivationRequests: DeviceActivationRequest[],
) => {
  const queryClient = useQueryClient();
  const { postAdminDeviceActivationRequestApprove } = useAdminDeviceApi();

  const { mutateAsync: approveActivationRequests } = useMutation({
    mutationFn: (activationRequests: DeviceActivationRequest[]) => {
      return Promise.all(
        activationRequests.map((activationRequest) => {
          if (!activationRequest.metadata[deviceRoleLabel]) {
            return Promise.reject(
              new BaseError(
                `Aktivierungsanfrage ${activationRequest.id} hat keinen Gerätetyp`,
                'management-device-activation-request-no-device-type',
              ),
            );
          }
          if (activationRequest.approvedAt !== null) {
            return Promise.reject(
              new BaseError(
                `Aktivierungsanfrage ${activationRequest.id} wurde bereits angenommen`,
                'management-device-activation-request-already-approved',
              ),
            );
          }

          return postAdminDeviceActivationRequestApprove(
            activationRequest.id,
            activationRequest.metadata[deviceRoleLabel],
            {},
          );
        }),
      );
    },
    onSuccess: () => {
      message.success('Anfrage angenommen');
      queryClient.invalidateQueries({
        queryKey: ['management-device-activation-requests'],
      });
    },
    onError: (error: any) => {
      message.error(getErrorMessage(error));
      queryClient.invalidateQueries({
        queryKey: ['management-device-activation-requests'],
      });
    },
  });

  const listActions: ListAction<DeviceActivationRequest>[] = [
    {
      key: 'accept',
      label: 'Annehmen',
      action: (items) => approveActivationRequests(items),
      renderConfirmText: (items) =>
        `Möchten Sie die ${items.length} ausgewählten Aktivierungsanfragen annehmen?`,
    },
  ];

  const selectors: Selector<DeviceActivationRequest>[] = [
    {
      key: 'unaccepted',
      label: 'Noch nicht angenommen',
      onClick: (items) => items.filter((item) => item.approvedAt === null),
    },
  ];

  const listActionManagerState = useListActionManager(
    deviceActivationRequests,
    listActions,
    selectors,
  );

  return listActionManagerState;
};

export default useAdminDeviceActivationRequestSelectionList;
