import { WarningOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { FlexBox } from 'components/Helpers';
import type { DeviceActivationRequest } from 'interfaces/deviceActivationRequest';
import type { ManagementDeviceActivationRequest } from 'interfaces/managementActivationRequest';
import Highlighter from 'react-highlight-words';

interface Props {
  activationRequest:
    | ManagementDeviceActivationRequest
    | DeviceActivationRequest;
  searchWords?: string[];
  autoEscape?: boolean;
}

const ActivationRequestEmail: React.FC<Props> = ({
  activationRequest,
  searchWords,
  autoEscape,
}) => {
  return (
    <FlexBox withgap>
      {!activationRequest.userEmailVerifiedAt && (
        <Tooltip
          title="Die E-Mail Adresse wurde noch nicht bestätigt."
          mouseEnterDelay={0.3}
        >
          <WarningOutlined style={{ color: 'red' }} />
        </Tooltip>
      )}

      <Highlighter
        searchWords={searchWords ?? []}
        autoEscape={autoEscape}
        textToHighlight={activationRequest.userEmail}
      />
    </FlexBox>
  );
};

export default ActivationRequestEmail;
