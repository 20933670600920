import useGlobalDeviceConfigurations from 'hooks/useGlobalDeviceConfigurations';
import useManagementDeviceConfigurations from 'hooks/useManagementDeviceConfigurations';
import type { GlobalDeviceConfiguration } from 'interfaces/adminGlobalDeviceConfig';
import type { ManagementDeviceConfiguration } from 'interfaces/managementDeviceConfiguration';
import { Operator } from 'interfaces/matchers';
import _ from 'lodash';
import { useMemo } from 'react';

export function getSimpleConfigSelectorLabels(
  config: ManagementDeviceConfiguration,
): Record<string, string[]> {
  return _.mapValues(
    _.groupBy(
      (config.deviceSelector.matchLabels ?? []).filter(
        (label) => label.operator == Operator.IN,
      ),
      'key',
    ),
    (label) => label.flatMap((l) => l.values ?? []),
  );
}

export function getSimpleConfigSelectorLabelsFromGlobal(
  config: GlobalDeviceConfiguration,
): Record<string, string[]> {
  return _.mapValues(
    _.groupBy(
      (config.deviceSelector.matchLabels ?? []).filter(
        (label) => label.operator == Operator.IN,
      ),
      'key',
    ),
    (label) => label.flatMap((l) => l.values ?? []),
  );
}

export function getSimpleConfigSelectorLabelKeys(
  config: ManagementDeviceConfiguration,
): string[] {
  return Object.keys(getSimpleConfigSelectorLabels(config));
}

export function getConfigSelectorLabelValue(
  config: ManagementDeviceConfiguration,
  labelKey: string,
): string | null {
  return getSimpleConfigSelectorLabels(config)[labelKey]?.[0] ?? null;
}

export function getConfigSelectorLabelValueOrThrow(
  config: ManagementDeviceConfiguration,
  labelKey: string,
): string {
  const value = getConfigSelectorLabelValue(config, labelKey);
  if (value === null) {
    throw new Error(`Configuration does not have label ${labelKey}`);
  }
  return value;
}

export function useAvailableDeviceSelectors() {
  const { data: configurations } = useManagementDeviceConfigurations();
  // not accessible to all users
  const { data: globalConfigurations } = useGlobalDeviceConfigurations();

  const deviceLabelsFromConfigs = useMemo(() => {
    return _.mapValues(
      _.groupBy(
        [
          ...(configurations ?? []).flatMap((config) =>
            Object.entries(getSimpleConfigSelectorLabels(config)),
          ),
          ...(globalConfigurations ?? []).flatMap((config) =>
            Object.entries(getSimpleConfigSelectorLabelsFromGlobal(config)),
          ),
        ],
        '0',
      ),
      (label) => label.flatMap((l) => l[1]),
    );
  }, [configurations, globalConfigurations]);

  return deviceLabelsFromConfigs;
}
