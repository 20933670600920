import { Checkbox, Divider, Form, Input, Modal } from 'antd';
import type { UpdateSeniorCareUserDto } from 'api/seniorcare/useSeniorCareApi';
import type { SeniorCareManagementUser } from 'interfaces/seniorcare/seniorCareUser';
import React from 'react';
import { appIsCoachKey } from 'userMetadataKeys';

interface Props {
  user: SeniorCareManagementUser;
  onSubmit: (data: UpdateSeniorCareUserDto) => Promise<void>;
  onClose?: () => void;
  onEdited?: () => void;
}

interface FormData {
  firstname: string;
  lastname: string;
  isCoach: boolean;
}

const SeniorCareAppUserEditModal: React.FC<Props> = ({
  user,
  onSubmit,
  onClose,
  onEdited,
}) => {
  const [form] = Form.useForm<FormData>();

  const handleSubmit = async (data: FormData) => {
    await onSubmit({
      firstname: data.firstname,
      lastname: data.lastname,
      metadata: {
        ...user.metadata,
        [appIsCoachKey]: data.isCoach ? 'true' : 'false',
      },
    });
    onEdited?.();
  };

  return (
    <Modal
      title="Benutzer bearbeiten"
      open={true}
      onCancel={onClose}
      onOk={form.submit}
      okText="Speichern"
      width={900}
    >
      <Form<FormData>
        form={form}
        onFinish={handleSubmit}
        labelCol={{ span: 3 }}
        initialValues={{
          firstname: user.firstname,
          lastname: user.lastname,
          isCoach: user.metadata[appIsCoachKey] === 'true',
        }}
      >
        <Form.Item
          label="Vorname"
          name="firstname"
          rules={[
            { required: true, message: 'Bitte geben Sie den Vornamen ein.' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Nachname"
          name="lastname"
          rules={[
            { required: true, message: 'Bitte geben Sie den Nachnamen ein.' },
          ]}
        >
          <Input />
        </Form.Item>
        <Divider plain>Metadaten</Divider>
        <Form.Item
          wrapperCol={{ offset: 3 }}
          name="isCoach"
          valuePropName="checked"
        >
          <Checkbox>Benutzer ist ein voize Coach</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SeniorCareAppUserEditModal;
