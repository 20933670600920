import { Alert, Modal, Select } from 'antd';
import ActivationRequestEmail from 'components/common/ActivationRequestEmail';
import IdTag from 'components/common/IdTag';
import ListItemInfoTag from 'components/common/ListItemInfoTag';
import Metadata from 'components/common/Metadata';
import RelativeDateTimeWithTooltip from 'components/common/RelativeDateTimeWithTooltip';
import { FlexBox } from 'components/Helpers';
import device from 'defaults/deviceRoles';
import type { DeviceActivationRequest } from 'interfaces/deviceActivationRequest';
import { deviceRoleLabel } from 'labels';
import { useCallback, useState } from 'react';
import UpdateDeviceActivationRequestOrganizationInput from './UpdateDeviceActivationRequestOrganizationInput';

interface Props {
  activationRequest: DeviceActivationRequest;
  open: boolean;
  onActivate: (deviceRole: string) => Promise<void>;
  onClose?: () => void;
  isLoading?: boolean;
}
const AdminDeviceActivationModal: React.FC<Props> = ({
  activationRequest,
  open,
  onActivate,
  onClose,
  isLoading,
}) => {
  const [error, setError] = useState<null | string>(null);
  const [deviceRole, setDeviceRole] = useState(
    activationRequest.metadata[deviceRoleLabel] ?? '',
  );

  const close = useCallback(() => {
    setError(null);
    setDeviceRole(activationRequest.metadata[deviceRoleLabel] ?? '');
    onClose?.();
  }, [onClose, activationRequest.metadata]);

  const activate = useCallback(() => {
    setError(null);
    if (!activationRequest.organization) {
      setError(
        'Keine Organisation ausgewählt. Bitte wählen Sie eine Organisation aus.',
      );
      return;
    }
    if (!deviceRole || deviceRole.length === 0) {
      setError('Keine Rolle ausgewählt. Bitte wählen Sie eine Rolle aus.');
      return;
    }

    onActivate(deviceRole);
  }, [activationRequest.organization, deviceRole, onActivate]);

  return (
    <Modal
      title={
        <FlexBox withgap>
          Aktivierungsanfrage <IdTag id={activationRequest.id} />
        </FlexBox>
      }
      open={open}
      okText="Anfrage annehmen"
      cancelText="Abbrechen"
      onOk={activate}
      onCancel={close}
      width={800}
      confirmLoading={isLoading}
    >
      <FlexBox direction="column" withgap>
        <Metadata metadata={activationRequest.metadata} />

        <ListItemInfoTag label="Geräte-ID">
          {activationRequest.deviceId ?? '-'}
        </ListItemInfoTag>

        <ListItemInfoTag label="Username">
          {activationRequest.userName}
        </ListItemInfoTag>
        <ListItemInfoTag label="E-Mail Adresse">
          <ActivationRequestEmail activationRequest={activationRequest} />
        </ListItemInfoTag>

        <ListItemInfoTag
          label="Organisation"
          color={activationRequest.organization ? undefined : 'red'}
        >
          <UpdateDeviceActivationRequestOrganizationInput
            deviceActivationRequest={activationRequest}
          />
        </ListItemInfoTag>

        <ListItemInfoTag label="Rolle">
          <FlexBox withgap>
            <Select
              value={deviceRole}
              onChange={(value) => setDeviceRole(value)}
              options={device.map((role) => ({
                value: role,
                label: role,
              }))}
              size="small"
              style={{ width: '200px' }}
            />
          </FlexBox>
        </ListItemInfoTag>
        <ListItemInfoTag label="Erstellt">
          <RelativeDateTimeWithTooltip
            timestamp={activationRequest.createdAt}
          />
        </ListItemInfoTag>
        <ListItemInfoTag
          label="E-Mail Adresse bestätigt"
          valueStyle={{
            backgroundColor: activationRequest.userEmailVerifiedAt
              ? 'hsla(90, 100%, 72%, 1)'
              : 'hsla(0, 100%, 72%, 1)',
          }}
        >
          <RelativeDateTimeWithTooltip
            timestamp={activationRequest.userEmailVerifiedAt}
            noneText="Noch nicht bestätigt"
          />
        </ListItemInfoTag>

        {error && <Alert type="error" message={error} />}
      </FlexBox>
    </Modal>
  );
};

export default AdminDeviceActivationModal;
