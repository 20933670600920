import { Button, Select, type SelectProps, Space } from 'antd';
import { type PropsWithChildren, useState } from 'react';
import styled from 'styled-components';

const ActionBar = ({
  children,
  isVisible,
}: PropsWithChildren<{ isVisible: boolean }>) => (
  <ActionBarStyled isVisible={isVisible}>{children}</ActionBarStyled>
);

const ActionBarSelect = <T extends undefined>({
  onSave,
  placeholder,
  ...props
}: SelectProps<T extends infer U ? U : unknown> & {
  onSave: (value: T | undefined) => void;
}) => {
  const [value, setValue] = useState<T>();
  const [width, setWidth] = useState(0);
  const [isActive, setIsActive] = useState(false);

  return (
    <Select
      {...props}
      onBlur={() => {
        onSave(value);
        setValue(undefined);
        setIsActive(false);
      }}
      onFocus={() => {
        setIsActive(true);
      }}
      onSelect={(value) => {
        setValue(value);
      }}
      optionFilterProp="label"
      placeholder={
        <ActionBarSelectPlaceholder isActive={isActive}>
          {placeholder}
        </ActionBarSelectPlaceholder>
      }
      ref={(ref) => {
        const newWidth = ref?.nativeElement.offsetWidth ?? 0;

        if (width === 0 && newWidth > 0) {
          setWidth(newWidth + 1);
        }
      }}
      showSearch
      style={{
        transition: 'width 300ms ease-in-out',
        width:
          width === 0
            ? undefined
            : isActive
              ? `${Math.max(300, width)}px`
              : `${width}px`,
      }}
      suffixIcon={!isActive ? null : undefined}
      value={value}
    />
  );
};

ActionBar.Button = Button;
ActionBar.Select = ActionBarSelect;

const ActionBarStyled = styled(Space.Compact)<{ isVisible: boolean }>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  ${({ isVisible }) => (!isVisible ? 'pointer-events: none;' : '')};
  transition: opacity 300ms ease-in-out;
`;

const ActionBarSelectPlaceholder = styled.span<{ isActive: boolean }>`
  color: ${({ isActive, theme }) =>
    !isActive ? theme.antd.colorText : theme.antd.colorTextPlaceholder};
  transition: color 300ms ease-in-out;
`;

export default ActionBar;
