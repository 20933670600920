import type { ManagementDevice } from 'interfaces/device';
import type { DeviceActivationRequest } from 'interfaces/deviceActivationRequest';
import { useMemo } from 'react';
import useAdminDeviceActivationRequests from './admin-device-activation-requests/useAdminDeviceActivationRequests';
import useAdminDevices from './devices/useAdminDevices';

export enum CombinedActivationRequestAndDeviceType {
  DEVICE = 'device',
  ACTIVATION_REQUEST = 'activation_request',
}

interface BaseCombined<T extends CombinedActivationRequestAndDeviceType> {
  type: T;
  id: string; // for table
}

export interface AdminDeviceWithActivationRequest
  extends BaseCombined<CombinedActivationRequestAndDeviceType.DEVICE> {
  device: ManagementDevice;
  activationRequest: DeviceActivationRequest | null;
  organization: string;
}

export interface AdminActivationRequest
  extends BaseCombined<CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST> {
  activationRequest: DeviceActivationRequest;
  organization: string | null;
}

export type CombinedActivationRequestAndDevice =
  | AdminDeviceWithActivationRequest
  | AdminActivationRequest;

const useCombinedActivationRequestsAndDevices = () => {
  const {
    data: activationRequests,
    isLoading: isLoadingActivationRequests,
    refetch: refetchActivationRequests,
  } = useAdminDeviceActivationRequests();

  const {
    devices: adminDevices,
    isLoading: isLoadingDevices,
    refetch: refetchDevices,
    invalidateDevices: invalidateDevices,
  } = useAdminDevices(undefined);

  const combined: CombinedActivationRequestAndDevice[] = useMemo(() => {
    if (!activationRequests || !adminDevices) return [];
    const tempActivationRequests = [...activationRequests];

    const combined = adminDevices.map<AdminDeviceWithActivationRequest>(
      (device) => {
        const activationRequestIndex = tempActivationRequests.findIndex(
          (request) => request.deviceId === device.id,
        );

        const activationRequest =
          activationRequestIndex > -1
            ? (tempActivationRequests[activationRequestIndex] ?? null)
            : null;

        if (activationRequestIndex > -1) {
          tempActivationRequests.splice(activationRequestIndex, 1);
        }

        return {
          type: CombinedActivationRequestAndDeviceType.DEVICE,
          id: device.id,
          device,
          activationRequest,
          organization: device.organization,
        };
      },
    );

    const restActivationRequests =
      tempActivationRequests.map<AdminActivationRequest>(
        (activationRequest) => ({
          type: CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST,
          id: activationRequest.id,
          activationRequest,
          organization: activationRequest.organization,
        }),
      );

    return [...combined, ...restActivationRequests];
  }, [activationRequests, adminDevices]);

  return {
    data: combined,
    isLoading: isLoadingActivationRequests || isLoadingDevices,
    refetch: () => {
      refetchActivationRequests();
      refetchDevices();
    },
    invalidateDevices,
    devicesNumber: adminDevices?.length ?? 0,
    activationRequestsNumber: activationRequests?.length ?? 0,
  };
};

export default useCombinedActivationRequestsAndDevices;
