import { FlexBox } from 'components/Helpers';
import { getPresentFeatures } from 'defaults/featuresToDeviceProperties';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import DeviceFeature from './DeviceFeature';

interface Props {
  deviceProperties: Record<string, string>;
  onChange: (properties: Record<string, string>) => void;
  canDelete: boolean;
  isAllInEditMode: boolean;
}
const DeviceFeatures: React.FC<Props> = ({
  deviceProperties,
  onChange,
  canDelete,
  isAllInEditMode,
}) => {
  const presentFeatures = useMemo(
    () => getPresentFeatures(deviceProperties ?? {}),
    [deviceProperties],
  );

  const [lastPresentFeatures, setLastPresentFeatures] =
    useState(presentFeatures);
  const [addedFeatureIds, setAddedFeatureIds] = useState<string[]>([]);

  useEffect(() => {
    if (lastPresentFeatures !== presentFeatures) {
      const newFeatures = presentFeatures.filter(
        (feature) => !_.some(lastPresentFeatures, feature),
      );
      if (newFeatures.length > 0) {
        setAddedFeatureIds((prev) => [
          ...prev,
          ...newFeatures.map((f) => f.id),
        ]);
      }
      setLastPresentFeatures(presentFeatures);
    }
  }, [presentFeatures, lastPresentFeatures]);

  return (
    <>
      <Wrapper direction="column" withgap>
        {/* We use lastPresentFeatures here instead of presentFeatures so that wasJustAdded has the correct state on mount of the feature */}
        {lastPresentFeatures.map((feature) => (
          <DeviceFeature
            key={feature.id}
            featureDeviceProperties={_.pick(
              deviceProperties,
              feature.properties,
            )}
            allDeviceProperties={deviceProperties}
            feature={feature}
            wasJustAdded={addedFeatureIds.includes(feature.id)}
            onDelete={() =>
              onChange(_.omit(deviceProperties, feature.properties))
            }
            onUpdate={(properties) => {
              // Merge the current device properties with the new properties.
              // If a property is set to null in the new properties, remove it from the device properties.
              const updatedProperties = Object.fromEntries(
                Object.entries({ ...deviceProperties, ...properties }).filter(
                  (entry): entry is [string, string] => entry[1] !== null,
                ),
              );
              onChange(updatedProperties);
            }}
            canDelete={canDelete}
            isEditModeForced={isAllInEditMode}
          />
        ))}
      </Wrapper>
    </>
  );
};

const Wrapper = styled(FlexBox)`
  margin-bottom: ${({ theme }) => theme.antd.marginLG}px;
  margin-top: ${({ theme }) => theme.antd.marginXS}px;
  overflow-x: auto;
`;

export default DeviceFeatures;
