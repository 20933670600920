import type { ManagementDeviceConfiguration } from 'interfaces/managementDeviceConfiguration';
import type {
  DeviceSelector,
  GlobalDeviceConfiguration,
} from '../interfaces/adminGlobalDeviceConfig';
import type {
  OrganizationDeviceConfiguration,
  OrganizationDeviceSelector,
} from '../interfaces/adminOrganizationDeviceConfig';
import type { ManagementDevice } from '../interfaces/device';
import {
  Operator,
  type MatchExpression,
  type SetMatch,
} from '../interfaces/matchers';

/**
 * Checks if a label matches the given expression
 */
export const matchLabelExpression = (
  expression: MatchExpression,
  labels: Record<string, string>,
): boolean => {
  const labelValueOrNull = labels[expression.key];
  switch (expression.operator) {
    case Operator.IN:
      return (
        labelValueOrNull !== undefined &&
        (expression.values?.includes(labelValueOrNull) ?? false)
      );
    case Operator.NOT_IN:
      return (
        labelValueOrNull === undefined ||
        !(expression.values?.includes(labelValueOrNull) ?? false)
      );
    case Operator.EXISTS:
      return labelValueOrNull !== undefined;
    case Operator.DOES_NOT_EXIST:
      return labelValueOrNull === undefined;
  }
};

/**
 * Checks if all label expressions match
 */
export const matchLabels = (
  expressions: MatchExpression[] | undefined,
  labels: Record<string, string>,
): boolean => {
  if (!expressions || expressions.length === 0) {
    return true;
  }
  return expressions.every((expression) =>
    expression ? matchLabelExpression(expression, labels) : true,
  );
};

/**
 * Checks if a value matches the SetMatch criteria
 */
export const matchSetMatch = (
  setMatch: SetMatch | undefined,
  value: string,
): boolean => {
  if (setMatch === undefined) {
    return true;
  }

  const inList = setMatch.in ?? [];
  const notInList = setMatch.notIn ?? [];

  return (
    (inList.length === 0 || inList.includes(value)) &&
    (notInList.length === 0 || !notInList.includes(value))
  );
};

/**
 * Checks if a device matches an organization device selector
 */
export const deviceMatchesOrgSelector = (
  device: ManagementDevice,
  selector: OrganizationDeviceSelector,
): boolean => {
  return (
    matchLabels(selector.matchLabels, device.labels) &&
    matchSetMatch(selector.matchRole, device.role)
  );
};

/**
 * Checks if a device matches a global device selector
 */
export const deviceMatchesGlobalSelector = (
  device: ManagementDevice,
  selector: DeviceSelector,
): boolean => {
  return (
    matchLabels(selector.matchLabels, device.labels) &&
    matchSetMatch(selector.matchRole, device.role) &&
    matchSetMatch(selector.matchOrganization, device.organization)
  );
};

/**
 * Filters devices by organization
 */
export const filterDevicesByOrganization = (
  devices: ManagementDevice[],
  organization: string | null,
): ManagementDevice[] => {
  if (organization === null) {
    return devices;
  }
  return devices.filter((device) => device.organization === organization);
};

/**
 * Counts devices that match each label expression
 */
export const countDevicesByLabelExpression = (
  devices: ManagementDevice[],
  labelExpressions: MatchExpression[] | undefined,
  roleMatch: SetMatch | undefined,
  organization: string | null,
): Record<string, number> => {
  if (!labelExpressions || labelExpressions.length === 0) {
    return {};
  }

  const organizationFilteredDevices = filterDevicesByOrganization(
    devices,
    organization,
  );
  const result: Record<string, number> = {};

  labelExpressions.forEach((expression) => {
    if (!expression) return;

    const key = `${expression.key}:${expression.operator}:${expression.values?.join(',') || ''}`;
    result[key] = organizationFilteredDevices.filter(
      (device) =>
        matchLabelExpression(expression, device.labels) &&
        matchSetMatch(roleMatch, device.role),
    ).length;
  });

  return result;
};

/**
 * Counts how many devices match each role in a SetMatch
 */
export const countDevicesByRoleMatch = (
  devices: ManagementDevice[],
  roleMatch: SetMatch | undefined,
  labelExpressions: MatchExpression[] | undefined,
  organization: string | null,
): Record<string, number> => {
  if (!roleMatch || (!roleMatch.in?.length && !roleMatch.notIn?.length)) {
    return {};
  }

  // Filter devices by organization and labels first
  const organizationFilteredDevices = filterDevicesByOrganization(
    devices,
    organization,
  );
  const filteredDevices = organizationFilteredDevices.filter((device) =>
    matchLabels(labelExpressions, device.labels),
  );

  const counts: Record<string, number> = {};

  // Count devices matching each role in the "in" list
  if (roleMatch.in?.length) {
    roleMatch.in.forEach((role) => {
      counts[`in:${role}`] = filteredDevices.filter(
        (device) => device.role === role,
      ).length;
    });
  }

  // Count devices NOT matching each role in the "notIn" list
  if (roleMatch.notIn?.length) {
    roleMatch.notIn.forEach((role) => {
      counts[`notIn:${role}`] = filteredDevices.filter(
        (device) => device.role !== role,
      ).length;
    });
  }

  return counts;
};

/**
 * Counts total devices matching a selector
 */
export const countMatchingDevices = (
  devices: ManagementDevice[],
  selector: OrganizationDeviceSelector,
  organization: string | null,
): ManagementDevice[] => {
  const organizationFilteredDevices = filterDevicesByOrganization(
    devices,
    organization,
  );
  return organizationFilteredDevices.filter((device) =>
    deviceMatchesOrgSelector(device, selector),
  );
};

/**
 * Gets devices that match a specific label expression
 */
export const getDevicesForLabel = (
  devices: ManagementDevice[],
  expression: MatchExpression,
  organization: string | null,
): ManagementDevice[] => {
  const organizationFilteredDevices = filterDevicesByOrganization(
    devices,
    organization,
  );
  return organizationFilteredDevices.filter((device) => {
    const labelValueOrNull = device.labels[expression.key];
    switch (expression.operator) {
      case Operator.IN:
        return (
          labelValueOrNull !== undefined &&
          (expression.values?.includes(labelValueOrNull) ?? false)
        );
      case Operator.NOT_IN:
        return (
          labelValueOrNull === undefined ||
          !(expression.values?.includes(labelValueOrNull) ?? false)
        );
      case Operator.EXISTS:
        return labelValueOrNull !== undefined;
      case Operator.DOES_NOT_EXIST:
        return labelValueOrNull === undefined;
    }
  });
};

/**
 * Gets devices that match a specific role
 */
export const getDevicesForRole = (
  devices: ManagementDevice[],
  role: string,
  isNotIn = false,
  organization: string | null,
): ManagementDevice[] => {
  const organizationFilteredDevices = filterDevicesByOrganization(
    devices,
    organization,
  );
  if (!isNotIn) {
    return organizationFilteredDevices.filter((device) => device.role === role);
  } else {
    return organizationFilteredDevices.filter((device) => device.role !== role);
  }
};

/**
 * Gets devices that match a specific organization
 */
export const getDevicesForOrganization = (
  devices: ManagementDevice[],
  organization: string,
  isNotIn = false,
): ManagementDevice[] => {
  if (!isNotIn) {
    return devices.filter((device) => device.organization === organization);
  } else {
    return devices.filter((device) => device.organization !== organization);
  }
};

/**
 * Gets all matching configurations for a device, both global and organization specific
 */
export const getMatchingConfigurations = (
  device: ManagementDevice,
  globalConfigurations: GlobalDeviceConfiguration[] | undefined,
  organizationConfigurations:
    | OrganizationDeviceConfiguration[]
    | ManagementDeviceConfiguration[]
    | undefined,
): {
  globalConfigurations: GlobalDeviceConfiguration[];
  organizationConfigurations:
    | OrganizationDeviceConfiguration[]
    | ManagementDeviceConfiguration[];
} => {
  const matchingGlobalConfigs = (globalConfigurations ?? []).filter((config) =>
    deviceMatchesGlobalSelector(device, config.deviceSelector),
  );

  const matchingOrgConfigs = (organizationConfigurations ?? [])
    .filter((config) =>
      'organization' in config
        ? config.organization === device.organization
        : true,
    )
    .filter((config) =>
      deviceMatchesOrgSelector(device, config.deviceSelector),
    );

  return {
    globalConfigurations: matchingGlobalConfigs,
    organizationConfigurations: matchingOrgConfigs,
  };
};
