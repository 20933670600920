import { Button, Form, Input } from 'antd';
import type { FeatureRenderProps } from 'defaults/featuresToDeviceProperties';
import Joi from 'joi';
import { useMemo } from 'react';
import { validateJsonProperty, validateOrThrow } from 'utlis/validationUtil';
import IntegrationCacheServiceConfigSummary from './IntegrationCacheServiceConfigSummary';

export const integrationCacheServiceConfigPropertyId =
  'INTEGRATION_CACHE_SERVICE_CONFIG';

interface IntegrationCacheServiceConfig {
  cacheServiceBaseUrl: string;
  cacheServiceToken: string;
}

export const integrationCacheServiceConfigSchema =
  Joi.object<IntegrationCacheServiceConfig>({
    cacheServiceBaseUrl: Joi.string().uri().required(),
    cacheServiceToken: Joi.string().required(),
  });

const IntegrationCacheServiceConfigFeature: React.FC<FeatureRenderProps> = ({
  values,
  onUpdate,
  isEditing,
  onEditDone,
}) => {
  const [form] = Form.useForm<IntegrationCacheServiceConfig>();

  const [config] = useMemo(
    () =>
      validateJsonProperty(
        values[integrationCacheServiceConfigPropertyId],
        integrationCacheServiceConfigSchema,
      ),
    [values],
  );

  if (!isEditing)
    return <IntegrationCacheServiceConfigSummary properties={values} />;
  return (
    <Form
      labelAlign="left"
      labelCol={{ span: 6 }}
      form={form}
      onFinish={(formData) => {
        const config = validateOrThrow(
          formData,
          integrationCacheServiceConfigSchema,
        );

        const configJSON = JSON.stringify(config);

        onUpdate({
          ...values,
          [integrationCacheServiceConfigPropertyId]: configJSON,
        });
        onEditDone?.();
      }}
      initialValues={config ?? undefined}
      onChange={() => {
        if (onEditDone === undefined) {
          form.submit();
        }
      }}
    >
      <Form.Item
        label="Cache Service Base URL"
        name="cacheServiceBaseUrl"
        rules={[
          {
            required: true,
            type: 'url',
            message: 'Bitte geben Sie eine gültige URL ein.',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Cache Service Token"
        name="cacheServiceToken"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      {onEditDone !== undefined && (
        <Button type="primary" onClick={form.submit}>
          Speichern
        </Button>
      )}
    </Form>
  );
};

export default IntegrationCacheServiceConfigFeature;
