import { Link } from '@tanstack/react-router';
import { Card, Col, Row, Typography } from 'antd';
import ResourceIcons from 'components/common/resourceIcons';
import { FlexBox } from 'components/Helpers';
import {
  useSeniorCareDeviceConfigs,
  useSeniorCareIntegrationConfigs,
} from 'components/seniorcare/SeniorCareDeviceConfigurationAssignment';
import { seniorCareAppDeviceRole } from 'defaults/deviceRoles';
import { useIsSeniorCareView } from 'hooks/auth/useIsSeniorCareView';
import { useOrganization } from 'hooks/auth/useOrganization';
import useManagementDevices from 'hooks/devices/useManagementDevices';
import useManagementDeviceActivationRequests from 'hooks/management-device-activation-requests/useManagementDeviceActivationRequests';
import useLoadAllPaginatedCursorQuery from 'hooks/useLoadAllPaginatedCursorQuery';
import useSeniorCareManagementUsers from 'hooks/useSeniorCareUsers';
import { useAtom } from 'jotai';
import React from 'react';
import { useManagementPathOrganization } from 'router';
import { isUserAdvancedAtom } from 'state/state';
import InsightsView from './InsightsView';

const OrganizationHomeView: React.FC = () => {
  const pathOrganization = useManagementPathOrganization();

  const { devices } = useManagementDevices();
  const seniorCareDevicesCount = devices.filter(
    (device) => device.role === seniorCareAppDeviceRole,
  ).length;
  const { getUsers } = useSeniorCareManagementUsers();
  const seniorCareIntegrationConfigs = useSeniorCareIntegrationConfigs();
  const seniorCareDeviceConfigs = useSeniorCareDeviceConfigs();
  const organizationData = useOrganization();

  const { openRequests } = useManagementDeviceActivationRequests();
  const organizationId = useManagementPathOrganization();
  const [isUserAdvanced] = useAtom(isUserAdvancedAtom);
  const isSeniorCareView = useIsSeniorCareView();

  const { data: users } = useLoadAllPaginatedCursorQuery({
    queryKey: ['management-senior-care-users', organizationId],
    queryFn: getUsers,
  });

  if (organizationData === null) return null;

  return (
    <FlexBox
      style={{
        flex: 1,
        padding: 40,
        backgroundColor: 'hsl(200, 10%, 97.5%)',
      }}
      withgap
      gap={20}
      direction="column"
      alignitems="flex-start"
    >
      <Typography.Title level={2} style={{ margin: 0 }}>
        {organizationData.name}
      </Typography.Title>
      <div />
      <Row gutter={[20, 20]} style={{ marginBottom: 20 }}>
        {(!isSeniorCareView || isUserAdvanced) && (
          <Col>
            <Link
              to="/management/$organization/devices"
              params={{ organization: pathOrganization }}
            >
              <DashboardItem
                title="Geräte"
                count={devices.length.toString()}
                icon={<ResourceIcons.Device size={24} />}
              />
            </Link>
          </Col>
        )}
        {isSeniorCareView && (
          <>
            <Col>
              <Link
                to="/management/$organization/senior-care-devices"
                params={{ organization: pathOrganization }}
              >
                <DashboardItem
                  title={isUserAdvanced ? 'Geräte (Pflege App)' : 'Geräte'}
                  count={seniorCareDevicesCount.toString()}
                  icon={<ResourceIcons.Device size={24} />}
                />
              </Link>
            </Col>
            <Col>
              <Link
                to="/management/$organization/senior-care-users"
                params={{ organization: pathOrganization }}
              >
                <DashboardItem
                  title={isUserAdvanced ? 'Benutzer (Pflege App)' : 'Benutzer'}
                  count={users?.length?.toString() ?? '...'}
                  icon={<ResourceIcons.User size={24} />}
                />
              </Link>
            </Col>
            <Col>
              <Link
                to="/management/$organization/integration-configurations"
                params={{ organization: pathOrganization }}
              >
                <DashboardItem
                  title={
                    isUserAdvanced
                      ? 'Schnittstellen (Pflege App)'
                      : 'Schnittstellen'
                  }
                  count={seniorCareIntegrationConfigs.length.toString()}
                  icon={<ResourceIcons.Integration size={24} />}
                />
              </Link>
            </Col>
            <Col>
              <Link
                to="/management/$organization/senior-care-device-configurations"
                params={{ organization: pathOrganization }}
              >
                <DashboardItem
                  title={
                    isUserAdvanced
                      ? 'Gerätekonfigurationen (Pflege App)'
                      : 'Gerätekonfigurationen'
                  }
                  count={seniorCareDeviceConfigs.length.toString()}
                  icon={<ResourceIcons.DeviceConfiguration size={24} />}
                />
              </Link>
            </Col>
          </>
        )}
        {(!isSeniorCareView || isUserAdvanced) && (
          <Col>
            <Link
              to="/management/$organization/device-activation-requests"
              params={{ organization: pathOrganization }}
            >
              <DashboardItem
                title="Offene Aktivierungsanfragen"
                count={openRequests.length.toString()}
                icon={<ResourceIcons.ActivationRequest size={24} />}
              />
            </Link>
          </Col>
        )}
      </Row>
      <InsightsView />
    </FlexBox>
  );
};

const DashboardItem: React.FC<{
  title: string;
  count: string;
  icon: React.ReactNode;
}> = ({ title, count, icon }) => {
  return (
    <Card style={{ width: 300 }} hoverable>
      <FlexBox withgap alignitems="center" gap={20}>
        <FlexBox
          style={{
            width: 50,
            height: 50,
            background: 'hsl(200, 100%, 95%)',
            borderRadius: 100,
            color: 'hsl(200, 100%, 50%)',
          }}
          alignitems="center"
          justifycontent="center"
        >
          {icon}
        </FlexBox>
        <FlexBox direction="column">
          <Typography.Title level={4} style={{ margin: 0 }}>
            {count}
          </Typography.Title>
          <Typography.Text style={{ color: 'hsl(200, 10%, 50%)' }}>
            {title}
          </Typography.Text>
        </FlexBox>
      </FlexBox>
    </Card>
  );
};

export default OrganizationHomeView;
