import { CloseOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Tag } from 'antd';
import { FlexBox } from 'components/Helpers';
import React, { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';

type Labels = Record<string, string>;

interface Props {
  labels: Labels;
  editable?: boolean;
  onChange?: (labels: Labels) => void;
  highlightTexts?: string[];
  containerStyle?: React.CSSProperties;
  labelOptions?: Record<string, string[]>;
}

const EllipsisLabel = ({ children }: { children: React.ReactNode }) => {
  const [isClicked, setIsClicked] = useState(false);
  return (
    <div
      onClick={() => setIsClicked((isClicked) => !isClicked)}
      className={`max-w-[500px] cursor-pointer break-words ${
        isClicked
          ? 'whitespace-pre-wrap overflow-visible'
          : 'overflow-hidden text-ellipsis'
      }`}
    >
      {children}
    </div>
  );
};

const LabelList: React.FC<Props> = ({
  labels,
  onChange = () => {},
  editable = false,
  highlightTexts = [],
  containerStyle,
  labelOptions = {},
}) => {
  const [labelList, setLabelList] = useState<Labels>(labels);
  const [newLabelKey, setNewLabelKey] = useState('');
  const [newLabelValue, setNewLabelValue] = useState('');
  useEffect(() => {
    setLabelList(labels);
  }, [labels]);

  const removeLabel = (key: string) => {
    const newLabelList = { ...labelList };
    delete newLabelList[key];
    setLabelList(newLabelList);
    onChange(newLabelList);
  };

  const addSpecificLabel = (key: string, value: string) => {
    const newLabelList = { ...labelList, [key]: value };
    setLabelList(newLabelList);
    onChange(newLabelList);
  };

  const addLabel = () => {
    addSpecificLabel(newLabelKey.trim(), newLabelValue);
    setNewLabelKey('');
    setNewLabelValue('');
  };

  return (
    <div className="w-full">
      <FlexBox wrap style={containerStyle}>
        {Object.keys(labelList).map((key) => (
          <Tag key={'tag-' + key} className="m-[5px]" color={'blue'}>
            <FlexBox alignitems="center" wrap>
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={highlightTexts}
                autoEscape
                textToHighlight={key}
              />
              :
              <EllipsisLabel>
                <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={highlightTexts}
                  autoEscape
                  textToHighlight={labelList[key] ?? ''}
                />
              </EllipsisLabel>
              {editable && (
                <CloseOutlined
                  onClick={() => removeLabel(key)}
                  className="cursor-pointer ml-[5px]"
                />
              )}
            </FlexBox>
          </Tag>
        ))}
      </FlexBox>
      {editable && (
        <div className="flex flex-row flex-wrap mt-[5px] w-full gap-[5px]">
          <AutoComplete
            popupMatchSelectWidth={false}
            options={Object.keys(labelOptions)
              .filter(
                (labelKey) =>
                  labelKey.toLowerCase().indexOf(newLabelKey.toLowerCase()) !==
                  -1,
              )
              .map((labelKey) => ({
                label: labelKey,
                value: labelKey,
              }))}
            className="flex-1"
            placeholder="key"
            value={newLabelKey}
            onChange={(value) => setNewLabelKey(value)}
          />
          <AutoComplete
            popupMatchSelectWidth={false}
            options={
              newLabelKey in labelOptions
                ? (labelOptions[newLabelKey] ?? [])
                    .filter(
                      (labelValue) =>
                        labelValue
                          .toLowerCase()
                          .indexOf(newLabelValue.toLowerCase()) !== -1,
                    )
                    .map((labelValue) => ({
                      label: labelValue,
                      value: labelValue,
                    }))
                : []
            }
            className="flex-1"
            placeholder="value"
            value={newLabelValue}
            onChange={(value) => setNewLabelValue(value)}
          />
          <Button
            type="primary"
            onClick={addLabel}
            disabled={!newLabelKey.trim()}
          >
            Add
          </Button>
        </div>
      )}
    </div>
  );
};

export default LabelList;
