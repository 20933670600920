import { Divider, Typography } from 'antd';
import type { DefaultOptionType } from 'antd/es/select';
import {
  type IndustryServices,
  IndustryServicesSchema,
  InspectionCatalogMetadataType,
} from '../schemas';
import { createCodeEditorFormItem } from './shared/CodeEditorFormItem';
import FormList from './shared/FormList';
import { objectsToStrings, stringsToObjects } from './shared/transformers';

export interface IndustryServicesFormValues {
  elevator: {
    defectCatalogs: string[];
    noticeCatalogs: string[];
  };
}

const IndustryServicesSubform = ({ isEditing }: { isEditing: boolean }) => (
  <>
    <Divider dashed>
      <Typography.Text>Aufzug</Typography.Text>
    </Divider>
    <FormList
      InputComponent={DefectCatalogsCodeEditor}
      isEditing={isEditing}
      label="Mangelkatalog"
      path={industryServicesElevatorDefectCatalogsPath}
    />
    <FormList
      InputComponent={NoticeCatalogsCodeEditor}
      isEditing={isEditing}
      label="Hinweiskatalog"
      path={industryServicesElevatorNoticeCatalogsPath}
    />
  </>
);

const DefectCatalogsCodeEditor = createCodeEditorFormItem(
  IndustryServicesSchema.shape.elevator.shape.defectCatalogs.element,
);
const NoticeCatalogsCodeEditor = createCodeEditorFormItem(
  IndustryServicesSchema.shape.elevator.shape.noticeCatalogs.element,
);

export const industryServicesSelectOption: DefaultOptionType = {
  label: 'Industriedienstleistungen',
  value: InspectionCatalogMetadataType.INDUSTRY_SERVICES,
};

export const industryServicesPath =
  InspectionCatalogMetadataType.INDUSTRY_SERVICES;
export const industryServicesElevatorPath = `${industryServicesPath}/elevator`;
export const industryServicesElevatorDefectCatalogsPath = `${industryServicesElevatorPath}/defectCatalogs`;
export const industryServicesElevatorNoticeCatalogsPath = `${industryServicesElevatorPath}/noticeCatalogs`;

export const industryServicesDefaultValues = {
  [industryServicesElevatorDefectCatalogsPath]: {
    id: '',
    downloadId: '',
    startDate: '2000-01-01',
    catalogVersion: '',
  } as IndustryServices['elevator']['defectCatalogs'][0],
  [industryServicesElevatorNoticeCatalogsPath]: {
    id: '',
    downloadId: '',
    startDate: '2000-01-01',
    catalogVersion: '',
  } as IndustryServices['elevator']['noticeCatalogs'][0],
};

export const industryServicesToIndustryServicesFormValues = (
  industryServices: IndustryServices,
): IndustryServicesFormValues => ({
  elevator: {
    defectCatalogs: objectsToStrings(industryServices.elevator.defectCatalogs),
    noticeCatalogs: objectsToStrings(industryServices.elevator.noticeCatalogs),
  },
});

export const industryServicesFormValuesToIndustryServices = (
  industryServicesFormValues: IndustryServicesFormValues,
): IndustryServices => ({
  type: InspectionCatalogMetadataType.INDUSTRY_SERVICES,
  elevator: {
    defectCatalogs: stringsToObjects(
      industryServicesFormValues.elevator.defectCatalogs,
    ),
    noticeCatalogs: stringsToObjects(
      industryServicesFormValues.elevator.noticeCatalogs,
    ),
  },
});

export default IndustryServicesSubform;
