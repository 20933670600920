import { useQueryClient } from '@tanstack/react-query';
import { useManagementApiFromContext } from 'components/scaffold/OrganizationView';
import useLoadAllPaginatedCursorQuery from 'hooks/useLoadAllPaginatedCursorQuery';
import type { ManagementDeviceV3Dto } from 'interfaces/managementDeviceV3';
import { useCallback, useMemo } from 'react';

const useManagementDevices = () => {
  const queryClient = useQueryClient();
  const { getDevices, organization } = useManagementApiFromContext();

  const { data, ...query } = useLoadAllPaginatedCursorQuery({
    queryKey: ['management-devices', organization],
    queryFn: getDevices,
  });

  const devices = useMemo(() => {
    if (!data) return [];
    return data.sort((a: ManagementDeviceV3Dto, b: ManagementDeviceV3Dto) => {
      if (a.lastSeenAt === b.lastSeenAt) return 0;
      if (a.lastSeenAt === null) return 1;
      if (b.lastSeenAt === null) return -1;
      return a.lastSeenAt > b.lastSeenAt ? -1 : 1;
    });
  }, [data]);

  const invalidateDevices = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ['management-devices', organization],
    });
  }, [queryClient, organization]);

  return {
    devices,
    invalidateDevices,
    ...query,
  };
};

export default useManagementDevices;
