import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, message, Modal } from 'antd';
import KeyValueForm from 'components/common/KeyValueForm';
import { useManagementApiFromContext } from 'components/scaffold/OrganizationView';
import type { ManagementDeviceActivationRequest } from 'interfaces/managementActivationRequest';
import React from 'react';
import { toBaseError } from 'utlis/BaseError';
import { getLabelsDiff } from 'utlis/getLabelsDiff';

interface Props {
  request: ManagementDeviceActivationRequest;
  onClose?: () => void;
  onEdited?: () => void;
}

interface FormData {
  labels: Record<string, string>;
}

const ManagementDeviceActivationRequestEditModal: React.FC<Props> = ({
  request,
  onClose,
  onEdited,
}) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm<FormData>();
  const { patchDeviceActivationRequestLabels } = useManagementApiFromContext();
  const { mutate: onSubmit, isPending } = useMutation({
    mutationKey: ['management-device-activation-request', request.id],
    mutationFn: async (data: FormData) => {
      await patchDeviceActivationRequestLabels(
        request.id,
        getLabelsDiff(request.labels, data.labels),
      );
      return {
        ...request,
        labels: data.labels,
      };
    },
    onSuccess: () => {
      onEdited?.();
      queryClient.invalidateQueries({
        queryKey: ['management-device-activation-requests'],
      });
      message.success('Aktivierungsanfrage wurde bearbeitet.');
    },
    onError: (error) => {
      const baseError = toBaseError(error);
      message.error(
        `Aktivierungsanfrage konnte nicht bearbeitet werden: ${baseError.message}`,
      );
    },
  });

  return (
    <Modal
      title="Aktivierungsanfrage bearbeiten"
      open={true}
      onCancel={onClose}
      onOk={form.submit}
      okText="Speichern"
      width={900}
      confirmLoading={isPending}
    >
      <Form<FormData>
        form={form}
        onFinish={onSubmit}
        initialValues={{
          labels: request.labels,
        }}
      >
        <Form.Item name="labels" noStyle>
          <KeyValueForm />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ManagementDeviceActivationRequestEditModal;
