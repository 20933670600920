import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';
import { IntegrationType } from 'components/seniorcare/SeniorCareIntegrationConfig';
import { getRequiredEnv } from 'config';
import type { PaginationQuery } from 'interfaces/PaginationQuery';
import type {
  SeniorCareAdminUser,
  SeniorCareManagementUser,
} from 'interfaces/seniorcare/seniorCareUser';
import { useCallback } from 'react';
import {
  deleteSeniorCareAdminUsers,
  deleteSeniorCareOrganizationsUsers,
  readSeniorCareAdminUsers,
  readSeniorCareIntegrationAreas,
  readSeniorCareOrganizationsUsers,
  writeSeniorCareAdminUsers,
  writeSeniorCareOrganizationsUsers,
} from './seniorCareApiScopes';

const audience = getRequiredEnv('VITE_AUTH0_AUDIENCE');

export const seniorCareApi = Axios.create({
  baseURL: getRequiredEnv('VITE_SENIOR_CARE_API_URL'),
});

export interface UpdateSeniorCareUserDto {
  firstname?: string;
  lastname?: string;
  metadata?: Record<string, string>;
}

interface BaseGetIntegrationAreasRequest<T extends IntegrationType> {
  type: T;
  baseUrl: string;
  credentialsId: string;
  credentialsSecret: string;
}

export interface GetIntegrationAreasRequestVivendi
  extends BaseGetIntegrationAreasRequest<IntegrationType.VIVENDI> {
  useWindowsLogin: boolean;
}

export interface GetIntegrationAreasRequestDan
  extends BaseGetIntegrationAreasRequest<IntegrationType.DAN> {}

export enum StandardizedClientAuthMethod {
  BASIC = 'Basic',
  POST = 'Post',
}

export interface GetIntegrationAreasRequestStandardized
  extends BaseGetIntegrationAreasRequest<IntegrationType.STANDARDIZED> {
  tokenUrl: string;
  clientAuthMethod: StandardizedClientAuthMethod;
}

export type GetIntegrationAreasRequest =
  | GetIntegrationAreasRequestVivendi
  | GetIntegrationAreasRequestDan
  | GetIntegrationAreasRequestStandardized;

export interface SeniorCareIntegrationArea {
  id: string;
  name: string;
  parentId: string | null;
}

export const useSeniorCareApi = () => {
  const { getAccessTokenSilently } = useAuth0();
  const getAccessToken = useCallback(
    async (...scopes: string[]) =>
      await getAccessTokenSilently({
        authorizationParams: {
          audience,
          scope: scopes.join(' '),
        },
      }),
    [getAccessTokenSilently],
  );

  const getManagementSeniorCareUsersV2 = useCallback(
    async (
      paginationQuery: PaginationQuery,
    ): Promise<SeniorCareManagementUser[]> => {
      const accessToken = await getAccessToken(
        readSeniorCareOrganizationsUsers,
      );

      const { data } = await seniorCareApi.get('/v2/management/users', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: paginationQuery,
      });

      return data;
    },
    [getAccessToken],
  );

  const deleteManagementSeniorCareUser = useCallback(
    async (userId: string) => {
      const accessToken = await getAccessToken(
        deleteSeniorCareOrganizationsUsers,
      );

      await seniorCareApi.delete(`/v2/management/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
    [getAccessToken],
  );

  const updateManagementSeniorCareUser = useCallback(
    async (userId: string, updateData: UpdateSeniorCareUserDto) => {
      const accessToken = await getAccessToken(
        writeSeniorCareOrganizationsUsers,
      );

      await seniorCareApi.put(`/v1/management/users/${userId}`, updateData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
    [getAccessToken],
  );

  const getAdminSeniorCareUsersV2 = useCallback(
    async (
      paginationQuery: PaginationQuery,
      organization?: string,
    ): Promise<SeniorCareAdminUser[]> => {
      const accessToken = await getAccessToken(readSeniorCareAdminUsers);

      const { data } = await seniorCareApi.get('/v2/admin/users', {
        params: {
          organization,
          ...paginationQuery,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return data;
    },
    [getAccessToken],
  );

  const deleteAdminSeniorCareUser = useCallback(
    async (userId: string) => {
      const accessToken = await getAccessToken(deleteSeniorCareAdminUsers);

      await seniorCareApi.delete(`/v2/admin/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
    [getAccessToken],
  );

  const updateAdminSeniorCareUser = useCallback(
    async (userId: string, updateData: UpdateSeniorCareUserDto) => {
      const accessToken = await getAccessToken(writeSeniorCareAdminUsers);

      await seniorCareApi.put(`/v2/admin/users/${userId}`, updateData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
    [getAccessToken],
  );

  const getIntegrationAreas = useCallback(
    async (
      request: GetIntegrationAreasRequest,
    ): Promise<SeniorCareIntegrationArea[]> => {
      const accessToken = await getAccessToken(readSeniorCareIntegrationAreas);

      const response = await seniorCareApi.post<SeniorCareIntegrationArea[]>(
        '/v1/integration/areas',
        request,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      return response.data;
    },
    [getAccessToken],
  );

  return {
    getManagementSeniorCareUsersV2,
    updateManagementSeniorCareUser,
    deleteManagementSeniorCareUser,
    getAdminSeniorCareUsersV2,
    updateAdminSeniorCareUser,
    deleteAdminSeniorCareUser,
    getIntegrationAreas,
  };
};
