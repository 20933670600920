import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Divider, Form, Input, message, Modal, Select } from 'antd';
import LabelsFormList, {
  convertFromFormList,
} from 'components/common/LabelsFormList';
import { useAvailableDeviceSelectors } from 'components/device-configuration/DeviceConfigurationHelper';
import { useManagementApiFromContext } from 'components/scaffold/OrganizationView';
import SeniorCareDeviceConfigurationForm from 'components/seniorcare/SeniorCareDeviceConfigurationAssignment';
import { seniorCareAppDeviceRole } from 'defaults/deviceRoles';
import {
  deviceConfigurationKey,
  seniorCareDeviceManagementIntegrationConfigurationKey,
} from 'deviceMetadataKeys';
import useAuthContext from 'hooks/auth/useAuthContext';
import useManagementDeviceConfigurations from 'hooks/useManagementDeviceConfigurations';
import {
  type ManagementDevicePost,
  type ManagementDevicePostResponse,
} from 'interfaces/device';
import { useAtom } from 'jotai';
import { deviceDisplayNameLabel } from 'labels';
import _ from 'lodash';
import React from 'react';
import { useManagementPathOrganization } from 'router';
import { isUserAdvancedAtom } from 'state/state';
import { useSeniorCareLastUsedDeviceConfigs } from 'state/useSeniorCareLastUsedDeviceConfigs';
import { toBaseError } from 'utlis/BaseError';

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  onCreated?: (device: ManagementDevicePostResponse) => void;
  isSeniorCare?: boolean;
}

interface FormData {
  deviceName: string;
  organization: string;
  role: string;
  labels: { key: string; value: string }[];
  integration?: string;
  deviceconfig?: string;
}

const { Item } = Form;

const ManagementDeviceCreateModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onCreated,
  isSeniorCare = false,
}) => {
  const { postDevice } = useManagementApiFromContext();
  const queryClient = useQueryClient();
  const organization = useManagementPathOrganization();
  const { allowedDeviceRoles } = useAuthContext();
  const {
    setLastUsedDeviceConfigSelector,
    lastUsedDeviceConfigSelector,
    setLastUsedIntegrationConfigSelector,
    lastUsedIntegrationConfigSelector,
  } = useSeniorCareLastUsedDeviceConfigs(organization);
  const availableDeviceSelectorLabels = useAvailableDeviceSelectors();

  const [isUserAdvanced] = useAtom(isUserAdvancedAtom);

  const { data: deviceConfigurations } = useManagementDeviceConfigurations();

  const { mutate: onSubmit, isPending: isUpdating } = useMutation({
    mutationFn: async (
      data: FormData,
    ): Promise<ManagementDevicePostResponse> => {
      const devicePost: ManagementDevicePost = {
        role: data.role,
        labels: {
          ...convertFromFormList(data.labels),
          ...(data.role === seniorCareAppDeviceRole
            ? {
                [seniorCareDeviceManagementIntegrationConfigurationKey]:
                  data.integration,
                [deviceConfigurationKey]: data.deviceconfig,
              }
            : {}),
          [deviceDisplayNameLabel]: data.deviceName,
        },
      };
      const device = await postDevice(devicePost);

      if (data.role === seniorCareAppDeviceRole) {
        if (data.deviceconfig !== undefined) {
          setLastUsedDeviceConfigSelector(data.deviceconfig);
        }
        if (data.integration !== undefined) {
          setLastUsedIntegrationConfigSelector(data.integration);
        }
      }

      return device;
    },
    onSuccess: (device) => {
      queryClient.invalidateQueries({ queryKey: ['management-devices'] });
      message.success('Gerät wurde angelegt');
      form.resetFields();
      onCreated?.(device);
      onClose?.();
    },
    onError: (error) => {
      const baseError = toBaseError(error);
      message.error(`Gerät konnte nicht angelegt werden: ${baseError.message}`);
    },
  });

  const [form] = Form.useForm<FormData>();

  return (
    <Modal
      title="Gerät anlegen"
      open={isOpen}
      onCancel={onClose}
      onOk={form.submit}
      width={800}
      okText="Gerät anlegen"
      cancelText="Abbrechen"
      confirmLoading={isUpdating}
    >
      <Form form={form} onFinish={onSubmit}>
        <Item name="organization" label="Organisation" hidden>
          {organization}
        </Item>

        <Item
          labelCol={{ span: 6 }}
          name="role"
          label="Rolle"
          rules={[
            {
              required: true,
              message: 'Bitte wählen Sie eine Rolle aus',
            },
          ]}
          initialValue={isSeniorCare ? seniorCareAppDeviceRole : undefined}
          hidden={isSeniorCare}
        >
          <Select
            defaultActiveFirstOption={false}
            showSearch={true}
            filterOption={true}
            placeholder="Rolle auswählen"
            optionFilterProp="label"
            options={_.sortBy(allowedDeviceRoles, 'name').map((roleOption) => ({
              label: roleOption,
              value: roleOption,
            }))}
            disabled={isSeniorCare}
          />
        </Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.role !== currentValues.role
          }
        >
          {({ getFieldValue }) =>
            getFieldValue('role') === seniorCareAppDeviceRole ? (
              <SeniorCareDeviceConfigurationForm
                deviceConfigurations={deviceConfigurations ?? []}
                initialDeviceConfig={lastUsedDeviceConfigSelector ?? undefined}
                initialIntegrationConfig={
                  lastUsedIntegrationConfigSelector ?? undefined
                }
              />
            ) : null
          }
        </Form.Item>

        <Item
          name="deviceName"
          label="Name"
          labelAlign="left"
          labelCol={{ span: 6 }}
        >
          <Input placeholder="Gerätename" />
        </Item>

        {isUserAdvanced && (
          <div>
            <Divider plain>Labels</Divider>
            <LabelsFormList
              name="labels"
              label="Label"
              labelOptions={availableDeviceSelectorLabels}
            />
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default ManagementDeviceCreateModal;
