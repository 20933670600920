import {
  GlobalOutlined,
  LoadingOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Collapse, Skeleton, Table, Tag, Tooltip } from 'antd';
import type { GlobalDeviceConfiguration } from 'interfaces/adminGlobalDeviceConfig';
import type { OrganizationDeviceConfiguration } from 'interfaces/adminOrganizationDeviceConfig';
import type { ManagementDeviceConfiguration } from 'interfaces/managementDeviceConfiguration';

interface Props {
  config:
    | OrganizationDeviceConfiguration
    | GlobalDeviceConfiguration
    | ManagementDeviceConfiguration;
  isLoading?: boolean;
  isGlobal?: boolean;
}

const DeviceConfigurationCollapsible = ({
  config,
  isLoading,
  isGlobal = false,
}: Props) => {
  // typeguard for global config if isGlobal is true
  const isGlobalConfig = (
    config: OrganizationDeviceConfiguration | GlobalDeviceConfiguration,
  ): config is GlobalDeviceConfiguration => {
    return isGlobal;
  };

  return (
    <Collapse>
      <Collapse.Panel
        key="device-config"
        header={
          <div className="flex justify-between items-center w-full">
            {isLoading ? (
              <LoadingOutlined className="animate-spin" />
            ) : (
              <>
                <div className="flex items-center gap-2">
                  {isGlobal && (
                    <Tooltip title="Globale Gerätekonfiguration">
                      <GlobalOutlined />
                    </Tooltip>
                  )}
                  <SettingOutlined />
                  <p className="font-semibold text-base pl-1">{config.name}</p>
                </div>
                <div>
                  <Tag>
                    {config.deviceSelector.matchLabels?.length} Label
                    {config.deviceSelector.matchLabels?.length === 1 ? '' : 's'}
                  </Tag>
                  <Tag>
                    {config.deviceSelector.matchRole?.in?.length} Rolle
                    {config.deviceSelector.matchRole?.in?.length === 1
                      ? ''
                      : 'n'}
                  </Tag>
                  <Tag>
                    {Object.keys(config.properties).length} Geräteeigenschaft
                    {Object.keys(config.properties).length === 1 ? '' : 'en'}
                  </Tag>
                </div>
              </>
            )}
          </div>
        }
      >
        {isLoading ? (
          <Skeleton active />
        ) : (
          <div className="flex flex-col gap-y-5">
            {(config.deviceSelector.matchLabels || []).length > 0 && (
              <Table
                pagination={false}
                dataSource={config.deviceSelector.matchLabels}
                columns={[
                  { title: 'Label', dataIndex: 'key' },
                  { title: 'Operator', dataIndex: 'operator' },
                  { title: 'Wert', dataIndex: 'values' },
                ]}
              />
            )}
            {config.deviceSelector.matchRole?.in?.length ||
            config.deviceSelector.matchRole?.notIn?.length ? (
              <div>
                <p className="font-semibold text-base">
                  {config.deviceSelector.matchRole.in?.length
                    ? 'Inkludierte Rollen:'
                    : 'Exkludierte Rollen:'}
                </p>
                <div className="flex flex-wrap gap-2.5 mt-2.5">
                  {config.deviceSelector.matchRole.in?.map((role) => (
                    <Tag key={role}>{role}</Tag>
                  ))}
                </div>
              </div>
            ) : null}
            {isGlobalConfig(config) &&
            (config.deviceSelector.matchOrganization?.in?.length !==
              undefined ||
              config.deviceSelector.matchOrganization?.notIn?.length !==
                undefined) ? (
              <div>
                {(() => {
                  switch (true) {
                    case config.deviceSelector.matchOrganization.in?.length !==
                      undefined &&
                      config.deviceSelector.matchOrganization.in?.length > 0:
                      return (
                        <>
                          <p className="font-semibold text-base">
                            Inkludierte Organisationen:
                          </p>
                          <div className="flex flex-wrap gap-2.5 mt-2.5">
                            {config.deviceSelector.matchOrganization.in?.map(
                              (org) => <Tag key={org}>{org}</Tag>,
                            )}
                          </div>
                        </>
                      );
                    case config.deviceSelector.matchOrganization.notIn
                      ?.length !== undefined &&
                      config.deviceSelector.matchOrganization.notIn?.length > 0:
                      return (
                        <>
                          <p className="font-semibold text-base">
                            Exkludierte Organisationen:
                          </p>
                          <div className="flex flex-wrap gap-2.5 mt-2.5">
                            {config.deviceSelector.matchOrganization.notIn?.map(
                              (org) => <Tag key={org}>{org}</Tag>,
                            )}
                          </div>
                        </>
                      );
                    default:
                      return (
                        <>
                          <p className="font-semibold text-base">
                            Organisationen:
                          </p>
                          <div className="flex flex-wrap gap-2.5 mt-2.5">
                            <Tag>Alle Organisationen werden berücksichtigt</Tag>
                          </div>
                        </>
                      );
                  }
                })()}
              </div>
            ) : null}
            {Object.keys(config.properties).length > 0 && (
              <div>
                <p className="font-semibold text-base">Geräteeigenschaften:</p>
                <div className="flex flex-wrap gap-2.5 mt-2.5">
                  {Object.keys(config.properties).map((property) => (
                    <Tag key={property}>{property}</Tag>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};

export default DeviceConfigurationCollapsible;
