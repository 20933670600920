import type { DefaultOptionType } from 'antd/es/select';
import {
  InspectionCatalogMetadataType,
  type VehicleInspection,
  VehicleInspectionSchema,
} from '../schemas';
import { createCodeEditorFormItem } from './shared/CodeEditorFormItem';
import FormList from './shared/FormList';
import { objectsToStrings, stringsToObjects } from './shared/transformers';

export interface VehicleInspectionFormValues {
  defectCatalogs: string[];
  noticeCatalogs: string[];
}

const VehicleInspectionSubform = ({ isEditing }: { isEditing: boolean }) => (
  <>
    <FormList
      InputComponent={DefectCatalogsCodeEditor}
      isEditing={isEditing}
      label="Mangelkatalog"
      path={vehicleInspectionDefectCatalogsPath}
    />
    <FormList
      InputComponent={NoticeCatalogsCodeEditor}
      isEditing={isEditing}
      label="Hinweiskatalog"
      path={vehicleInspectionNoticeCatalogsPath}
    />
  </>
);

const DefectCatalogsCodeEditor = createCodeEditorFormItem(
  VehicleInspectionSchema.shape.defectCatalogs.element,
);
const NoticeCatalogsCodeEditor = createCodeEditorFormItem(
  VehicleInspectionSchema.shape.noticeCatalogs.element,
);

export const vehicleInspectionSelectOption: DefaultOptionType = {
  label: 'Fahrzeuginspektion',
  value: InspectionCatalogMetadataType.VEHICLE_INSPECTION,
};

export const vehicleInspectionPath =
  InspectionCatalogMetadataType.VEHICLE_INSPECTION;
export const vehicleInspectionDefectCatalogsPath = `${vehicleInspectionPath}/defectCatalogs`;
export const vehicleInspectionNoticeCatalogsPath = `${vehicleInspectionPath}/noticeCatalogs`;

export const vehicleInspectionDefaultValues = {
  [vehicleInspectionDefectCatalogsPath]: {
    id: '',
    downloadId: '',
    startDate: '2000-01-01',
    catalogVersions: {
      hu: '',
      specialHu: null,
      sp: null,
      specialFtu: null,
      specialUvv: null,
      tmp: null,
    },
  } as VehicleInspection['defectCatalogs'][0],
  [vehicleInspectionNoticeCatalogsPath]: {
    id: '',
    downloadId: '',
    startDate: '2000-01-01',
    catalogVersions: {
      hu: '',
      sp: null,
      specialFtu: null,
    },
  } as VehicleInspection['noticeCatalogs'][0],
};

export const vehicleInspectionToVehicleInspectionFormValues = (
  vehicleInspection: VehicleInspection,
): VehicleInspectionFormValues => ({
  defectCatalogs: objectsToStrings(vehicleInspection.defectCatalogs),
  noticeCatalogs: objectsToStrings(vehicleInspection.noticeCatalogs),
});

export const vehicleInspectionFormValuesToVehicleInspection = (
  vehicleInspectionFormValues: VehicleInspectionFormValues,
): VehicleInspection => ({
  type: InspectionCatalogMetadataType.VEHICLE_INSPECTION,
  defectCatalogs: stringsToObjects(vehicleInspectionFormValues.defectCatalogs),
  noticeCatalogs: stringsToObjects(vehicleInspectionFormValues.noticeCatalogs),
});

export default VehicleInspectionSubform;
