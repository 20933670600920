import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  Form,
  Input,
  Radio,
  Select,
  Switch,
} from 'antd';
import { StandardizedClientAuthMethod } from 'api/seniorcare/useSeniorCareApi';
import { FlexBox } from 'components/Helpers';
import {
  integrationConfigDeviceProperty,
  IntegrationType,
  SeniorCareIntegrationConfigSchema,
  type SeniorCareIntegrationConfigV2,
} from 'components/seniorcare/SeniorCareIntegrationConfig';
import { useMemo } from 'react';
import { UnreachableCaseError } from 'ts-essentials';
import { validateJsonProperty, validateOrThrow } from 'utlis/validationUtil';
import {
  StandardizedApiAuthenticationConfigSchema,
  StandardizedUserAuthenticationOIDCConfigSchema,
} from './SeniorCareIntegrationAreasFeature';

interface Props {
  initialProperties?: Record<string, string>;
  onSubmit: (config: Record<string, string>) => void;
}

type FormData = SeniorCareIntegrationConfigV2 & {
  vivendiUseWindowsLogin?: boolean;
  vivendiDisableWindowsLoginForSyncUser?: boolean;
} & {
  tokenUrl?: string;
  clientAuthMethod?: StandardizedClientAuthMethod;
  oidcClientId?: string;
  oidcClientSecret?: string;
  oidcClientAuthMethod?: StandardizedClientAuthMethod;
  discoveryUri?: string;
  scopes?: string[];
  isRefreshTokenRotationEnabled?: boolean;
  endSessionOnLogout?: boolean;
  useRefreshTokens?: boolean;
  forceReAuthenticate?: boolean;
  skipEmailVerifiedCheck?: boolean;
  responseMode?: string | null;
  additionalAuthorizationParameters?: { key: string; value: string }[] | null;
};

const SeniorCareIntegrationConfigForm: React.FC<Props> = ({
  initialProperties = {},
  onSubmit,
}) => {
  const formId = 'senior-care-integration-config-feature';
  const [form] = Form.useForm<FormData>();

  const [integrationConfig] = useMemo(
    () =>
      validateJsonProperty(
        initialProperties[integrationConfigDeviceProperty],
        SeniorCareIntegrationConfigSchema,
      ),
    [initialProperties],
  );

  const [standardizedApiAuthenticationConfig] = useMemo(
    () =>
      integrationConfig?.type === IntegrationType.STANDARDIZED
        ? validateJsonProperty(
            initialProperties.STANDARDIZED_API_AUTHENTICATION_CONFIG,
            StandardizedApiAuthenticationConfigSchema,
          )
        : [null, null],
    [initialProperties, integrationConfig],
  );

  const [standardizedOIDCConfig] = useMemo(
    () =>
      integrationConfig?.type === IntegrationType.STANDARDIZED
        ? validateJsonProperty(
            initialProperties.STANDARDIZED_API_USER_AUTHENTICATION_OIDC_CONFIG,
            StandardizedUserAuthenticationOIDCConfigSchema,
          )
        : [null, null],
    [initialProperties, integrationConfig],
  );

  return (
    <Form<FormData>
      id={formId}
      form={form}
      labelAlign="left"
      labelCol={{ span: 6 }}
      labelWrap={true}
      onFinish={(formData) => {
        const integrationConfig = validateOrThrow(
          {
            type: formData.type,
            baseUrl: formData.baseUrl,
            credentialsId: formData.credentialsId,
            credentialsSecret: formData.credentialsSecret,
          },
          SeniorCareIntegrationConfigSchema,
        );

        onSubmit({
          [integrationConfigDeviceProperty]: JSON.stringify(integrationConfig),
          ...((() => {
            switch (integrationConfig.type) {
              case IntegrationType.STANDARDIZED: {
                const standardizedApiAuthenticationConfig = validateOrThrow(
                  {
                    tokenUrl: formData.tokenUrl,
                    clientAuthMethod: formData.clientAuthMethod,
                  },
                  StandardizedApiAuthenticationConfigSchema,
                );

                const standardizedOIDCConfig = validateOrThrow(
                  {
                    clientId: formData.oidcClientId,
                    ...(formData.oidcClientSecret !== '' && {
                      clientSecret: formData.oidcClientSecret,
                    }),
                    clientAuthMethod: formData.oidcClientAuthMethod,
                    discoveryUri: formData.discoveryUri,
                    isRefreshTokenRotationEnabled:
                      formData.isRefreshTokenRotationEnabled,
                    endSessionOnLogout: formData.endSessionOnLogout,
                    scopes: formData.scopes,
                    responseMode: formData.responseMode,
                    skipEmailVerifiedCheck: formData.skipEmailVerifiedCheck,
                    useRefreshTokens: formData.useRefreshTokens,
                    forceReAuthenticate: formData.forceReAuthenticate,
                    additionalAuthorizationParameters:
                      formData.additionalAuthorizationParameters !== null &&
                      formData.additionalAuthorizationParameters !== undefined
                        ? Object.fromEntries(
                            formData.additionalAuthorizationParameters.map(
                              (param) => [param.key, param.value],
                            ),
                          )
                        : formData.additionalAuthorizationParameters,
                  },
                  StandardizedUserAuthenticationOIDCConfigSchema,
                );

                return {
                  STANDARDIZED_API_AUTHENTICATION_CONFIG: JSON.stringify(
                    standardizedApiAuthenticationConfig,
                  ),
                  STANDARDIZED_API_USER_AUTHENTICATION_OIDC_CONFIG:
                    JSON.stringify(standardizedOIDCConfig),
                };
              }
              case IntegrationType.VIVENDI:
                return {
                  USE_VIVENDI_WINDOWS_LOGIN: formData.vivendiUseWindowsLogin
                    ? 'true'
                    : 'false',
                  DISABLE_SYNC_USER_VIVENDI_WINDOWS_LOGIN:
                    formData.vivendiDisableWindowsLoginForSyncUser
                      ? 'true'
                      : 'false',
                };
              case IntegrationType.DAN:
                return {};
              default:
                throw new UnreachableCaseError(integrationConfig.type);
            }
          })() as any),
        });
      }}
      initialValues={{
        ...(integrationConfig !== null
          ? integrationConfig
          : {
              type: '',
              baseUrl: '',
              credentialsId: '',
              credentialsSecret: '',
            }),
        ...(standardizedApiAuthenticationConfig ?? {}),
        ...(standardizedOIDCConfig ?? {
          responseMode: null,
        }),
      }}
    >
      <Form.Item
        label="Integrations-Typ"
        name="type"
        rules={[{ required: true }]}
      >
        <Select
          options={Object.values(IntegrationType).map((type) => ({
            label: type,
            value: type,
          }))}
        />
      </Form.Item>
      <Form.Item
        label="Integrations-URL"
        name="baseUrl"
        rules={[
          {
            required: true,
            type: 'url',
            message: 'Bitte geben Sie eine gültige URL ein.',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.type !== currentValues.type
        }
      >
        {({ getFieldValue }) => {
          const integrationType: IntegrationType = getFieldValue('type');

          switch (integrationType) {
            case IntegrationType.STANDARDIZED:
              return (
                <>
                  <Divider>Sync User (Read Access)</Divider>
                  <Form.Item
                    label="Client ID"
                    name="credentialsId"
                    rules={[
                      {
                        required: true,
                        message: 'Bitte geben Sie die Client ID ein.',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Client Secret"
                    name="credentialsSecret"
                    rules={[
                      {
                        required: true,
                        message: 'Bitte geben Sie das Client Secret ein.',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Token URL"
                    name="tokenUrl"
                    rules={[
                      {
                        required: true,
                        type: 'url',
                        message: 'Bitte geben Sie eine gültige Token URL ein.',
                      },
                    ]}
                    initialValue={
                      standardizedApiAuthenticationConfig?.tokenUrl ?? ''
                    }
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Client Auth Method"
                    name="clientAuthMethod"
                    rules={[
                      {
                        required: true,
                        message: 'Bitte geben Sie die Client Auth Methode ein.',
                      },
                    ]}
                    initialValue={
                      standardizedApiAuthenticationConfig?.clientAuthMethod ??
                      StandardizedClientAuthMethod.BASIC
                    }
                  >
                    <Radio.Group>
                      <Radio value={StandardizedClientAuthMethod.BASIC}>
                        BASIC
                      </Radio>
                      <Radio value={StandardizedClientAuthMethod.POST}>
                        POST
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Divider>User Auth OIDC Config</Divider>
                  <Form.Item
                    label="Client ID"
                    name="oidcClientId"
                    tooltip="Client ID of the application."
                    rules={[
                      {
                        required: true,
                        message: 'Bitte geben Sie die Client ID ein.',
                      },
                    ]}
                    initialValue={standardizedOIDCConfig?.clientId ?? ''}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Client Secret"
                    name="oidcClientSecret"
                    tooltip="Client secret of the application. This is only required for the authorization code flow without PKCE."
                    initialValue={standardizedOIDCConfig?.clientSecret ?? ''}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Client Auth Method"
                    name="oidcClientAuthMethod"
                    tooltip="The method to use for client authentication. This is only required if the client secret is used. Only effective on Android."
                    rules={[
                      {
                        required: getFieldValue('oidcClientSecret')
                          ? true
                          : false,
                        message: 'Bitte geben Sie die Client Auth Methode ein.',
                      },
                    ]}
                    initialValue={
                      standardizedOIDCConfig?.clientAuthMethod ??
                      StandardizedClientAuthMethod.BASIC
                    }
                  >
                    <Radio.Group>
                      <Radio value={StandardizedClientAuthMethod.BASIC}>
                        BASIC
                      </Radio>
                      <Radio value={StandardizedClientAuthMethod.POST}>
                        POST
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label="Discovery URI"
                    name="discoveryUri"
                    tooltip="The discovery URI of the OpenID Connect provider."
                    rules={[
                      {
                        required: true,
                        type: 'url',
                        message:
                          'Bitte geben Sie eine gültige Discovery URI ein.',
                      },
                    ]}
                    initialValue={standardizedOIDCConfig?.discoveryUri ?? ''}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Scopes"
                    name="scopes"
                    tooltip={
                      'The list of scopes to request. If refresh tokens are requested, this must also include "offline_access".'
                    }
                    rules={[
                      {
                        required: true,
                        type: 'array',
                        message: 'Bitte geben Sie mindestens einen Scope ein.',
                      },
                    ]}
                    initialValue={standardizedOIDCConfig?.scopes ?? []}
                  >
                    <Select mode="tags" />
                  </Form.Item>
                  <Form.Item
                    label="Refresh Token Rotation"
                    name="isRefreshTokenRotationEnabled"
                    tooltip="If true, the refresh token will be rotated on every token request."
                    initialValue={
                      standardizedOIDCConfig?.isRefreshTokenRotationEnabled ??
                      false
                    }
                  >
                    <Switch />
                  </Form.Item>

                  <Form.Item
                    label="End Session on Logout"
                    name="endSessionOnLogout"
                    tooltip="If true, the user will be logged out of the identity provider when the user logs out of the app."
                    initialValue={
                      standardizedOIDCConfig?.endSessionOnLogout ?? false
                    }
                  >
                    <Switch />
                  </Form.Item>
                  <Collapse
                    style={{ marginBottom: '20px' }}
                    size="small"
                    items={[
                      {
                        key: '1',
                        label: 'Advanced Settings',
                        children: (
                          <>
                            <Form.Item
                              label="Skip E-Mail Verified Check"
                              name="skipEmailVerifiedCheck"
                              tooltip="If true, the `email_verified` claim of the ID token will not be checked."
                              initialValue={
                                standardizedOIDCConfig?.skipEmailVerifiedCheck ??
                                false
                              }
                            >
                              <Switch />
                            </Form.Item>

                            <Form.Item
                              label="Use Refresh Tokens"
                              name="useRefreshTokens"
                              tooltip="If true, refresh tokens will be used to refresh the access token."
                              initialValue={
                                standardizedOIDCConfig?.useRefreshTokens ?? true
                              }
                            >
                              <Switch />
                            </Form.Item>
                            <Form.Item
                              label="Force Re-Authenticate"
                              name="forceReAuthenticate"
                              tooltip="If true, the user will need to re-authenticate even if there is a session in the authorization server."
                              initialValue={
                                standardizedOIDCConfig?.forceReAuthenticate ??
                                false
                              }
                            >
                              <Switch />
                            </Form.Item>

                            <Form.Item
                              label="Additional Authorization Parameters"
                              tooltip="Additional parameters to add to the authorization request."
                              shouldUpdate={(prevValues, currentValues) =>
                                prevValues.additionalAuthorizationParameters !==
                                currentValues.additionalAuthorizationParameters
                              }
                            >
                              {({ getFieldValue, setFieldsValue }) => {
                                const value =
                                  getFieldValue(
                                    'additionalAuthorizationParameters',
                                  ) ?? null;

                                return (
                                  <FlexBox withgap>
                                    <Checkbox
                                      value={value !== null}
                                      defaultChecked={value !== null}
                                      onChange={(e) => {
                                        setFieldsValue({
                                          additionalAuthorizationParameters: e
                                            .target.checked
                                            ? []
                                            : null,
                                        });
                                      }}
                                    />
                                    {value !== null && (
                                      <Form.List name="additionalAuthorizationParameters">
                                        {(fields, { add, remove }) => (
                                          <FlexBox direction="column" withgap>
                                            {fields.map((field) => (
                                              <FlexBox key={field.key} withgap>
                                                <Form.Item
                                                  noStyle
                                                  name={[field.name, 'key']}
                                                  rules={[{ required: true }]}
                                                >
                                                  <Input placeholder="Key" />
                                                </Form.Item>
                                                <Form.Item
                                                  noStyle
                                                  name={[field.name, 'value']}
                                                  rules={[{ required: true }]}
                                                >
                                                  <Input placeholder="Value" />
                                                </Form.Item>
                                                <Button
                                                  onClick={() =>
                                                    remove(field.name)
                                                  }
                                                  icon={<CloseOutlined />}
                                                  type="text"
                                                />
                                              </FlexBox>
                                            ))}
                                            <Button
                                              onClick={() => add()}
                                              icon={<PlusOutlined />}
                                            >
                                              Add Paramter
                                            </Button>
                                          </FlexBox>
                                        )}
                                      </Form.List>
                                    )}
                                  </FlexBox>
                                );
                              }}
                            </Form.Item>

                            <Form.Item
                              label="Response Mode"
                              tooltip="Specifies the response mode to be used for returning authorization response parameters from the authorization endpoint."
                              initialValue={
                                standardizedOIDCConfig?.responseMode ?? null
                              }
                              shouldUpdate={(prevValues, currentValues) =>
                                prevValues.responseMode !==
                                currentValues.responseMode
                              }
                            >
                              {({ getFieldValue, setFieldsValue }) => {
                                const value =
                                  getFieldValue('responseMode') ?? null;

                                return (
                                  <FlexBox withgap>
                                    <Checkbox
                                      value={value !== null}
                                      defaultChecked={value !== null}
                                      onChange={(e) => {
                                        setFieldsValue({
                                          responseMode: e.target.checked
                                            ? ''
                                            : null,
                                        });
                                      }}
                                    />
                                    {value !== null && (
                                      <Form.Item name="responseMode" noStyle>
                                        <Input placeholder="Response mode" />
                                      </Form.Item>
                                    )}
                                  </FlexBox>
                                );
                              }}
                            </Form.Item>
                          </>
                        ),
                      },
                    ]}
                  />
                </>
              );
            case IntegrationType.VIVENDI:
              return (
                <>
                  <Form.Item
                    label="Sync-Nutzer Benutzername"
                    name="credentialsId"
                    rules={[
                      {
                        required: true,
                        message:
                          'Bitte geben Sie den Sync-Nutzer Benutzername ein.',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Sync-Nutzer Passwort"
                    name="credentialsSecret"
                    rules={[
                      {
                        required: true,
                        message:
                          'Bitte geben Sie das Sync-Nutzer Passwort ein.',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Windows-Login"
                    name="vivendiUseWindowsLogin"
                    valuePropName="checked"
                    initialValue={
                      initialProperties['USE_VIVENDI_WINDOWS_LOGIN'] === 'true'
                    }
                  >
                    <Switch />
                  </Form.Item>

                  <Form.Item
                    name="vivendiDisableWindowsLoginForSyncUser"
                    noStyle
                    initialValue={
                      initialProperties[
                        'DISABLE_SYNC_USER_VIVENDI_WINDOWS_LOGIN'
                      ] === 'true'
                    }
                  />

                  <Form.Item
                    label="Sync-Nutzer Windows-Login"
                    valuePropName="checked"
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.vivendiUseWindowsLogin !==
                        currentValues.vivendiUseWindowsLogin ||
                      prevValues.vivendiDisableWindowsLoginForSyncUser !==
                        currentValues.vivendiDisableWindowsLoginForSyncUser
                    }
                  >
                    {({ getFieldValue, setFieldsValue }) => {
                      const useWindowsLogin = getFieldValue(
                        'vivendiUseWindowsLogin',
                      );
                      const disableWindowsLoginForSyncUser = getFieldValue(
                        'vivendiDisableWindowsLoginForSyncUser',
                      );

                      return (
                        <Switch
                          disabled={!useWindowsLogin}
                          checked={
                            useWindowsLogin && !disableWindowsLoginForSyncUser
                          }
                          onChange={(checked) => {
                            setFieldsValue({
                              vivendiDisableWindowsLoginForSyncUser: !checked,
                            });
                          }}
                        />
                      );
                    }}
                  </Form.Item>
                </>
              );
            case IntegrationType.DAN:
              return (
                <>
                  <Form.Item
                    label="Sync-Nutzer Benutzername"
                    name="credentialsId"
                    rules={[
                      {
                        required: true,
                        message:
                          'Bitte geben Sie den Sync-Nutzer Benutzername ein.',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Sync-Nutzer Passwort"
                    name="credentialsSecret"
                    rules={[
                      {
                        required: true,
                        message:
                          'Bitte geben Sie das Sync-Nutzer Passwort ein.',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </>
              );
            default:
              return null;
          }
        }}
      </Form.Item>
      <Button
        type="primary"
        key="submit"
        form={formId}
        onClick={() => form.submit()}
      >
        Speichern
      </Button>
    </Form>
  );
};

export default SeniorCareIntegrationConfigForm;
