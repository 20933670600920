import { Divider } from 'antd';
import Breadcrumb from 'components/common/Breadcrumb';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import ResourceIcons from 'components/common/resourceIcons';
import { DeviceMetadataStatistics } from 'components/views/statistics/metadata/DeviceMetadataStatistics';
import useManagementDevices from 'hooks/devices/useManagementDevices';
import { useFilteredDevices } from './filtering/useFilteredDevices';
import { DevicePropertiesStatistics } from './properties/DevicePropertiesStatistics';

const DeviceMetadataWhitelist = [
  'android/system-version',
  'android/device/manufacturer',
  'android/device/model',
  'android/app-version',
  'ios/app-version',
  'ios/system-version',
  'ios/device/model',
  'voize-app-senior-care/version',
  'voize-app-senior-care/release-track',
  'voize-sdk/version',
  'voize-app-tic/version',
];

export const OrganizationDeviceStatisticsView: React.FC = () => {
  const { devices } = useManagementDevices();
  const { filteredDevices, ui: filterUi } = useFilteredDevices(devices, {
    showSourceFilter: false,
    showOrganizationFilter: false,
    showActiveFilter: false,
    showIndustryFilter: false,
  });

  return (
    <>
      <Breadcrumb
        items={[
          { name: 'Statistiken', href: '/management/$organization/statistics' },
        ]}
      />
      <PageTitleBar title="Statistiken" Icon={ResourceIcons.Statistics} />
      <PageContentContainer>
        {filterUi}
        <Divider />
        <DeviceMetadataStatistics
          devices={filteredDevices}
          metadataWhitelist={DeviceMetadataWhitelist}
        />
        <Divider />
        <DevicePropertiesStatistics devices={filteredDevices} />
      </PageContentContainer>
    </>
  );
};
