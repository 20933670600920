import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';
import { getRequiredEnv } from 'config';
import type { ManagementDevicePost } from 'interfaces/device';
import type { ManagementDeviceActivationRequest } from 'interfaces/managementActivationRequest';
import type {
  ManagementDeviceConfiguration,
  PostManagementDeviceConfiguration,
  PutManagementDeviceConfiguration,
} from 'interfaces/managementDeviceConfiguration';
import type {
  ManagementCreateDeviceResponseV3Dto,
  ManagementDeviceV3Dto,
} from 'interfaces/managementDeviceV3';
import type { ManagementOrganization } from 'interfaces/organization';
import type { PaginationQuery } from 'interfaces/PaginationQuery';
import { useCallback } from 'react';
import {
  deleteOrganizationDeviceConfigurationsScope,
  deleteOrganizationDevicesScope,
  readOrganizationDeviceConfigurationsScope,
  readOrganizationDevicesScope,
  readOrganizationSelfScope,
  writeDevicesScope,
  writeOrganizationDeviceConfigurationsScope,
  writeOrganizationDevicesScope,
} from './deviceManagementApiScopes';

const audience = getRequiredEnv('VITE_AUTH0_AUDIENCE');

export const voizeDeviceManagementApi = Axios.create({
  baseURL: getRequiredEnv('VITE_DEVICE_MANAGEMENT_API_URL'),
});

export function useRawManagementApi() {
  const { getAccessTokenSilently } = useAuth0();
  const getAccessToken = useCallback(
    async (...scopes: string[]) =>
      await getAccessTokenSilently({
        authorizationParams: {
          audience,
          scope: scopes.join(' '),
        },
      }),
    [getAccessTokenSilently],
  );

  const getOrganization =
    useCallback(async (): Promise<ManagementOrganization> => {
      const accessToken = await getAccessToken(readOrganizationSelfScope);

      const { data } =
        await voizeDeviceManagementApi.get<ManagementOrganization>(
          '/v2/management/organizations/me',
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

      return data;
    }, [getAccessToken]);

  const getDevices = useCallback(
    async (
      paginationQuery: PaginationQuery,
    ): Promise<ManagementDeviceV3Dto[]> => {
      const accessToken = await getAccessToken(readOrganizationDevicesScope);

      const { data } = await voizeDeviceManagementApi.get<
        ManagementDeviceV3Dto[]
      >('/v3/management/devices', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: paginationQuery,
      });

      return data;
    },
    [getAccessToken],
  );

  const getDevice = useCallback(
    async (id: string): Promise<ManagementDeviceV3Dto> => {
      const accessToken = await getAccessToken(readOrganizationDevicesScope);

      const { data } =
        await voizeDeviceManagementApi.get<ManagementDeviceV3Dto>(
          `/v3/management/devices/${id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

      return data;
    },
    [getAccessToken],
  );

  const postDevice = useCallback(
    async (
      device: ManagementDevicePost,
    ): Promise<ManagementCreateDeviceResponseV3Dto> => {
      const accessToken = await getAccessToken(writeOrganizationDevicesScope);

      const { data } = await voizeDeviceManagementApi.post(
        `/v3/management/devices`,
        device,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      return data;
    },
    [getAccessToken],
  );

  const patchDeviceLabels = useCallback(
    async (
      id: string,
      labels: Record<string, string | null>,
    ): Promise<void> => {
      const accessToken = await getAccessToken(writeOrganizationDevicesScope);

      await voizeDeviceManagementApi.patch(
        `/v3/management/devices/${id}/labels`,
        labels,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
    [getAccessToken],
  );

  const deleteDevice = useCallback(
    async (id: string): Promise<void> => {
      const accessToken = await getAccessToken(deleteOrganizationDevicesScope);

      await voizeDeviceManagementApi.delete(`/v3/management/devices/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
    [getAccessToken],
  );

  const getDeviceActivationCode = useCallback(
    async (id: string): Promise<string | null> => {
      const accessToken = await getAccessToken(readOrganizationDevicesScope);

      const { data } = await voizeDeviceManagementApi.get<string>(
        `/v3/management/devices/${id}/activation-code`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      return data.length === 0 ? null : data;
    },
    [getAccessToken],
  );

  const resetDeviceSecret = useCallback(
    async (id: string): Promise<void> => {
      const accessToken = await getAccessToken(writeDevicesScope);

      await voizeDeviceManagementApi.post(
        `/v3/management/devices/${id}/reset-device-secret`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
    [getAccessToken],
  );

  const updateDeviceProperties = useCallback(
    async (id: string): Promise<void> => {
      // TODO currently not implemented
    },
    [],
  );

  const getDeviceActivationRequests = useCallback(async (): Promise<
    ManagementDeviceActivationRequest[]
  > => {
    const accessToken = await getAccessToken();

    const { data } = await voizeDeviceManagementApi.get<
      ManagementDeviceActivationRequest[]
    >(`/v1/management/device-activation-requests`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  }, [getAccessToken]);

  const postDeviceActivationRequestApprove = useCallback(
    async (
      deviceActivationRequestId: string,
      deviceRole: string,
      deviceLabels: Record<string, string>,
    ): Promise<void> => {
      const accessToken = await getAccessToken();

      await voizeDeviceManagementApi.post<void>(
        `/v1/management/device-activation-requests/${deviceActivationRequestId}/approve`,
        {
          deviceRole,
          deviceLabels,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
    [getAccessToken],
  );

  const deleteDeviceActivationRequest = useCallback(
    async (deviceActivationRequestId: string): Promise<void> => {
      const accessToken = await getAccessToken();

      await voizeDeviceManagementApi.delete<void>(
        `/v1/management/device-activation-requests/${deviceActivationRequestId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
    [getAccessToken],
  );

  const patchDeviceActivationRequestLabels = useCallback(
    async (
      deviceActivationRequestId: string,
      labels: Record<string, string | null>,
    ): Promise<void> => {
      const accessToken = await getAccessToken();
      await voizeDeviceManagementApi.patch<void>(
        `/v1/management/device-activation-requests/${deviceActivationRequestId}/labels`,
        labels,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
    [getAccessToken],
  );

  const getManagementDeviceConfigurations = useCallback(async (): Promise<
    ManagementDeviceConfiguration[]
  > => {
    const accessToken = await getAccessToken(
      readOrganizationDeviceConfigurationsScope,
    );

    const { data } = await voizeDeviceManagementApi.get<
      ManagementDeviceConfiguration[]
    >(`/v1/management/device-configurations`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  }, [getAccessToken]);

  const getManagementDeviceConfiguration = useCallback(
    async (id: string): Promise<ManagementDeviceConfiguration> => {
      const accessToken = await getAccessToken(
        readOrganizationDeviceConfigurationsScope,
      );

      const { data } =
        await voizeDeviceManagementApi.get<ManagementDeviceConfiguration>(
          `/v1//management/device-configurations/${id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

      return data;
    },
    [getAccessToken],
  );

  const postManagementDeviceConfiguration = useCallback(
    async (
      deviceConfiguration: PostManagementDeviceConfiguration,
    ): Promise<ManagementDeviceConfiguration> => {
      const accessToken = await getAccessToken(
        writeOrganizationDeviceConfigurationsScope,
      );

      const { data } =
        await voizeDeviceManagementApi.post<ManagementDeviceConfiguration>(
          `/v1/management/device-configurations`,
          deviceConfiguration,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

      return data;
    },
    [getAccessToken],
  );

  const putManagementDeviceConfiguration = useCallback(
    async (
      id: string,
      putManagementDeviceConfiguration: PutManagementDeviceConfiguration,
    ): Promise<void> => {
      const accessToken = await getAccessToken(
        writeOrganizationDeviceConfigurationsScope,
      );

      await voizeDeviceManagementApi.put<ManagementDeviceConfiguration>(
        `/v1/management/device-configurations/${id}`,
        putManagementDeviceConfiguration,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
    [getAccessToken],
  );

  const deleteManagementDeviceConfiguration = useCallback(
    async (id: string): Promise<void> => {
      const accessToken = await getAccessToken(
        deleteOrganizationDeviceConfigurationsScope,
      );

      await voizeDeviceManagementApi.delete<void>(
        `/v1/management/device-configurations/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
    [getAccessToken],
  );

  return {
    getOrganization,
    postDevice,
    getDevices,
    getDevice,
    patchDeviceLabels,
    deleteDevice,
    resetDeviceSecret,
    updateDeviceProperties,
    getDeviceActivationCode,
    getDeviceActivationRequests,
    postDeviceActivationRequestApprove,
    deleteDeviceActivationRequest,
    patchDeviceActivationRequestLabels,
    getManagementDeviceConfigurations,
    getManagementDeviceConfiguration,
    postManagementDeviceConfiguration,
    putManagementDeviceConfiguration,
    deleteManagementDeviceConfiguration,
  };
}
