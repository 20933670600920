import { useQueryClient } from '@tanstack/react-query';
import { useAdminDeviceApiV4 } from 'api/devices/useAdminDeviceApiV4';
import { transformDevice } from 'api/devices/useAdminToManagementApi';
import useLoadAllPaginatedCursorQuery from 'hooks/useLoadAllPaginatedCursorQuery';
import type { AdminDeviceV4Dto } from 'interfaces/adminDeviceV4';
import { useCallback, useMemo } from 'react';

/**
 * Hook to fetch all devices for a specific organization using the admin API.
 */
const useAdminDevices = (
  organization: string | undefined | null,
  batchSize = 5000,
) => {
  const queryClient = useQueryClient();
  const { getDevices } = useAdminDeviceApiV4();

  const { data, ...query } = useLoadAllPaginatedCursorQuery(
    {
      queryKey: ['admin-devices', organization],
      queryFn: async (paginationQuery) => {
        if (organization === null) {
          return [];
        }
        return getDevices(paginationQuery, organization);
      },
    },
    batchSize,
  );

  const devices = useMemo(() => {
    if (!data) return [];
    return data
      .sort((a: AdminDeviceV4Dto, b: AdminDeviceV4Dto) => {
        if (a.lastSeenAt === b.lastSeenAt) return 0;
        if (a.lastSeenAt === null) return 1;
        if (b.lastSeenAt === null) return -1;
        return a.lastSeenAt > b.lastSeenAt ? -1 : 1;
      })
      .map((device) => transformDevice(device));
  }, [data]);

  const invalidateDevices = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ['admin-devices', organization],
    });
  }, [queryClient, organization]);

  return {
    devices,
    invalidateDevices,
    ...query,
  };
};

export default useAdminDevices;
