import type { OrganizationDeviceSelector } from 'interfaces/adminOrganizationDeviceConfig';
import type { ManagementDeviceConfiguration } from 'interfaces/managementDeviceConfiguration';
import Joi from 'joi';
import { validateOrThrow } from 'utlis/validationUtil';

/**
 * Only used inside of the admin tool for abstracting the integration configuration
 */
export interface SeniorCareIntegrationConfig {
  id: string;
  name: string;
  integrationType: IntegrationType;
  baseUrl: string;
  username: string;
  devicesCount: number;
  deviceSelector: OrganizationDeviceSelector;
  priority: number;
  properties: Record<string, string>;
}

export interface SeniorCareIntegrationConfigV2 {
  type: IntegrationType;
  baseUrl: string;
  credentialsId: string;
  credentialsSecret: string;
}

export const integrationConfigDeviceProperty = 'INTEGRATION_CONFIG_V2';

export enum IntegrationType {
  VIVENDI = 'VIVENDI',
  DAN = 'DAN',
  STANDARDIZED = 'STANDARDIZED',
}

export const integrationTypeName: Record<IntegrationType, string> = {
  [IntegrationType.VIVENDI]: 'Vivendi',
  [IntegrationType.DAN]: 'DAN',
  [IntegrationType.STANDARDIZED]: 'Standardisiert',
};

export const SeniorCareIntegrationConfigSchema =
  Joi.object<SeniorCareIntegrationConfigV2>({
    type: Joi.string()
      .valid(...Object.values(IntegrationType))
      .required(),
    baseUrl: Joi.string().uri().required(),
    credentialsId: Joi.string().required(),
    credentialsSecret: Joi.string().required(),
  });

export const toSeniorCareIntegrationConfig = (
  config: ManagementDeviceConfiguration,
): SeniorCareIntegrationConfig => {
  const integrationConfig = validateOrThrow(
    JSON.parse(config.properties[integrationConfigDeviceProperty] ?? '{}'),
    SeniorCareIntegrationConfigSchema,
  );

  return {
    id: config.id,
    name: config.name,
    integrationType: integrationConfig.type,
    baseUrl: integrationConfig.baseUrl,
    username: integrationConfig.credentialsId,
    devicesCount: config.devicesCount,
    properties: config.properties,
    deviceSelector: config.deviceSelector,
    priority: config.priority,
  };
};
