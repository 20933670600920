import { SearchOutlined } from '@ant-design/icons';
import { Input, Table, Typography } from 'antd';
import { FlexBox } from 'components/Helpers';
import { features } from 'defaults/featuresToDeviceProperties';
import type { ManagementDevice } from 'interfaces/device';
import { useMemo, useState } from 'react';
import { MdInsertChartOutlined } from 'react-icons/md';

interface Props {
  devices: ManagementDevice[];
}

type PropertyStats = Record<string, number>;

export const DevicePropertiesStatistics: React.FC<Props> = ({ devices }) => {
  const propertyStats = useMemo(() => {
    const stats: PropertyStats = {};

    devices.forEach((device) => {
      if (!device.properties) return;

      Object.keys(device.properties).forEach((key) => {
        stats[key] = (stats[key] || 0) + 1;
      });
    });

    return stats;
  }, [devices]);

  const [searchText, setSearchText] = useState('');

  return (
    <FlexBox direction="column">
      <Typography.Title level={4}>
        <FlexBox alignitems="center" withgap>
          <MdInsertChartOutlined />
          <span>Geräte-Eigenschaften</span>
        </FlexBox>
      </Typography.Title>

      <FlexBox direction="column" withgap gap={16}>
        <Input
          placeholder="Eigenschaft suchen..."
          prefix={<SearchOutlined />}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ maxWidth: 300 }}
        />

        <Table
          dataSource={Object.entries(propertyStats)
            .sort(([, a], [, b]) => b - a)
            .filter(
              ([property]) =>
                !searchText ||
                getPropertyFeatureName(property)
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                property.toLowerCase().includes(searchText.toLowerCase()),
            )
            .map(([property, count]) => ({
              key: property,
              property,
              feature: getPropertyFeatureName(property),
              count,
              percentageTotal: ((count / devices.length) * 100).toFixed(1),
            }))}
          columns={[
            {
              title: 'Eigenschaft (Key)',
              dataIndex: 'property',
              key: 'property',
              sorter: (a, b) => a.property.localeCompare(b.property),
              defaultSortOrder: undefined,
            },
            {
              title: 'Feature',
              dataIndex: 'feature',
              key: 'feature',
              sorter: (a, b) => a.feature.localeCompare(b.feature),
              defaultSortOrder: undefined,
            },
            {
              title: 'Anzahl Geräte',
              dataIndex: 'count',
              key: 'count',
              align: 'right' as const,
              sorter: (a, b) => a.count - b.count,
              defaultSortOrder: 'descend',
            },
            {
              title: 'Prozent aller Geräte',
              dataIndex: 'percentageTotal',
              key: 'percentageTotal',
              align: 'right' as const,
              render: (value: string) => `${value}%`,
              sorter: (a, b) =>
                parseFloat(a.percentageTotal) - parseFloat(b.percentageTotal),
              defaultSortOrder: undefined,
            },
          ]}
          pagination={false}
          size="small"
          scroll={{ y: 'calc(100vh - 500px)' }}
          style={{ height: '500px' }}
        />
      </FlexBox>
    </FlexBox>
  );
};

function getPropertyFeatureName(propertyKey: string): string {
  // Check if it's part of a feature
  const feature = features.find((f) => f.properties.includes(propertyKey));
  if (feature) {
    return feature.name;
  }

  // If no mapping found, return empty string
  return '';
}
