import { Alert, Radio } from 'antd';
import { FlexBox } from 'components/Helpers';
import type { FeatureRenderProps } from '../../../defaults/featuresToDeviceProperties';

enum RecordingAnonymizationStrategy {
  DELETE = 'delete',
  SPLIT = 'split',
  KEEP = 'keep',
  MUTE = 'mute',
}

const labels: Record<RecordingAnonymizationStrategy, string> = {
  [RecordingAnonymizationStrategy.DELETE]: 'Löschen',
  [RecordingAnonymizationStrategy.SPLIT]: 'Ausschneiden',
  [RecordingAnonymizationStrategy.KEEP]: 'Unverändert behalten',
  [RecordingAnonymizationStrategy.MUTE]: 'Stummschalten',
};

const RecordingAnonymizationFeature: React.FC<FeatureRenderProps> = ({
  values,
  onUpdate,
  isEditing,
}) => {
  const deleteRecordingsWithNames =
    values['TRANSCRIPTION_PRIVACY_CHECKER_ENABLED'] === 'true';
  const splitRecordingsWithNames =
    values['SENIOR_CARE_RECORDING_NAME_SPLITTING_ENABLED'] === 'true';
  const muteRecordingsWithNames =
    values['SENIOR_CARE_RECORDING_NAME_MUTING_ENABLED'] === 'true';

  const value = deleteRecordingsWithNames
    ? RecordingAnonymizationStrategy.DELETE
    : splitRecordingsWithNames
      ? RecordingAnonymizationStrategy.SPLIT
      : muteRecordingsWithNames
        ? RecordingAnonymizationStrategy.MUTE
        : RecordingAnonymizationStrategy.KEEP;

  return isEditing ? (
    <FlexBox direction="column" withgap>
      <Radio.Group
        value={value}
        optionType="button"
        options={[
          {
            label: labels[RecordingAnonymizationStrategy.DELETE],
            value: RecordingAnonymizationStrategy.DELETE,
          },
          {
            label: labels[RecordingAnonymizationStrategy.SPLIT],
            value: RecordingAnonymizationStrategy.SPLIT,
          },
          {
            label: labels[RecordingAnonymizationStrategy.MUTE],
            value: RecordingAnonymizationStrategy.MUTE,
          },
          {
            label: labels[RecordingAnonymizationStrategy.KEEP],
            value: RecordingAnonymizationStrategy.KEEP,
          },
        ]}
        onChange={(e) => {
          onUpdate({
            TRANSCRIPTION_PRIVACY_CHECKER_ENABLED:
              e.target.value === RecordingAnonymizationStrategy.DELETE
                ? 'true'
                : 'false',
            SENIOR_CARE_RECORDING_NAME_SPLITTING_ENABLED:
              e.target.value === RecordingAnonymizationStrategy.SPLIT
                ? 'true'
                : 'false',
            SENIOR_CARE_RECORDING_NAME_MUTING_ENABLED:
              e.target.value === RecordingAnonymizationStrategy.MUTE
                ? 'true'
                : 'false',
          });
        }}
      />
    </FlexBox>
  ) : (
    <FlexBox direction="column" withgap>
      <span>{labels[value]}</span>
      {value === RecordingAnonymizationStrategy.KEEP && (
        <Alert
          showIcon
          type="warning"
          message="Achtung! Diese Option darf nie im Produktivbetrieb verwendet werden!"
        />
      )}
    </FlexBox>
  );
};

export default RecordingAnonymizationFeature;
