import { Alert } from 'antd';
import LabelRow from 'components/common/LabelRow';
import { FlexBox } from 'components/Helpers';
import {
  getTicIntegrationFormData,
  ticDivisionName,
} from 'interfaces/ticIntegrationConfig';
import { useMemo } from 'react';

interface Props {
  properties: Record<string, string>;
}

const TICIntegrationConfigSummary: React.FC<Props> = ({ properties }) => {
  const [data, error] = useMemo(
    () => getTicIntegrationFormData(properties),
    [properties],
  );

  if (error) {
    return (
      <FlexBox direction="column">
        <Alert
          message="Fehler"
          description={error.message}
          type="error"
          showIcon
        />
      </FlexBox>
    );
  }

  return (
    <FlexBox direction="column">
      <FlexBox direction="column" withgap>
        <FlexBox direction="column">
          {data.integrationType && (
            <LabelRow label="Integrations-Typ" value={data.integrationType} />
          )}
          {data.division && (
            <LabelRow label="Division" value={ticDivisionName[data.division]} />
          )}
          {data.baseUrl && (
            <LabelRow label="Integrations-URL" value={data.baseUrl} />
          )}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default TICIntegrationConfigSummary;
