import { Alert, Button, Form, Input, Select } from 'antd';
import { VOIZE_METADATA_INDUSTRY } from 'components/device-configuration/devicePropertyKeys';
import { FlexBox } from 'components/Helpers';
import {
  getTicIntegrationFormData,
  ticDivisionName,
  TICDivisionViaIndustry,
  TICIntegrationAdapterType,
  type TICIntegrationFormData,
  TICIntegrationPropertyKeys,
  ticIntegrationTuevNordMobilityConfigurationSchema,
  ticIntegrationTypeName,
} from 'interfaces/ticIntegrationConfig';
import { useMemo, useState } from 'react';
import { UnreachableCaseError } from 'ts-essentials';
import { validateOrThrow } from 'utlis/validationUtil';

interface Props {
  initialProperties: Record<string, string>;
  onSubmit: (config: Record<string, string | null>) => void;
}

const TICIntegrationConfigForm: React.FC<Props> = ({
  initialProperties,
  onSubmit,
}) => {
  const formId = 'tic-integration-config-feature';
  const [form] = Form.useForm<TICIntegrationFormData>();
  const integrationType = Form.useWatch('integrationType', form);
  const [initialData, error] = useMemo(
    () => getTicIntegrationFormData(initialProperties),
    [initialProperties],
  );
  const [submissionError, setSubmissionError] = useState<Error | null>(null);

  if (error) {
    return (
      <FlexBox direction="column">
        <Alert
          message="Fehler"
          description={error.message}
          type="error"
          showIcon
        />
      </FlexBox>
    );
  }
  return (
    <Form<TICIntegrationFormData>
      id={formId}
      form={form}
      labelAlign="left"
      labelCol={{ span: 6 }}
      labelWrap={true}
      onFinish={(formData) => {
        try {
          const { integrationType, division, baseUrl } = formData;

          setSubmissionError(null);

          if (integrationType === undefined) {
            throw new Error('Integrations-Adapter muss ausgewählt werden.');
          }

          const properties: Record<string, string | null> = {
            [TICIntegrationPropertyKeys.ADAPTER_TYPE]: integrationType,
            [TICIntegrationPropertyKeys.TUEV_NORD_MOBILITY]: null,
            [TICIntegrationPropertyKeys.TUEV_NORD_SYSTEMS]: null,
            [TICIntegrationPropertyKeys.TUEV_SUED_MOBILITY]: null,
            ...(() => {
              switch (integrationType) {
                case TICIntegrationAdapterType.MOCK: {
                  if (
                    division === undefined ||
                    (division !== TICDivisionViaIndustry.Mobility &&
                      division !== TICDivisionViaIndustry.IndustryServices)
                  ) {
                    throw new Error('Division muss ausgewählt werden.');
                  }
                  return {
                    [VOIZE_METADATA_INDUSTRY]: division,
                  };
                }
                case TICIntegrationAdapterType.TUEV_NORD: {
                  if (
                    division === undefined ||
                    (division !== TICDivisionViaIndustry.Mobility &&
                      division !== TICDivisionViaIndustry.IndustryServices)
                  ) {
                    throw new Error('Division muss ausgewählt werden.');
                  }
                  const devicePropertyKeyLabel =
                    formData.division === TICDivisionViaIndustry.Mobility
                      ? TICIntegrationPropertyKeys.TUEV_NORD_MOBILITY
                      : TICIntegrationPropertyKeys.TUEV_NORD_SYSTEMS;

                  return {
                    [VOIZE_METADATA_INDUSTRY]: division,
                    [devicePropertyKeyLabel]: JSON.stringify(
                      validateOrThrow(
                        { baseUrl },
                        ticIntegrationTuevNordMobilityConfigurationSchema,
                      ),
                    ),
                  };
                }
                case TICIntegrationAdapterType.TUEV_SUED: {
                  return {
                    [VOIZE_METADATA_INDUSTRY]: TICDivisionViaIndustry.Mobility,
                    [TICIntegrationPropertyKeys.TUEV_SUED_MOBILITY]:
                      JSON.stringify(
                        validateOrThrow(
                          { baseUrl },
                          ticIntegrationTuevNordMobilityConfigurationSchema,
                        ),
                      ),
                  };
                }
                default:
                  throw new UnreachableCaseError(integrationType);
              }
            })(),
          };

          onSubmit(properties);
        } catch (error) {
          setSubmissionError(
            error instanceof Error ? error : new Error(String(error)),
          );
        }
      }}
      initialValues={initialData}
    >
      {submissionError && (
        <Alert
          message="Fehler beim Speichern"
          description={submissionError.message}
          type="error"
          showIcon
          style={{ marginBottom: '16px' }}
        />
      )}
      <Form.Item
        label="Integrations-Adapter"
        name="integrationType"
        rules={[{ required: true }]}
      >
        <Select
          options={Object.values(TICIntegrationAdapterType).map((type) => ({
            label: ticIntegrationTypeName[type],
            value: type,
          }))}
        />
      </Form.Item>

      {(() => {
        switch (integrationType) {
          case undefined:
          case TICIntegrationAdapterType.MOCK:
            return (
              <>
                <Form.Item
                  label="Division"
                  name="division"
                  rules={[{ required: true }]}
                >
                  <Select
                    options={Object.values(TICDivisionViaIndustry).map(
                      (division) => ({
                        label: ticDivisionName[division],
                        value: division,
                      }),
                    )}
                  />
                </Form.Item>
              </>
            );
          case TICIntegrationAdapterType.TUEV_NORD:
            return (
              <>
                <Form.Item
                  label="Division"
                  name="division"
                  rules={[{ required: true }]}
                >
                  <Select
                    options={Object.values(TICDivisionViaIndustry).map(
                      (division) => ({
                        label: ticDivisionName[division],
                        value: division,
                      }),
                    )}
                  />
                </Form.Item>
                <Form.Item
                  label="Integrations-URL"
                  name="baseUrl"
                  rules={[
                    {
                      required: true,
                      type: 'url',
                      message: 'Bitte geben Sie eine gültige URL ein.',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </>
            );
          case TICIntegrationAdapterType.TUEV_SUED:
            return (
              <>
                <Form.Item
                  label="Integrations-URL"
                  name="baseUrl"
                  rules={[
                    {
                      required: true,
                      type: 'url',
                      message: 'Bitte geben Sie eine gültige URL ein.',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </>
            );
          default:
            throw new UnreachableCaseError(integrationType);
        }
      })()}
      <Button
        type="primary"
        key="submit"
        form={formId}
        onClick={() => form.submit()}
      >
        Speichern
      </Button>
    </Form>
  );
};

export default TICIntegrationConfigForm;
