import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Tag, Typography } from 'antd';
import { FlexBox, FlexOne } from 'components/Helpers';
import type { DeviceProperty } from 'defaults/deviceProperties';
import {
  type Feature,
  isFeaturePresent,
} from 'defaults/featuresToDeviceProperties';
import { type HoverItem, HoverItemType } from './DevicePropertiesModal';

const FeatureListItem = ({
  feature,
  onAdd,
  isPresent,
}: {
  feature: Feature;
  onAdd: () => void;
  isPresent: boolean;
}) => {
  return (
    <>
      <FlexBox withgap>
        <Typography.Text strong>{feature.name}</Typography.Text>
        {isPresent && <CheckCircleFilled />}
      </FlexBox>
      <FlexBox>
        <FlexOne>
          <Tag color="blue">Feature</Tag>
        </FlexOne>
        {!isPresent && (
          <Button size="small" onClick={onAdd}>
            Hinzufügen
          </Button>
        )}
      </FlexBox>
    </>
  );
};

const DevicePropertyListItem = ({
  deviceProperty,
  onAdd,
  isPresent,
}: {
  deviceProperty: DeviceProperty;
  onAdd: () => void;
  isPresent: boolean;
}) => {
  return (
    <>
      <FlexBox withgap>
        <Typography.Text strong>{deviceProperty.name}</Typography.Text>
        {isPresent && <CheckCircleFilled />}
      </FlexBox>
      <FlexBox>
        <FlexOne>
          <Tag color="green">Device Property</Tag>
          <Tag color="blue">{deviceProperty.source}</Tag>
        </FlexOne>
        {!isPresent && (
          <Button size="small" onClick={onAdd}>
            Hinzufügen
          </Button>
        )}
      </FlexBox>
    </>
  );
};

export const HoverListItem = ({
  item,
  onAdd,
  deviceProperties,
}: {
  item: HoverItem;
  onAdd: (item: HoverItem) => void;
  deviceProperties: Record<string, string>;
}) => {
  switch (item.type) {
    case HoverItemType.FEATURE: {
      const isPresent = isFeaturePresent(item.feature, deviceProperties);
      return (
        <FeatureListItem
          feature={item.feature}
          onAdd={() => onAdd(item)}
          isPresent={isPresent}
        />
      );
    }
    case HoverItemType.DEVICE_PROPERTY: {
      const isPresent = item.deviceProperty.name in deviceProperties;
      return (
        <DevicePropertyListItem
          deviceProperty={item.deviceProperty}
          onAdd={() => onAdd(item)}
          isPresent={isPresent}
        />
      );
    }
  }
};
