import React from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import { FlexBox } from 'components/Helpers';
import { Operator } from 'interfaces/matchers';
import DeviceLabelSelector from './DeviceLabelSelector';

const MATCH_LABELS = 'matchLabels';

const DeviceLabelSelectors: React.FC = () => {
  return (
    <Form.List name={MATCH_LABELS}>
      {(fields, { add, remove }) => (
        <FlexBox direction="column" withgap>
          {fields.map(({ name, key }) => (
            <DeviceLabelSelector
              key={key}
              listName={MATCH_LABELS}
              name={name}
              remove={remove}
            />
          ))}

          <Button
            type="dashed"
            onClick={() =>
              add({
                key: '',
                operator: Operator.IN,
                values: [],
              })
            }
            style={{ width: '100%' }}
            icon={<PlusOutlined />}
          >
            Label-Selektor hinzufügen
          </Button>
        </FlexBox>
      )}
    </Form.List>
  );
};

export default DeviceLabelSelectors;
