import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { Skeleton, message } from 'antd';
import Breadcrumb from 'components/common/Breadcrumb';
import ErrorAlert from 'components/common/ErrorAlert';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import ResourceIcons from 'components/common/resourceIcons';
import OrganizationDeviceConfigurationForm from 'components/device-configuration/OrganizationDeviceConfigurationForm';
import { useManagementApiFromContext } from 'components/scaffold/OrganizationView';
import useManagementDevices from 'hooks/devices/useManagementDevices';
import type { PostManagementDeviceConfiguration } from 'interfaces/managementDeviceConfiguration';
import {
  deviceConfigurationEditRoute,
  useManagementPathOrganization,
} from 'router';

const ManagementOrganizationDeviceConfigurationEditView: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const organization = useManagementPathOrganization();
  const { devices, isLoading: isDevicesLoading } = useManagementDevices();
  const { deviceConfigurationId } = deviceConfigurationEditRoute.useParams();
  const { putManagementDeviceConfiguration, getManagementDeviceConfiguration } =
    useManagementApiFromContext();

  const { data: editConfig, isLoading } = useQuery({
    queryKey: [
      'management-organization-device-configurations',
      deviceConfigurationId,
    ],
    queryFn: () => getManagementDeviceConfiguration(deviceConfigurationId),
  });

  const {
    mutate: updateOrganizationDeviceConfiguration,
    isPending: isCreating,
    error: createError,
  } = useMutation({
    mutationFn: (dc: PostManagementDeviceConfiguration) =>
      putManagementDeviceConfiguration(deviceConfigurationId, dc),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['management-organization-device-configurations'],
      });
      message.success('Gerätekonfiguration wurde bearbeitet.');
      navigate({
        to: '/management/$organization/device-configurations',
        params: { organization },
      });
    },
  });

  return (
    <>
      <Breadcrumb
        items={[
          {
            name: 'Gerätekonfigurationen',
            href: `/management/${organization}/device-configurations`,
          },
          {
            name: editConfig?.name ?? '...',
            href: `/management/${organization}/device-configurations/${deviceConfigurationId}`,
          },
        ]}
      />
      <PageTitleBar
        title={editConfig?.name ?? '...'}
        Icon={ResourceIcons.DeviceConfiguration}
      />
      <PageContentContainer>
        {isLoading ? (
          <Skeleton active />
        ) : !editConfig ? (
          <ErrorAlert
            errorCode="404"
            subtitle="Gerätekonfiguration nicht gefunden"
          />
        ) : (
          <OrganizationDeviceConfigurationForm
            onSubmit={updateOrganizationDeviceConfiguration}
            isLoading={isCreating}
            error={createError}
            fixedOrganization={organization}
            devices={devices}
            isDevicesLoading={isDevicesLoading}
            initialValues={{
              ...editConfig,
              organization,
            }}
          />
        )}
      </PageContentContainer>
    </>
  );
};

export default ManagementOrganizationDeviceConfigurationEditView;
