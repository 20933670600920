import {
  DeleteOutlined,
  InfoCircleOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Button, Tag, Tooltip } from 'antd';
import { FlexBox } from 'components/Helpers';
import {
  type DeviceProperty,
  DevicePropertyType,
  useDeviceProperties,
} from 'defaults/deviceProperties';
import { type Feature } from 'defaults/featuresToDeviceProperties';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import CopyableDevicePropertyKey from './CopyableDevicePropertyKey';
import DevicePropertyInfoModal from './DevicePropertyInfoModal';
import DevicePropertyInputForFeature from './DevicePropertyInputForFeature';
import DevicePropertyValue from './DevicePropertyValue';

interface Props {
  v: {
    index: number;
    key: string;
    value: string;
  };
  onChange: (value: string) => void;
  onDelete: () => void;
  presentFeatures: Feature[];
}

const DevicePropertyInput: React.FC<Props> = ({
  v,
  onChange,
  onDelete,
  presentFeatures,
}) => {
  const [isDevicePropertyModalOpen, setIsDevicePropertyModalOpen] =
    useState(false);
  const deviceProperties = useDeviceProperties();

  const currentDeviceProperty = useMemo<DeviceProperty>(
    () =>
      deviceProperties.find(({ name }) => name === v.key) ?? {
        type: DevicePropertyType.STRING,
        name: v.key,
        source: 'unknown',
        description: 'Unknown device property',
        default: null,
      },
    [deviceProperties, v.key],
  );

  const parentFeature = useMemo<Feature | null>(
    () => presentFeatures.find((f) => f.properties.includes(v.key)) ?? null,
    [presentFeatures, v.key],
  );

  return (
    <Wrapper alignitems="center" isFirst={v.index === 0} withgap>
      <DevicePropertyInfoModal
        deviceProperty={currentDeviceProperty}
        open={isDevicePropertyModalOpen}
        onClose={() => setIsDevicePropertyModalOpen(false)}
      />
      <LeftColumn alignitems="center" withgap wrap>
        <CopyableDevicePropertyKey propertyName={v.key} />
        <Tag style={{ margin: 0 }}>{currentDeviceProperty.source}</Tag>
        {parentFeature !== null && (
          <Tag style={{ margin: 0 }} color="blue">
            {parentFeature.name}
          </Tag>
        )}
      </LeftColumn>
      {parentFeature !== null ? (
        <DevicePropertyInputForFeature
          deviceProperty={currentDeviceProperty}
          value={v.value}
        />
      ) : (
        <>
          <DevicePropertyValue
            deviceProperty={currentDeviceProperty}
            onChange={onChange}
            value={v.value}
            style={{ flex: 1 }}
          />
          <Tooltip title="Reset to default" mouseEnterDelay={0.5}>
            <Button
              type="text"
              icon={<ReloadOutlined />}
              onClick={() => onChange(String(currentDeviceProperty.default))}
              disabled={currentDeviceProperty.default == null}
            />
          </Tooltip>
          <Button
            onClick={() => setIsDevicePropertyModalOpen(true)}
            icon={<InfoCircleOutlined />}
            type="text"
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={onDelete}
            type="text"
            danger
          />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(FlexBox)<{ isFirst: boolean }>`
  border-top: ${({ isFirst, theme }) =>
    isFirst
      ? 'none'
      : `${theme.antd.lineWidth}px ${theme.antd.lineType} ${theme.antd.colorBorder}`};
  padding-bottom: ${({ theme }) => theme.antd.paddingSM}px;
  padding-top: ${({ isFirst, theme }) =>
    isFirst ? 0 : theme.antd.paddingSM}px;
`;

const LeftColumn = styled(FlexBox)`
  width: calc((100% / 24) * 9);
`;

export default DevicePropertyInput;
