import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, Modal } from 'antd';
import { useAdminDeviceApi } from 'api/devices/useAdminDeviceApi';
import { AxiosError } from 'axios';
import ListItemInfoTag from 'components/common/ListItemInfoTag';
import type { DeviceActivationRequest } from 'interfaces/deviceActivationRequest';
import { useState } from 'react';
import getErrorMessage from 'utlis/getErrorMessage';
import AdminDeviceActivationModal from './AdminDeviceActivationModal';
import DevicesActivationRequestInfo from './DevicesActivationRequestInfo';
import UpdateDeviceActivationRequestOrganizationInput from './UpdateDeviceActivationRequestOrganizationInput';

interface Props {
  activationRequest: DeviceActivationRequest;
  isIdCollapsed?: boolean;
  searchText?: string;
  toggleSelect?: (id: string) => void;
  isChecked?: boolean;
}
const AdminDeviceActivationRequestInfo: React.FC<Props> = (props) => {
  const { activationRequest } = props;

  const queryClient = useQueryClient();
  const {
    deleteAdminDeviceActivationRequest,
    postAdminDeviceActivationRequestApprove,
  } = useAdminDeviceApi();

  const [isActivationModalOpen, setIsActivationModalOpen] = useState(false);

  const { mutateAsync: remove } = useMutation({
    mutationFn: () => deleteAdminDeviceActivationRequest(activationRequest.id),
    onSuccess: () => {
      Modal.success({
        title: 'Anfrage gelöscht',
        content: `Die Anfrage mit der ID ${activationRequest.id} wurde gelöscht.`,
      });
      queryClient.invalidateQueries({
        queryKey: ['admin-device-activation-requests'],
      });
    },
    onError: (error: any) => {
      let extratext;
      if (error instanceof AxiosError) {
        switch (error.status) {
          case 401:
            extratext = 'Sie müssen sich anmelden um diese Anfrage zu löschen.';
            break;
          case 403:
            extratext =
              'Sie haben keine Berechtigung diese Anfrage zu löschen.';
            break;
          case 404:
            extratext = 'Die Anfrage wurde nicht gefunden.';
            break;
          default:
            extratext = error.response?.data?.message;
            break;
        }
      }

      Modal.error({
        title: 'Anfrage konnte nicht gelöscht werden',
        content: `Die Anfrage mit der ID ${
          activationRequest.id
        } konnte nicht gelöscht werden. Es gab folgende Fehlermeldung: ${
          extratext ?? error.message ?? 'Unbekannter Fehler'
        }`,
      });
    },
  });

  const { mutateAsync: approveActivationRequest, isPending } = useMutation({
    mutationFn: ({
      deviceRole,
      configId,
    }: {
      deviceRole: string;
      configId: string | null;
    }) =>
      postAdminDeviceActivationRequestApprove(
        activationRequest.id,
        deviceRole,
        {},
        configId,
      ),
    onSuccess: () => {
      message.success('Anfrage angenommen');
      queryClient.invalidateQueries({
        queryKey: ['admin-device-activation-requests'],
      });
      setIsActivationModalOpen(false);
    },
    onError: (error: any) => {
      message.error(getErrorMessage(error));
    },
  });

  return (
    <>
      <AdminDeviceActivationModal
        open={isActivationModalOpen}
        activationRequest={activationRequest}
        onClose={() => setIsActivationModalOpen(false)}
        onActivate={(deviceRole: string) =>
          approveActivationRequest({ deviceRole, configId: null })
        }
        isLoading={isPending}
      />

      <DevicesActivationRequestInfo
        {...props}
        organizationInfo={
          <ListItemInfoTag label="Organisation">
            {activationRequest.approvedAt ? (
              <>{activationRequest.organization ?? 'Keine'}</>
            ) : (
              <UpdateDeviceActivationRequestOrganizationInput
                deviceActivationRequest={activationRequest}
              />
            )}
          </ListItemInfoTag>
        }
        remove={remove}
        setIsActivationModalOpen={setIsActivationModalOpen}
      />
    </>
  );
};

export default AdminDeviceActivationRequestInfo;
