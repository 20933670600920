import { Radio, Select } from 'antd';
import { FlexBox } from 'components/Helpers';
import type { SetMatch } from 'interfaces/matchers';
import { useState } from 'react';

interface Props {
  options: { label: string; value: string }[];
  freeText?: boolean;
  value?: SetMatch;
  onChange?: (value: SetMatch) => void;
}

const SetMatchSelector: React.FC<Props> = ({
  options,
  freeText,
  value,
  onChange,
}) => {
  const [selectedType, setSelectedType] = useState<'in' | 'notIn'>(
    value?.in && value?.in?.length > 0 ? 'in' : 'notIn',
  );
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    value?.[selectedType] || [],
  );

  return (
    <FlexBox withgap>
      <Radio.Group
        value={selectedType}
        onChange={(e) => {
          const v = e.target.value as 'in' | 'notIn';
          setSelectedType(v);
          if (v === 'in') {
            onChange?.({
              in: selectedOptions,
              notIn: [],
            });
          } else {
            onChange?.({
              in: [],
              notIn: selectedOptions,
            });
          }
        }}
      >
        <Radio.Button value="in">Inkludiert</Radio.Button>
        <Radio.Button value="notIn">Exkludiert</Radio.Button>
      </Radio.Group>

      <Select
        options={options.sort((a, b) => a.label.localeCompare(b.label))}
        mode={freeText ? 'tags' : 'multiple'}
        value={value?.[selectedType] || []}
        onChange={(v) => {
          setSelectedOptions(v);
          if (selectedType === 'in') {
            onChange?.({
              in: v,
              notIn: [],
            });
          } else {
            onChange?.({
              in: [],
              notIn: v,
            });
          }
        }}
        style={{ flex: 1 }}
      />
    </FlexBox>
  );
};

export default SetMatchSelector;
