import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';
import { Button, Descriptions, message, Select } from 'antd';
import { getConfigSelectorLabelValueOrThrow } from 'components/device-configuration/DeviceConfigurationHelper';
import { FlexBox, FlexOne } from 'components/Helpers';
import { useManagementApiFromContext } from 'components/scaffold/OrganizationView';
import {
  getDeviceConfigs,
  getSeniorCareIntegrationConfigs,
} from 'components/seniorcare/SeniorCareDeviceConfigurationAssignment';
import {
  deviceConfigurationKey,
  seniorCareDeviceManagementIntegrationConfigurationKey,
} from 'deviceMetadataKeys';
import useManagementDeviceConfigurations from 'hooks/useManagementDeviceConfigurations';
import type { ManagementDevice } from 'interfaces/device';
import React, { useMemo } from 'react';
import { getLabelsDiff } from 'utlis/getLabelsDiff';

interface Props {
  device: ManagementDevice;
}

const SeniorCareDeviceDetails: React.FC<Props> = ({ device }) => {
  const organization = device.organization;
  const queryClient = useQueryClient();
  const { patchDeviceLabels, updateDeviceProperties } =
    useManagementApiFromContext();

  const { mutate: updateDevice } = useMutation({
    mutationFn: async (labels: Record<string, string>) => {
      await patchDeviceLabels(device.id, getLabelsDiff(device.labels, labels));
      await updateDeviceProperties(device.id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin-devices'] });
      queryClient.invalidateQueries({ queryKey: ['management-devices'] });
      message.success('Gerät wurde erfolgreich aktualisiert');
    },
  });

  const { data: deviceConfigurations } = useManagementDeviceConfigurations();

  const integrationDeviceConfigs = useMemo(
    () =>
      getSeniorCareIntegrationConfigs(deviceConfigurations ?? []).map(
        (config) => ({
          id: config.id,
          value: getConfigSelectorLabelValueOrThrow(
            config,
            seniorCareDeviceManagementIntegrationConfigurationKey,
          ),
          name: config.name,
        }),
      ),
    [deviceConfigurations],
  );

  const deviceConfigs = useMemo(
    () =>
      getDeviceConfigs(deviceConfigurations ?? []).map((config) => ({
        id: config.id,
        value: getConfigSelectorLabelValueOrThrow(
          config,
          deviceConfigurationKey,
        ),
        name: config.name,
      })),
    [deviceConfigurations],
  );

  const activeIntegrationConfig = useMemo(
    () =>
      integrationDeviceConfigs.find(
        (config) =>
          device.labels[
            seniorCareDeviceManagementIntegrationConfigurationKey
          ] === config.value,
      ) ?? null,
    [integrationDeviceConfigs, device.labels],
  );

  const activeDeviceConfig = useMemo(
    () =>
      deviceConfigs.find(
        (config) => device.labels[deviceConfigurationKey] === config.value,
      ) ?? null,
    [deviceConfigs, device.labels],
  );

  return (
    <div>
      <Descriptions bordered>
        <Descriptions.Item label="Schnittstelle">
          <EditableDeviceConfig
            selected={activeIntegrationConfig}
            organization={organization}
            options={integrationDeviceConfigs}
            onChange={(deviceConfigId) => {
              updateDevice({
                ...device.labels,
                [seniorCareDeviceManagementIntegrationConfigurationKey]:
                  deviceConfigId,
              });
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Konfiguration">
          <EditableDeviceConfig
            selected={activeDeviceConfig}
            organization={organization}
            options={deviceConfigs}
            onChange={(deviceConfigId) => {
              updateDevice({
                ...device.labels,
                [deviceConfigurationKey]: deviceConfigId,
              });
            }}
          />
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

const EditableDeviceConfig: React.FC<{
  selected: {
    id: string;
    value: string;
    name: string;
  } | null;
  organization: string;
  options: {
    id: string;
    value: string;
    name: string;
  }[];
  onChange: (selectedOptionId: string) => void;
}> = ({ selected, organization, options, onChange: onEdit }) => {
  const [isEditing, setIsEditing] = React.useState(false);

  return (
    <FlexBox>
      {isEditing ? (
        <FlexBox withgap>
          <Select
            showSearch={true}
            optionFilterProp="label"
            defaultValue={selected?.value}
            onChange={(value) => {
              onEdit(value);
              setIsEditing(false);
            }}
            options={options.map((option) => ({
              label: option.name,
              value: option.value,
            }))}
            style={{ minWidth: '300px' }}
          />
          <Button
            icon={<CloseOutlined />}
            type="text"
            onClick={() => setIsEditing(false)}
          />
        </FlexBox>
      ) : (
        <FlexBox alignitems="baseline" withgap>
          <FlexOne>
            {selected !== null ? (
              <Link
                to="/management/$organization/device-configurations/$deviceConfigurationId/edit"
                params={{
                  organization,
                  deviceConfigurationId: selected?.id ?? '-',
                }}
              >
                {selected?.name}
              </Link>
            ) : (
              '-'
            )}
          </FlexOne>
          <Button
            icon={<EditOutlined />}
            type="text"
            onClick={() => setIsEditing(true)}
          />
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default SeniorCareDeviceDetails;
