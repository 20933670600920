import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Modal, message } from 'antd';
import { useManagementApiFromContext } from 'components/scaffold/OrganizationView';
import type { ManagementDeviceActivationRequest } from 'interfaces/managementActivationRequest';
import { useState } from 'react';
import { useNullablePathOrganization } from 'router';
import getErrorMessage from 'utlis/getErrorMessage';
import DeviceActivationActions from './DeviceActivationActions';
import ManagementDeviceActivationModal from './ManagementDeviceActivationModal';

interface Props {
  activationRequest: ManagementDeviceActivationRequest;
  onDeleted: () => void;
  shouldShowDeviceId?: boolean;
}

const ManagementDeviceActivationActions: React.FC<Props> = ({
  activationRequest,
  onDeleted,
  shouldShowDeviceId = true,
}) => {
  const queryClient = useQueryClient();
  const organization = useNullablePathOrganization();
  const { deleteDeviceActivationRequest, postDeviceActivationRequestApprove } =
    useManagementApiFromContext();

  const [isActivationModalOpen, setIsActivationModalOpen] = useState(false);

  const { mutateAsync: remove } = useMutation({
    mutationFn: () => deleteDeviceActivationRequest(activationRequest.id),

    onSuccess: () => {
      Modal.success({
        title: 'Anfrage gelöscht',
        content: `Die Anfrage mit der ID ${activationRequest.id} wurde gelöscht.`,
      });
      queryClient.invalidateQueries({
        queryKey: ['management-device-activation-requests'],
      });

      setIsActivationModalOpen(false);
    },
    onError: (error: any) => {
      Modal.error({
        title: 'Anfrage konnte nicht gelöscht werden',
        content: `Die Anfrage mit der ID ${
          activationRequest.id
        } konnte nicht gelöscht werden. ${getErrorMessage(error)}`,
      });
    },
  });

  const { mutateAsync: approveActivationRequest } = useMutation({
    mutationFn: ({
      deviceRole,
      deviceLabels,
    }: {
      deviceRole: string;
      deviceLabels: Record<string, string>;
    }) => {
      return postDeviceActivationRequestApprove(
        activationRequest.id,
        deviceRole,
        deviceLabels,
      );
    },
    onSuccess: () => {
      message.success('Anfrage angenommen');
      queryClient.invalidateQueries({
        queryKey: ['management-device-activation-requests'],
      });
      setIsActivationModalOpen(false);
    },
    onError: (error: any) => {
      message.error(getErrorMessage(error));
      queryClient.invalidateQueries({
        queryKey: ['management-device-activation-requests'],
      });
    },
  });

  return (
    <>
      <ManagementDeviceActivationModal
        open={isActivationModalOpen}
        activationRequest={activationRequest}
        onClose={() => setIsActivationModalOpen(false)}
        onActivate={(
          deviceRole: string,
          deviceLabels: Record<string, string>,
        ) =>
          approveActivationRequest({
            deviceRole,
            deviceLabels,
          })
        }
      />
      <DeviceActivationActions
        activationRequest={activationRequest}
        organization={organization}
        onDeleted={onDeleted}
        remove={remove}
        setIsActivationModalOpen={setIsActivationModalOpen}
        shouldShowDeviceId={shouldShowDeviceId}
      />
    </>
  );
};

export default ManagementDeviceActivationActions;
