import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Modal, message } from 'antd';
import { useAdminDeviceApi } from 'api/devices/useAdminDeviceApi';
import AdminDeviceActivationModal from 'components/admin/device-activation/AdminDeviceActivationModal';
import type { DeviceActivationRequest } from 'interfaces/deviceActivationRequest';
import { useState } from 'react';
import { useNullablePathOrganization } from 'router';
import getErrorMessage from 'utlis/getErrorMessage';
import DeviceActivationActions from './DeviceActivationActions';

interface Props {
  activationRequest: DeviceActivationRequest;
  onDeleted: () => void;
}

const AdminDeviceActivationActions: React.FC<Props> = ({
  activationRequest,
  onDeleted,
}) => {
  const queryClient = useQueryClient();
  const organization = useNullablePathOrganization();

  const [isActivationModalOpen, setIsActivationModalOpen] = useState(false);

  const {
    deleteAdminDeviceActivationRequest,
    postAdminDeviceActivationRequestApprove,
  } = useAdminDeviceApi();

  const { mutateAsync: remove } = useMutation({
    mutationFn: () => deleteAdminDeviceActivationRequest(activationRequest.id),

    onSuccess: () => {
      Modal.success({
        title: 'Anfrage gelöscht',
        content: `Die Anfrage mit der ID ${activationRequest.id} wurde gelöscht.`,
      });
      queryClient.invalidateQueries({
        queryKey: ['admin-device-activation-requests'],
      });
      setIsActivationModalOpen(false);
    },
    onError: (error: any) => {
      Modal.error({
        title: 'Anfrage konnte nicht gelöscht werden',
        content: `Die Anfrage mit der ID ${
          activationRequest.id
        } konnte nicht gelöscht werden. ${getErrorMessage(error)}`,
      });
    },
  });

  const { mutateAsync: approveActivationRequest, isPending } = useMutation({
    mutationFn: ({
      deviceRole,
      configId,
    }: {
      deviceRole: string;
      configId: string | null;
    }) => {
      return postAdminDeviceActivationRequestApprove(
        activationRequest.id,
        deviceRole,
        {},
        configId,
      );
    },
    onSuccess: () => {
      message.success('Anfrage angenommen');
      queryClient.invalidateQueries({
        queryKey: ['admin-device-activation-requests'],
      });
      setIsActivationModalOpen(false);
    },
    onError: (error: any) => {
      message.error(getErrorMessage(error));
      queryClient.invalidateQueries({
        queryKey: ['admin-device-activation-requests'],
      });
    },
  });

  return (
    <>
      <AdminDeviceActivationModal
        open={isActivationModalOpen}
        activationRequest={activationRequest}
        onActivate={(deviceRole: string) =>
          approveActivationRequest({ deviceRole, configId: null })
        }
        onClose={() => setIsActivationModalOpen(false)}
        isLoading={isPending}
      />
      <DeviceActivationActions
        activationRequest={activationRequest}
        organization={activationRequest.organization ?? organization}
        onDeleted={onDeleted}
        remove={remove}
        setIsActivationModalOpen={setIsActivationModalOpen}
        shouldShowDeviceId={false}
      />
    </>
  );
};

export default AdminDeviceActivationActions;
