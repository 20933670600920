import { CheckOutlined } from '@ant-design/icons';
import { Alert, Button, Input, message, Modal } from 'antd';
import ActivationRequestEmail from 'components/common/ActivationRequestEmail';
import IdTag from 'components/common/IdTag';
import ListItemInfoTag from 'components/common/ListItemInfoTag';
import RelativeDateTimeWithTooltip from 'components/common/RelativeDateTimeWithTooltip';
import { useAvailableDeviceSelectors } from 'components/device-configuration/DeviceConfigurationHelper';
import { FlexBox } from 'components/Helpers';
import LabelList from 'components/views/LabelList';
import type { ManagementDeviceActivationRequest } from 'interfaces/managementActivationRequest';
import { deviceRoleLabel } from 'labels';
import { useCallback, useState } from 'react';

interface Props {
  activationRequest: ManagementDeviceActivationRequest;
  open: boolean;
  onActivate: (
    deviceRole: string,
    deviceLabels: Record<string, string>,
  ) => Promise<void>;
  onClose?: () => void;
}

const ManagementDeviceActivationModal: React.FC<Props> = ({
  activationRequest,
  open,
  onActivate,
  onClose,
}) => {
  const [error, setError] = useState<null | string>(null);
  const [deviceRole, setDeviceRole] = useState(
    activationRequest.metadata[deviceRoleLabel] ?? '',
  );
  const [deviceLabels, setDeviceLabels] = useState<Record<string, string>>({});

  const deviceLabelsFromConfigs = useAvailableDeviceSelectors();
  const close = useCallback(() => {
    setError(null);
    setDeviceRole(activationRequest.metadata[deviceRoleLabel] ?? '');
    onClose?.();
  }, [onClose, activationRequest.metadata]);

  const activate = useCallback(() => {
    setError(null);
    if (deviceRole.length === 0) {
      setError('Keine Rolle ausgewählt. Bitte wählen Sie eine Rolle aus.');
      return;
    }

    onActivate?.(deviceRole, deviceLabels);
  }, [deviceRole, deviceLabels, onActivate]);

  return (
    <Modal
      title={
        <FlexBox withgap>
          Aktivierungsanfrage <IdTag id={activationRequest.id} />
        </FlexBox>
      }
      open={open}
      onCancel={close}
      footer={[
        <Button key="cancel" onClick={close}>
          Abbrechen
        </Button>,
        <Button key="activate" type="primary" onClick={activate}>
          Anfrage annehmen
        </Button>,
      ]}
      width={800}
    >
      <FlexBox direction="column" withgap>
        <ListItemInfoTag label="Geräte-ID">
          {activationRequest.deviceId ?? '-'}
        </ListItemInfoTag>

        <ListItemInfoTag label="Username">
          {activationRequest.userName}
        </ListItemInfoTag>
        <ListItemInfoTag label="E-Mail Adresse">
          <ActivationRequestEmail activationRequest={activationRequest} />
        </ListItemInfoTag>

        <ListItemInfoTag label="Rolle">
          <FlexBox withgap>
            <Input
              value={deviceRole}
              onChange={(e) => setDeviceRole(e.target.value)}
              size="small"
            />
            <Button
              size="small"
              onClick={() => message.success('Rolle wurde aktualisiert')}
              icon={<CheckOutlined />}
            />
          </FlexBox>
        </ListItemInfoTag>
        <ListItemInfoTag
          label="Geräte-Labels"
          style={{ width: '100%' }}
          valueStyle={{ flex: 1 }}
        >
          <LabelList
            labels={deviceLabels}
            onChange={setDeviceLabels}
            editable
            labelOptions={deviceLabelsFromConfigs}
          />
        </ListItemInfoTag>
        {Object.keys(deviceLabels).length > 0 && (
          <Alert
            type="warning"
            message="Die Geräte-Labels werden erst nach dem Drücken von 'Anfrage annehmen' gespeichert."
          />
        )}
        <ListItemInfoTag label="Erstellt">
          <RelativeDateTimeWithTooltip
            timestamp={activationRequest.createdAt}
          />
        </ListItemInfoTag>
        <ListItemInfoTag
          label="E-Mail Adresse bestätigt"
          valueStyle={{
            backgroundColor: activationRequest.userEmailVerifiedAt
              ? 'hsla(90, 100%, 72%, 1)'
              : 'hsla(0, 100%, 72%, 1)',
          }}
        >
          <RelativeDateTimeWithTooltip
            style={{
              color:
                activationRequest.userEmailVerifiedAt === null
                  ? 'red'
                  : undefined,
            }}
            timestamp={activationRequest.userEmailVerifiedAt}
            noneText="Noch nicht bestätigt"
          />
        </ListItemInfoTag>

        {error && <Alert type="error" message={error} />}

        {activationRequest.userEmailVerifiedAt === null && (
          <Alert
            type="error"
            message="Die E-Mail Adresse wurde noch nicht bestätigt. Bitte warten Sie bis die E-Mail Adresse bestätigt wurde."
          />
        )}
      </FlexBox>
    </Modal>
  );
};

export default ManagementDeviceActivationModal;
