import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';
import { getRequiredEnv } from 'config';
import type {
  AdminDevicePostV4Dto,
  AdminDeviceV4Dto,
} from 'interfaces/adminDeviceV4';
import type { PaginationQuery } from 'interfaces/PaginationQuery';
import { useCallback } from 'react';
import {
  deleteDevicesScope,
  readDevicesScope,
  writeDevicesScope,
} from './deviceManagementApiScopes';

const audience = getRequiredEnv('VITE_AUTH0_AUDIENCE');

export const voizeDeviceManagementApi = Axios.create({
  baseURL: getRequiredEnv('VITE_DEVICE_MANAGEMENT_API_URL'),
});

export const useAdminDeviceApiV4 = () => {
  const { getAccessTokenSilently } = useAuth0();
  const getAccessToken = useCallback(
    async (...scopes: string[]) =>
      await getAccessTokenSilently({
        authorizationParams: {
          audience,
          scope: scopes.join(' '),
        },
      }),
    [getAccessTokenSilently],
  );

  const postDevice = useCallback(
    async (device: AdminDevicePostV4Dto): Promise<AdminDeviceV4Dto> => {
      const accessToken = await getAccessToken(writeDevicesScope);

      const { data } = await voizeDeviceManagementApi.post(
        `/v4/admin/devices`,
        device,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      return data;
    },
    [getAccessToken],
  );

  const postUpdateAllDeviceProperties = useCallback(async (): Promise<void> => {
    const accessToken = await getAccessToken(writeDevicesScope);

    const { data } = await voizeDeviceManagementApi.post(
      `/v4/admin/devices/update-all-device-properties`,
      undefined,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  }, [getAccessToken]);

  const postVerifyAllDevices = useCallback(async (): Promise<void> => {
    const accessToken = await getAccessToken(writeDevicesScope);

    const { data } = await voizeDeviceManagementApi.post(
      `/v4/admin/devices/verify-all-devices`,
      undefined,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  }, [getAccessToken]);

  const getDeviceActivationCode = useCallback(
    async (id: string): Promise<string | null> => {
      const accessToken = await getAccessToken(writeDevicesScope);

      const { data } = await voizeDeviceManagementApi.get<string>(
        `/v4/admin/devices/${id}/activation-code`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      return data.length === 0 ? null : data;
    },
    [getAccessToken],
  );

  const resetDeviceSecret = useCallback(
    async (id: string): Promise<void> => {
      const accessToken = await getAccessToken(writeDevicesScope);

      await voizeDeviceManagementApi.post(
        `/v4/admin/devices/${id}/reset-device-secret`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
    [getAccessToken],
  );

  const getDevices = useCallback(
    async (
      paginationQuery: PaginationQuery,
      organization?: string,
    ): Promise<AdminDeviceV4Dto[]> => {
      const accessToken = await getAccessToken(readDevicesScope);

      const { data } = await voizeDeviceManagementApi.get<AdminDeviceV4Dto[]>(
        '/v4/admin/devices',
        {
          params: {
            organization,
            ...paginationQuery,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      return data;
    },
    [getAccessToken],
  );

  const getDevice = useCallback(
    async (id: string): Promise<AdminDeviceV4Dto> => {
      const accessToken = await getAccessToken(readDevicesScope);

      const { data } = await voizeDeviceManagementApi.get<AdminDeviceV4Dto>(
        `/v4/admin/devices/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      return data;
    },
    [getAccessToken],
  );

  const patchDeviceLabels = useCallback(
    async (
      id: string,
      labels: Record<string, string | null>,
    ): Promise<void> => {
      const accessToken = await getAccessToken(writeDevicesScope);

      await voizeDeviceManagementApi.patch(
        `/v4/admin/devices/${id}/labels`,
        labels,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
    [getAccessToken],
  );

  const deleteDevice = useCallback(
    async (id: string): Promise<void> => {
      const accessToken = await getAccessToken(deleteDevicesScope);

      await voizeDeviceManagementApi.delete(`/v4/admin/devices/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
    [getAccessToken],
  );

  const updateDeviceProperties = useCallback(
    async (id: string): Promise<void> => {
      const accessToken = await getAccessToken(writeDevicesScope);

      await voizeDeviceManagementApi.post(
        `/v4/admin/devices/${id}/update-device-properties`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
    [getAccessToken],
  );

  return {
    getDevices,
    postDevice,
    postUpdateAllDeviceProperties,
    postVerifyAllDevices,
    resetDeviceSecret,
    getDeviceActivationCode,
    deleteDevice,
    getDevice,
    patchDeviceLabels,
    updateDeviceProperties,
  };
};
