import { Switch } from 'antd';
import type { BooleanDeviceProperty } from 'defaults/deviceProperties';

interface Props {
  deviceProperty: BooleanDeviceProperty;
  onChange: (value: boolean) => void;
  value: boolean;
  style?: React.CSSProperties;
}
const BooleanDevicePropertyValue: React.FC<Props> = ({
  onChange,
  value,
  style,
}) => {
  return (
    <div style={style}>
      <Switch checked={value} onChange={onChange} />
    </div>
  );
};

export default BooleanDevicePropertyValue;
