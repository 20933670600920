import { Alert } from 'antd';
import { FlexBox } from 'components/Helpers';
import Joi from 'joi';
import { useMemo } from 'react';
import { validateJsonProperty } from 'utlis/validationUtil';
import type { FeatureRenderProps } from '../../../../defaults/featuresToDeviceProperties';

export const integrationAreasDevicePropertyKey = 'FEATURES_BLACKLIST';

enum IntegrationFeature {
  MEASURES = 'MEASURES',
  MEASURE_CHECKS = 'MEASURE_CHECKS',
  CARE_REPORTS = 'CARE_REPORTS',
  VITALS = 'VITALS',
  FLUID_INTAKES = 'FLUID_INTAKES',
  HYGIENE = 'HYGIENE',
  POSITIONINGS = 'POSITIONINGS',
  WOUNDS = 'WOUNDS',
  WOUND_DEVELOPMENTS = 'WOUND_DEVELOPMENTS',
  MEDICATIONS = 'MEDICATIONS',
  MEDICAMENT_IMAGES = 'MEDICAMENT_IMAGES',
  RESIDENT_IMAGES = 'RESIDENT_IMAGES',
  FIXATIONS = 'FIXATIONS',
  URINATION = 'URINATION',
  INCONTINENCE = 'INCONTINENCE',
  NUTRITION = 'NUTRITION',
  PAIN = 'PAIN',
  SIS = 'SIS',
  FINGERTEST = 'FINGERTEST',
  ASSESSMENTS = 'ASSESSMENTS',
  TOURS = 'TOURS',
}

export const SeniorCareFeaturesBlacklistSchema = Joi.array<string[]>().items(
  Joi.string().valid(...Object.values(IntegrationFeature)),
);

const SeniorCareFeaturesBlacklistFeature: React.FC<FeatureRenderProps> = ({
  values,
  onUpdate,
  isEditing,
}) => {
  const [value, error] = useMemo(
    () =>
      validateJsonProperty(
        values[integrationAreasDevicePropertyKey] ?? '[]',
        SeniorCareFeaturesBlacklistSchema,
      ),
    [values],
  );

  const valueOrDefault = value ?? [];

  const handleUpdate = (newValue: string[]) => {
    onUpdate({
      ...values,
      [integrationAreasDevicePropertyKey]: JSON.stringify(newValue),
    });
  };

  return (
    <FlexBox direction="column">
      {error !== null && <Alert message={error} type="error" />}
      {Object.values(IntegrationFeature).map((feature) => (
        <label key={feature}>
          <FlexBox direction="row" withgap>
            <input
              disabled={!isEditing}
              type="checkbox"
              checked={!valueOrDefault.includes(feature)}
              onChange={() =>
                handleUpdate(
                  valueOrDefault.includes(feature)
                    ? valueOrDefault.filter((f) => f !== feature)
                    : [...valueOrDefault, feature],
                )
              }
            />
            <span>{feature}</span>
          </FlexBox>
        </label>
      ))}
    </FlexBox>
  );
};

export default SeniorCareFeaturesBlacklistFeature;
