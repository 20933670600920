import type { FeatureRenderProps } from 'defaults/featuresToDeviceProperties';
import Joi from 'joi';
import { useMemo } from 'react';
import { validateJsonProperty } from 'utlis/validationUtil';
import ShiftsOverwriteFeatureForm from './ShiftsOverwriteFeatureForm';
import ShiftsOverwriteFeatureSummary from './ShiftsOverwriteFeatureSummary';

export const shiftsOverwriteDeviceProperty = 'SHIFTS_OVERWRITE';

export interface ShiftsOverwriteConfigTime {
  days: number;
  hours: number;
  minutes: number;
}

export interface ShiftsOverwriteConfig {
  early: {
    start: ShiftsOverwriteConfigTime;
    end: ShiftsOverwriteConfigTime;
  };
  late: {
    start: ShiftsOverwriteConfigTime;
    end: ShiftsOverwriteConfigTime;
  };
  night: {
    start: ShiftsOverwriteConfigTime;
    end: ShiftsOverwriteConfigTime;
  };
}

const ShiftsOverwriteTimeSchema = Joi.object<ShiftsOverwriteConfigTime>({
  days: Joi.number().min(0).required(),
  hours: Joi.number().min(0).max(23).required(),
  minutes: Joi.number().min(0).max(59).required(),
});

export const ShiftsOverwriteConfigSchema = Joi.object<ShiftsOverwriteConfig>({
  early: Joi.object({
    start: ShiftsOverwriteTimeSchema.required(),
    end: ShiftsOverwriteTimeSchema.required(),
  }).required(),
  late: Joi.object({
    start: ShiftsOverwriteTimeSchema.required(),
    end: ShiftsOverwriteTimeSchema.required(),
  }).required(),
  night: Joi.object({
    start: ShiftsOverwriteTimeSchema.required(),
    end: ShiftsOverwriteTimeSchema.required(),
  }).required(),
});

const ShiftsOverwriteFeature: React.FC<FeatureRenderProps> = ({
  values,
  onUpdate,
  isEditing,
  onEditDone,
}) => {
  const [configOrNull] = useMemo(
    () =>
      validateJsonProperty(
        values[shiftsOverwriteDeviceProperty],
        ShiftsOverwriteConfigSchema,
      ),
    [values],
  );

  return isEditing ? (
    <ShiftsOverwriteFeatureForm
      initialValue={configOrNull}
      onSubmit={(formData) => {
        onUpdate({
          ...values,
          [shiftsOverwriteDeviceProperty]: JSON.stringify(formData),
        });
        onEditDone?.();
      }}
    />
  ) : (
    <ShiftsOverwriteFeatureSummary values={values} />
  );
};

export default ShiftsOverwriteFeature;
