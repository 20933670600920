import { useMutation } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { message } from 'antd';
import { useAdminDeviceApi } from 'api/devices/useAdminDeviceApi';
import Breadcrumb from 'components/common/Breadcrumb';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import ResourceIcons from 'components/common/resourceIcons';
import GlobalDeviceConfigurationForm from 'components/device-configuration/GlobalDeviceConfigurationForm';
import useAdminDevices from 'hooks/devices/useAdminDevices';

const AdminGlobalDeviceConfigurationCreateView: React.FC = () => {
  const navigate = useNavigate();
  const { postGlobalDeviceConfiguration } = useAdminDeviceApi();
  const { devices, isLoading: isDevicesLoading } = useAdminDevices(undefined);
  const {
    mutate: createGlobalDeviceConfiguration,
    isPending: isCreating,
    error: createError,
  } = useMutation({
    mutationFn: postGlobalDeviceConfiguration,
    onSuccess: () => {
      message.success('Global Device Configuration wurde erstellt.');
      navigate({
        to: '/admin/global-device-configurations',
      });
    },
  });

  return (
    <>
      <Breadcrumb
        items={[
          {
            name: 'Globale Gerätekonfigurationen',
            href: '/admin/global-device-configurations',
          },
        ]}
      />
      <PageTitleBar
        title="Globale Gerätekonfiguration angelegen"
        Icon={ResourceIcons.GlobalDeviceConfiguration}
      />
      <PageContentContainer>
        <GlobalDeviceConfigurationForm
          onSubmit={createGlobalDeviceConfiguration}
          isLoading={isCreating}
          error={createError}
          create
          devices={devices}
          isDevicesLoading={isDevicesLoading}
        />
      </PageContentContainer>
    </>
  );
};

export default AdminGlobalDeviceConfigurationCreateView;
