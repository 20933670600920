import { LoadingOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Collapse, Popover, Skeleton, Table } from 'antd';
import type { ManagementDevice } from 'interfaces/device';
import {
  type MatchExpression,
  Operator,
  type SetMatch,
  operatorToName,
} from 'interfaces/matchers';
import { deviceCreatedAtLabel, deviceDisplayNameLabel } from 'labels';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import {
  countMatchingDevices,
  deviceMatchesGlobalSelector,
  getDevicesForLabel,
  getDevicesForOrganization,
  getDevicesForRole,
} from '../../utlis/deviceConfigurationMatcher';

const DeviceCard = ({
  device,
  index,
}: {
  device: ManagementDevice;
  index: number;
}) => {
  return (
    <Card
      key={device.id}
      className={index === 0 ? '' : 'mt-2.5'}
      styles={{ body: { padding: '10px' } }}
    >
      <p>
        <strong>Device ID:</strong> {device.id}
        <br />
        <strong>Name:</strong> {device.labels[deviceDisplayNameLabel]}
        <br />
        <strong>Organisation:</strong> {device.organization}
        <br />
        <strong>Erstellt am:</strong>{' '}
        {device.labels[deviceCreatedAtLabel]
          ? DateTime.fromISO(device.labels[deviceCreatedAtLabel]).toFormat(
              "dd.MM.yyyy HH:mm 'Uhr'",
            )
          : '-'}
      </p>
    </Card>
  );
};

const DeviceList = ({ devices }: { devices: ManagementDevice[] }) => {
  return (
    <div className="max-h-36 overflow-y-scroll">
      {devices.length > 0 ? (
        devices.map((device, index) => {
          return <DeviceCard key={device.id} device={device} index={index} />;
        })
      ) : (
        <p>Keine betroffenen Geräte gefunden</p>
      )}
    </div>
  );
};

interface DeviceCountCollapsibleProps {
  devices: ManagementDevice[];
  matchLabels: MatchExpression[] | undefined;
  matchRole: SetMatch | undefined;
  currentOrg?: string;
  matchOrganization?: SetMatch | undefined;
  isDevicesLoading: boolean;
}

const DeviceCountCollapsible = ({
  devices,
  matchLabels,
  matchRole,
  currentOrg,
  matchOrganization,
  isDevicesLoading,
}: DeviceCountCollapsibleProps) => {
  // Calculate total matching devices
  const totalMatchingDevices = useMemo(() => {
    if (currentOrg) {
      return countMatchingDevices(
        devices,
        {
          matchLabels,
          matchRole,
        },
        currentOrg,
      );
    } else {
      // For global configs, use deviceMatchesGlobalSelector
      return devices.filter((device) =>
        deviceMatchesGlobalSelector(device, {
          matchLabels,
          matchRole,
          matchOrganization,
        }),
      );
    }
  }, [devices, matchLabels, matchRole, currentOrg, matchOrganization]);

  // Calculate devices matching each label expression
  const labelDeviceCounts = useMemo(() => {
    if (!matchLabels || matchLabels.length === 0) return [];
    return matchLabels
      .filter(
        (expr): expr is MatchExpression =>
          expr !== undefined &&
          typeof expr === 'object' &&
          expr.key !== undefined &&
          expr.operator !== undefined,
      )
      .map((expr, index) => ({
        key: index,
        selector: expr.key,
        operator: operatorToName[expr.operator],
        value: expr.values ? expr.values.join(', ') : '',
        deviceCount: getDevicesForLabel(devices, expr, currentOrg ?? null),
      }));
  }, [devices, matchLabels, currentOrg]);

  // Calculate devices matching each role
  const roleDeviceCounts = useMemo(() => {
    if (!matchRole) return [];
    const roles = matchRole.in?.length ? matchRole.in : (matchRole.notIn ?? []);
    const isNotIn = !matchRole.in?.length;
    return roles.map((role) => ({
      role,
      devices: getDevicesForRole(devices, role, isNotIn, currentOrg ?? null),
    }));
  }, [devices, matchRole, currentOrg]);

  // Calculate devices matching each organization
  const orgDeviceCounts = useMemo(() => {
    if (!matchOrganization) return [];
    const orgs = matchOrganization.in?.length
      ? matchOrganization.in
      : matchOrganization.notIn || [];
    const isNotIn = !matchOrganization.in?.length;

    return orgs.map((org) => ({
      org,
      devices: getDevicesForOrganization(devices, org, isNotIn),
    }));
  }, [devices, matchOrganization]);

  // Check if all operators have valid values
  const hasValidValuesForOperators = useMemo(() => {
    if (!matchLabels) return true;
    return matchLabels.every((expr) => {
      if (!expr) return true;
      if (expr.operator === Operator.IN || expr.operator === Operator.NOT_IN) {
        return expr.values && expr.values.length > 0;
      }
      return true;
    });
  }, [matchLabels]);

  return (
    <Collapse className="mb-5">
      <Collapse.Panel
        key="device-impact"
        header={
          <div className="flex justify-between items-center w-full">
            <p className="font-semibold text-base">
              Auswirkungen der Konfiguration
            </p>
            {isDevicesLoading ? (
              <LoadingOutlined className="animate-spin" />
            ) : (
              <Popover
                trigger="click"
                content={<DeviceList devices={totalMatchingDevices} />}
              >
                <Button
                  variant="outlined"
                  color={
                    totalMatchingDevices.length >= 100 && devices.length >= 100
                      ? 'danger'
                      : 'default'
                  }
                  onClick={(e) => e.stopPropagation()}
                >
                  {totalMatchingDevices.length} / {devices.length} Geräte
                </Button>
              </Popover>
            )}
          </div>
        }
      >
        {isDevicesLoading ? (
          <Skeleton active />
        ) : (
          <div className="flex flex-col gap-y-5">
            {!hasValidValuesForOperators && (
              <Alert
                type="warning"
                message="Bitte geben Werte für die Operatoren 'in' und 'not in' ein."
                className="mb-2.5"
              />
            )}

            {(matchLabels || []).length > 0 && (
              <Table
                pagination={false}
                dataSource={labelDeviceCounts}
                columns={[
                  { title: 'Selektor', dataIndex: 'selector' },
                  { title: 'Operator', dataIndex: 'operator' },
                  { title: 'Wert', dataIndex: 'value' },
                  {
                    title: 'Geräte',
                    dataIndex: 'deviceCount',
                    render: (matchedDevices: ManagementDevice[]) => (
                      <Popover
                        placement="right"
                        trigger="click"
                        content={<DeviceList devices={matchedDevices} />}
                      >
                        <Button variant="outlined">
                          {matchedDevices.length}
                        </Button>
                      </Popover>
                    ),
                  },
                ]}
              />
            )}

            {matchRole?.in?.length || matchRole?.notIn?.length ? (
              <div>
                <p className="font-semibold text-base">
                  {matchRole.in?.length
                    ? 'Inkludierte Rollen:'
                    : 'Exkludierte Rollen:'}
                </p>
                <div className="flex flex-wrap gap-2.5 mt-2.5">
                  {roleDeviceCounts.map(({ role, devices: matchedDevices }) => (
                    <Popover
                      key={`role-${role}`}
                      placement="bottom"
                      trigger="click"
                      content={<DeviceList devices={matchedDevices} />}
                    >
                      <Button variant="outlined">
                        {role} ({matchedDevices.length} Geräte)
                      </Button>
                    </Popover>
                  ))}
                </div>
              </div>
            ) : null}

            {matchOrganization?.in?.length ||
            matchOrganization?.notIn?.length ? (
              <div>
                <p className="font-semibold text-base">
                  {matchOrganization.in?.length
                    ? 'Inkludierte Organisationen:'
                    : 'Exkludierte Organisationen:'}
                </p>
                <div className="flex flex-wrap gap-2.5 mt-2.5">
                  {orgDeviceCounts.map(({ org, devices: matchedDevices }) => (
                    <Popover
                      key={`org-${org}`}
                      placement="bottom"
                      trigger="click"
                      content={<DeviceList devices={matchedDevices} />}
                    >
                      <Button variant="outlined">
                        {org} ({matchedDevices.length} Geräte)
                      </Button>
                    </Popover>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};

export default DeviceCountCollapsible;
