import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Input, message, Modal } from 'antd';
import { getConfigSelectorLabelValueOrThrow } from 'components/device-configuration/DeviceConfigurationHelper';
import { useManagementApiFromContext } from 'components/scaffold/OrganizationView';
import SeniorCareDeviceConfigurationForm, {
  useSeniorCareDeviceConfigs,
  useSeniorCareIntegrationConfigs,
} from 'components/seniorcare/SeniorCareDeviceConfigurationAssignment';
import {
  deviceConfigurationKey,
  seniorCareDeviceManagementIntegrationConfigurationKey,
} from 'deviceMetadataKeys';
import useManagementDeviceConfigurations from 'hooks/useManagementDeviceConfigurations';
import type { ManagementDevice } from 'interfaces/device';
import { deviceDisplayNameLabel } from 'labels';
import React from 'react';
import { toBaseError } from 'utlis/BaseError';
import { getLabelsDiff } from 'utlis/getLabelsDiff';

interface Props {
  device: ManagementDevice;
  onClose?: () => void;
  onEdited?: () => void;
}

interface FormData {
  name: string;
  integration: string;
  deviceconfig: string;
}

const ManagementSeniorCareDeviceEditModal: React.FC<Props> = ({
  device,
  onClose,
  onEdited,
}) => {
  const queryClient = useQueryClient();
  const { patchDeviceLabels, updateDeviceProperties } =
    useManagementApiFromContext();

  const { data: deviceConfigurations } = useManagementDeviceConfigurations();

  const integrationConfigs = useSeniorCareIntegrationConfigs();
  const deviceConfigs = useSeniorCareDeviceConfigs();

  const activeDeviceConfig =
    deviceConfigs.find(
      (config) =>
        device.labels[deviceConfigurationKey] ===
        getConfigSelectorLabelValueOrThrow(config, deviceConfigurationKey),
    ) ?? null;
  const activeIntegrationConfig =
    integrationConfigs.find(
      (config) =>
        device.labels[seniorCareDeviceManagementIntegrationConfigurationKey] ===
        getConfigSelectorLabelValueOrThrow(
          config,
          seniorCareDeviceManagementIntegrationConfigurationKey,
        ),
    ) ?? null;

  const [form] = Form.useForm<FormData>();

  const { mutate: onSubmit, isPending } = useMutation({
    mutationKey: ['management-device', device.id],
    mutationFn: async (data: FormData) => {
      const labels = {
        ...device.labels,
        [deviceDisplayNameLabel]: data.name,
        [seniorCareDeviceManagementIntegrationConfigurationKey]:
          data.integration,
        [deviceConfigurationKey]: data.deviceconfig,
      };

      await patchDeviceLabels(device.id, getLabelsDiff(device.labels, labels));
      await updateDeviceProperties(device.id);

      return {
        ...device,
        labels,
      };
    },
    onSuccess: () => {
      onEdited?.();
      queryClient.invalidateQueries({ queryKey: ['management-devices'] });
      message.success('Gerät wurde bearbeiten.');
    },
    onError: (error) => {
      const baseError = toBaseError(error);
      message.error(
        `Gerät konnte nicht bearbeitet werden: ${baseError.message}`,
      );
    },
  });

  return (
    <Modal
      title="Gerät bearbeiten"
      open={true}
      onCancel={onClose}
      onOk={form.submit}
      okText="Speichern"
      width={900}
      confirmLoading={isPending}
    >
      <Form<FormData>
        form={form}
        onFinish={onSubmit}
        initialValues={{
          name: device.labels[deviceDisplayNameLabel] ?? '',
          integration:
            activeIntegrationConfig !== null
              ? getConfigSelectorLabelValueOrThrow(
                  activeIntegrationConfig,
                  seniorCareDeviceManagementIntegrationConfigurationKey,
                )
              : undefined,
          deviceconfig:
            activeDeviceConfig !== null
              ? getConfigSelectorLabelValueOrThrow(
                  activeDeviceConfig,
                  deviceConfigurationKey,
                )
              : undefined,
        }}
      >
        <SeniorCareDeviceConfigurationForm
          deviceConfigurations={deviceConfigurations ?? []}
        />
        <Form.Item label="Name" name="name">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ManagementSeniorCareDeviceEditModal;
