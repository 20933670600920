import { useMemo, useState } from 'react';

const useJsonSearch = <T extends { id: string }>(items: T[]) => {
  const [searchText, setSearchText] = useState('');

  const filteredItems = useMemo(() => {
    if (!items || !searchText.trim()) return items || [];
    const lowerSearchText = searchText.toLowerCase();
    return items.filter((item) => {
      // Only stringify the item once during filtering
      const json = JSON.stringify(item).toLowerCase();
      return json.includes(lowerSearchText);
    });
  }, [items, searchText]);

  return {
    filteredItems,
    searchText,
    setSearchText,
  };
};

export default useJsonSearch;
