import { Alert, Switch } from 'antd';
import { FlexBox } from 'components/Helpers';
import type { FeatureRenderProps } from 'defaults/featuresToDeviceProperties';
import _ from 'lodash';

interface BooleanFeatureStates {
  on: Record<string, string>;
  off: Record<string, string>;
}

export const BooleanFeature: React.FC<
  FeatureRenderProps & {
    states: BooleanFeatureStates;
  }
> = ({ values, onUpdate, states }) => {
  const isOn = _.isEqual(states.on, values);
  const isOff = _.isEqual(states.off, values);
  const isUndefined = !isOn && !isOff;

  return (
    <FlexBox direction="column" withgap alignitems="flex-start">
      {isUndefined && (
        <Alert
          showIcon
          type="warning"
          message="Der Wert der Device Property ist noch nicht korrekt auf ”true” oder ”false” gesetzt. Wenn du diese Konfiguration so speicherst, kann es zu Problemen im Produkt kommen."
        />
      )}
      <Switch
        checked={isOn}
        onChange={(checked) => {
          onUpdate(checked ? states.on : states.off);
        }}
      />
    </FlexBox>
  );
};

export const createBooleanFeature =
  // eslint-disable-next-line react/display-name
  (states: BooleanFeatureStates) => (props: FeatureRenderProps) => (
    <BooleanFeature {...props} states={states} />
  );
